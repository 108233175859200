export const getTransactionType = businessType => {
  switch (businessType) {
    case 2:
    case 'Locacao':
    case 'Locação':
    case 'transaction_rent':
      return 'transaction_rent'
    case 3:
    case 'Temporada':
    case 'transaction_season':
      return 'transaction_season'
    default:
      return 'transaction_sale'
  }
}

export const translateTransactionType = businessType => {
  switch (businessType) {
    case 2:
    case 'transaction_rent':
      return 'Locação'
    case 3:
    case 'transaction_season':
      return 'Temporada'
    default:
      return 'Venda'
  }
}
