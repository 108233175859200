import React from 'react'
import classNames from 'classnames'
import { Checkbox } from 'components/Form'
import { Formik } from 'formik'
import { Col, Row } from 'components/Grid'
import { Button } from 'components/Buttons'
import I18n from 'locales'
import './index.css'

const FilterBusinessType = ({
  className,
  onSubmit,
  onCancel,
  initialValues = {},
}) => {
  const classes = classNames(
    'SearchFilter',
    'FilterBusinessTypeMobile',
    className,
  )
  return (
    <div className={classes}>
      <Formik onSubmit={onSubmit} initialValues={initialValues}>
        {({ values, ...props }) => (
          <form
            onSubmit={props.handleSubmit}
            className="FilterBusinessTypeMobile__Form"
          >
            <Row className="FilterPriceRangeMobile__Row">
              <Col>
                <Checkbox
                  mobile
                  name="businessType"
                  label={I18n.t('components.createAlert.sale')}
                  checked={
                    values.businessType ===
                    I18n.t('components.createAlert.sale')
                  }
                  onChange={() =>
                    props.setFieldValue(
                      'businessType',
                      I18n.t('components.createAlert.sale'),
                    )
                  }
                />
              </Col>
            </Row>
            <Row className="FilterPriceRangeMobile__Row">
              <Col>
                <Checkbox
                  mobile
                  name="businessType"
                  label={I18n.t('components.createAlert.rent')}
                  checked={
                    values.businessType ===
                    I18n.t('components.createAlert.rent')
                  }
                  onChange={() =>
                    props.setFieldValue(
                      'businessType',
                      I18n.t('components.createAlert.rent'),
                    )
                  }
                />
              </Col>
            </Row>
            <Row className="FilterPriceRangeMobile__Row">
              <Checkbox
                mobile
                name="privateBusiness"
                label={I18n.t('components.filters.privateBusiness')}
                checked={values.privateBusiness}
                onChange={({ target: { checked } }) =>
                  props.setFieldValue('privateBusiness', checked)
                }
              />
            </Row>
            <Row between="xs" className="FilterPriceRangeMobile__Row">
              <Col>
                <Button
                  className="FilterBusinessType__Popover__Cancel"
                  btnStyle="link"
                  onClick={onCancel}
                >
                  {I18n.t('components.actions.cancel')}
                </Button>
              </Col>
              <Col>
                <Button btnStyle="link" type="submit">
                  {I18n.t('components.actions.ok')}
                </Button>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default FilterBusinessType
