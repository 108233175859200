import React, { Component } from 'react'
import { Formik, Checkbox } from 'components/Form'
import { Col, Row } from 'components/Grid'
import { Button } from 'components/Buttons'
import I18n from 'locales'
import {
  getHouseAsArray,
  getApartmentAsArray,
  getComercialAsArray,
  getOthersAsArray,
  getAllCategoriesAsArray,
} from 'utils/category'
import './index.css'

const house = getHouseAsArray()
const apartment = getApartmentAsArray()
const comercial = getComercialAsArray()
const others = getOthersAsArray()
const all = getAllCategoriesAsArray()

const selectCategory = ({ target: { checked } }, props, elements) => {
  elements.forEach(({ name }) => {
    props.setFieldValue(name, checked)
  })
}

const verifyAllOptions = (elements, values) => {
  for (let el of elements) {
    if (!values[el.name]) return false
  }

  return true
}

export const renderMacroCheckbox = (
  name,
  label,
  props,
  elements,
  values,
  mobile = false,
) => (
  <Row between="xs">
    <Col>
      <Checkbox
        name={name}
        mobile={mobile}
        checked={verifyAllOptions(elements, values)}
        label={label}
        onChange={event => {
          selectCategory(event, props, elements)
          props.setFieldValue(name, event.target.checked)
        }}
      />
    </Col>
  </Row>
)

const renderMicroCheckboxes = (elements, props, values) =>
  elements.map(({ name, prettyName }) => {
    return (
      <Row
        between="xs"
        key={name}
        className="FilterCategory__OptionContainer__SubOption"
      >
        <Col>
          <Checkbox
            name={name}
            label={prettyName}
            checked={values[name]}
            onChange={({ target: { checked } }) =>
              props.setFieldValue(name, checked)
            }
          />
        </Col>
      </Row>
    )
  })

const prepareLabel = (options, values, category = false) => {
  let inputLabel = ''
  if (values) {
    const selectedOptions = Object.keys(values).filter(
      key => values[key] && !key.includes('Filter'),
    )

    if (category) {
      selectedOptions.forEach((option, index) => {
        options.forEach(({ name, prettyName }) => {
          if (name === option && index === selectedOptions.length - 1)
            inputLabel += prettyName
          else if (name === option) inputLabel += `${prettyName}, `
        })
      })
    } else {
      selectedOptions.forEach((option, index) => {
        options.forEach(({ name, label }) => {
          if (name === option && index === selectedOptions.length - 1)
            inputLabel += label
          else if (name === option) inputLabel += `${label}, `
        })
      })
    }
  }

  return inputLabel
}

class MultiSelect extends Component {
  constructor() {
    super()
    this.state = {
      active: false,
    }
  }

  toggleContainer = () => {
    this.setState(state => ({ active: !state.active }))
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  handleClick = e => {
    if (this.node && this.node.contains(e.target)) {
      return
    }

    this.state.active && this.submit && this.submit()
    this.state.active &&
      this.submit &&
      setTimeout(() => this.toggleContainer(), 300)
  }

  render() {
    const { active } = this.state
    return (
      <div className="SelectInput__FormContainer">
        <label className="SelectInput__LabelInput">
          {this.props.label || ''}
        </label>
        <Button
          className="SelectInput"
          btnStyle="link"
          onClick={this.toggleContainer}
          disabled={this.props.disabled}
        >
          <div className="SelectInput__Label">
            {prepareLabel(
              this.props.category ? all : this.props.options,
              this.props.values,
              this.props.category,
            ) || I18n.t('components.actions.select')}
          </div>
          <div className="SelectInput__Arrow">
            <i className="material-icons">{`arrow_drop_${
              active ? 'up' : 'down'
            }`}</i>
          </div>
        </Button>
        {active && (
          <Formik
            onSubmit={formValue =>
              this.props.onChange(this.props.name, formValue)
            }
            initialValues={this.props.values}
          >
            {({ values, ...props }) => {
              this.submit = props.submitForm
              return (
                <div
                  className="SelectInput__Popover"
                  ref={el => (this.node = el)}
                >
                  {this.props.category ? (
                    <React.Fragment>
                      {renderMacroCheckbox(
                        'allFilter',
                        'Todos',
                        props,
                        all,
                        values,
                      )}
                      {renderMacroCheckbox(
                        'apartmentFilter',
                        'Apartamento',
                        props,
                        apartment,
                        values,
                      )}
                      {renderMicroCheckboxes(apartment, props, values)}
                      {renderMacroCheckbox(
                        'houseFilter',
                        'Casa',
                        props,
                        house,
                        values,
                      )}
                      {renderMicroCheckboxes(house, props, values)}
                      {renderMacroCheckbox(
                        'comercialFilter',
                        'Comercial',
                        props,
                        comercial,
                        values,
                      )}
                      {renderMicroCheckboxes(comercial, props, values)}
                      {renderMacroCheckbox(
                        'othersFilter',
                        'Outros',
                        props,
                        others,
                        values,
                      )}
                      {renderMicroCheckboxes(others, props, values)}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {this.props.options.map(option => (
                        <Row between="xs" key={option.name + option.label}>
                          <Col>
                            <Checkbox
                              name={option.name}
                              label={option.label}
                              checked={values[option.name]}
                              onChange={({ target: { checked } }) => {
                                props.setFieldValue(option.name, checked)
                              }}
                            />
                          </Col>
                        </Row>
                      ))}
                    </React.Fragment>
                  )}
                </div>
              )
            }}
          </Formik>
        )}
      </div>
    )
  }
}

export default MultiSelect
