import React, { Component, Fragment } from 'react'
import Header from 'components/Header'
import { formatRealties } from 'utils/formatRealties'
import MobileHeader from 'components/MobileHeader'
import {
  Formik,
  Form,
  Select,
  AutoComplete,
  CurrencyField,
  Checkbox,
  Range,
  SelectTypeahead,
} from 'components/Form'
import { fitBounds } from 'google-map-react/utils'
import MultiSelect from 'components/MultiSelect'
import Map from 'google-map-react'
import { Button } from 'components/Buttons'
import { Grid, Row, Col } from 'components/Grid'
import { MAP } from 'constants/map'
import DrawButton from 'components/Search/Map/DrawButton'
import StopDrawingButton from 'components/Search/Map/StopDrawingButton'
import { debounce, flatten } from 'lodash'
import MapService from 'services/map'
import I18n from 'locales'
import classNames from 'classnames'
import { ALL } from 'constants/categories'
import SearchClient from 'services/search'
import { getOptionalsByCategory } from 'services/filter'
import { REALTY_OPTIONS } from 'constants/search'
import { RealtyProvider } from 'context/Realty'
import { ShowRealty } from 'components/ShowRealty'
import Spinner from 'components/Spinner'
import { formatToLocale } from 'utils/numberFormatter'
import { cityId, userId } from 'services/auth'
import { doReport } from 'services/report'
import { callRequest } from 'services/requestHandler'
import { getPaymentStatus } from 'services/user'
import { getTransactionType } from 'utils/getTransactionType'
import { getCities, getCityById } from 'services/location'
import './index.css'

class Evaluation extends Component {
  constructor() {
    super()
    this.client = new SearchClient()
    this.state = {
      drawing: false,
      suggestions: [],
      query: '',
      radiusInfo: false,
      mapOptions: {
        zoom: MAP.defaultZoom,
        center: MAP.defaultCenter,
      },
      category: undefined,
      optionalsFilter: [],
      realties: [],
      loading: false,
      medianSquareMeterPrice: undefined,
      suggestedPrice: undefined,
      averageArea: '',
      businessType: '',
      address: '',
      rooms: '',
      garages: '',
      optionals: '',
      minSample: '',
      maxSample: '',
      area: '',
      usefulArea: true,
      totalArea: false,
      suggestedMinArea: 0,
      suggestedMaxArea: 0,
      selectedRealty: [],
      addressCoordinates: [],
      pristine: true,
      selectedCityId: '',
      hasHits: false,
      sample: null,
    }
    this.minUsefulArea = undefined
    this.maxUsefulArea = undefined
    this.minTotalArea = undefined
    this.maxTotalArea = undefined
    this.cities = []
  }

  componentWillMount() {
    if (userId() === 14801) window.location = I18n.t('routes.search')

    callRequest(getCities, data => {
      this.cities = data.map(city => ({
        label: `${city.name}-${city.state}`,
        value: city.id,
      }))
      this.forceUpdate()
    })
  }

  componentDidMount() {
    getPaymentStatus()
  }

  componentDidUpdate(prevProps, prevState) {
    this.shouldOptionalsUpdate(prevState)
  }

  shouldOptionalsUpdate(prevState) {
    const { category } = this.state
    let currentCategories = []
    let prevCategories = []
    if (category && Object.keys(category).length) {
      Object.keys(category).forEach(key => {
        if (category[key] && !key.includes('Filter'))
          currentCategories.push(ALL[key].name)
      })
    }

    if (prevState.category) {
      Object.keys(prevState.category).forEach(key => {
        if (prevState.category[key] && !key.includes('Filter'))
          prevCategories.push(ALL[key].name)
      })
    }

    if (
      currentCategories.length &&
      currentCategories.toString() !== prevCategories.toString()
    ) {
      callRequest(
        () => getOptionalsByCategory(currentCategories),
        data => this.setOptionals(data),
      )
    }

    if (prevCategories.length > 0 && !currentCategories.length) {
      this.setOptionals([])
    }
  }

  setOptionals = optionals => {
    this.setState({
      optionalsFilter: optionals.map(({ Id, Opcional }) => ({
        name: Opcional,
        label: Opcional,
      })),
    })
  }

  initializeMapService = google => {
    google.mobile = window.innerWidth < 768
    this.mapService = new MapService(google, this.callUpdate)
    this.callUpdate()
  }

  closeRadiusInfo = () => {
    this.setState({ radiusInfo: false })
  }

  callUpdate = () => {
    this.forceUpdate()
  }

  clearDrawing = () => {
    this.setState({ drawing: false })
    this.mapService.stopDrawing()
    this.mapService.clearPolygons()
    this.callUpdate()
  }

  resetEvaluation = () => {
    this.setState({
      drawing: false,
      suggestions: [],
      query: '',
      radiusInfo: false,
      mapOptions: {
        zoom: MAP.defaultZoom,
        center: MAP.defaultCenter,
      },
      category: undefined,
      optionalsFilter: [],
      realties: [],
      loading: false,
      medianSquareMeterPrice: undefined,
      suggestedPrice: undefined,
      averageArea: '',
      businessType: '',
      address: '',
      rooms: '',
      garages: '',
      optionals: '',
      minSample: '',
      maxSample: '',
      area: '',
      usefulArea: true,
      totalArea: false,
      suggestedMinArea: '',
      suggestedMaxArea: '',
      selectedRealty: [],
      selectedAddress: '',
      addressCoordinates: [],
      pristine: true,
      hasHits: false,
    })
    this.clearDrawing()
    this.minUsefulArea = undefined
    this.maxUsefulArea = undefined
    this.minTotalArea = undefined
    this.maxTotalArea = undefined
  }

  getAggregation = () => {
    const {
      businessType,
      category,
      rooms,
      garages,
      totalArea,
      usefulArea,
      optionals,
      minSample,
      maxSample,
      selectedCityId,
    } = this.state

    let { suggestedMaxArea = 0, suggestedMinArea = 0 } = this.state

    if (suggestedMaxArea === '0.0') suggestedMaxArea = 0
    if (suggestedMinArea === '0.0') suggestedMinArea = 0

    const transactionType = getTransactionType(businessType)

    const aggregations = [
      { type: 'percentiles', field: transactionType, value: 'Valor' },
      { type: 'percentiles', field: 'total_area', value: 'totalArea' },
      { type: 'percentiles', field: 'area', value: 'area' },
      { type: 'min', field: 'total_area', value: 'minTotalArea' },
      { type: 'max', field: 'total_area', value: 'maxTotalArea' },
      { type: 'avg', field: 'total_area', value: 'avgTotalArea' },
      { type: 'max', field: 'area', value: 'maxArea' },
      { type: 'min', field: 'area', value: 'minArea' },
      { type: 'avg', field: 'area', value: 'avgArea' },
    ]
    let options = {
      businessType,
      rooms,
      garages,
      minUsefulArea: usefulArea ? suggestedMinArea : 0,
      maxUsefulArea: usefulArea ? suggestedMaxArea : 0,
      minTotalArea: totalArea ? suggestedMinArea : 0,
      maxTotalArea: totalArea ? suggestedMaxArea : 0,
      priceRange: {
        minPrice: minSample,
        maxPrice: maxSample,
      },
      optionals,
      polygon: this.polygon,
      category,
      aggregations,
      source: REALTY_OPTIONS,
      mapped: true,
      noNeighborhood: true,
      cityId: selectedCityId || cityId(),
    }

    this.setState({ loading: true })

    this.client
      .search(null, {
        ...options,
        filterPaths: ['aggregations'],
      })
      .then(({ data: { aggregations } }) => {
        this.minTotalArea = aggregations.minTotalArea.value
        this.maxTotalArea = aggregations.maxTotalArea.value
        this.minUsefulArea = aggregations.minArea.value
        this.maxUsefulArea = aggregations.maxArea.value
        this.averageUsefulArea = aggregations.avgArea.value
        this.averageTotalArea = aggregations.avgTotalArea.value
        this.setState(
          {
            loading: false,
            suggestedMinArea: usefulArea
              ? (
                  aggregations.avgArea.value -
                  aggregations.avgArea.value * 0.3
                ).toFixed(1)
              : (
                  aggregations.avgTotalArea.value -
                  aggregations.avgTotalArea.value * 0.3
                ).toFixed(1),
            suggestedMaxArea: usefulArea
              ? (
                  aggregations.avgArea.value +
                  aggregations.avgArea.value * 0.3
                ).toFixed(1)
              : (
                  aggregations.avgTotalArea.value +
                  aggregations.avgTotalArea.value * 0.3
                ).toFixed(1),
            averageArea: usefulArea
              ? aggregations.avgArea.value
              : aggregations.avgTotalArea.value,
          },
          () => {
            if (
              this.polygon &&
              (!this.state.suggestedMaxArea ||
                this.state.suggestedMaxArea === '0.0')
            ) {
              window.M.toast({
                classes: 'Toast--failure',
                html: 'Não foram encontrado amostras com essas caracteristicas',
              })
            }
          },
        )
      })
      .catch(err => {
        console.log(err)
        this.setState({ loading: false })
      })
  }

  changeRadius = ({ radius }) => {
    const { selectedAddress } = this.state

    const parsedRadius = Number(radius)
    this.mapService.createCircle(
      {
        lat: selectedAddress.location.lat(),
        lng: selectedAddress.location.lng(),
      },
      parsedRadius,
    )
    this.panMap(this.state.mapOptions.zoom, {
      lat: selectedAddress.location.lat(),
      lng: selectedAddress.location.lng(),
    })
    this.polygon = this.mapService.getPolygonsAsArray()
    this.getAggregation()
    this.closeRadiusInfo()
  }

  drawPolygon = () => {
    if (this.mapService) {
      this.startDrawing()
      this.mapService.drawPolygon()
    }
  }

  drawFreeHand = () => {
    if (this.mapService) {
      this.startDrawing()
      this.mapService.drawFreeHand()
    }
  }

  renderRadiusInfo = () => {
    const { radiusInfo } = this.state
    const radiusClasses = classNames({
      'Evaluation__Pane--hidden': !radiusInfo,
      Evaluation__Pane: radiusInfo,
    })
    return (
      <div className={radiusClasses}>
        <div className="Evaluation__Radius">
          <Row end="xs">
            <Col xs={12}>
              <i className="material-icons" onClick={this.closeRadiusInfo}>
                close
              </i>
            </Col>
          </Row>
          <div className="Evaluation__RadiusContainer">
            <span>{I18n.t('radiusSearch.radiusSearch')}</span>
            <Formik
              initialValues={{ radius: 250 }}
              onSubmit={this.changeRadius}
            >
              {({ handleSubmit, handleChange, values: { radius } }) => (
                <form onSubmit={handleSubmit}>
                  <input name="radius" value={radius} onChange={handleChange} />
                  <Button type="submit" btnStyle="primary" btnSize="xs" compact>
                    <i className="material-icons">check</i>
                  </Button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    )
  }

  toggleRadius = () => {
    this.setState(state => ({ radiusInfo: !state.radiusInfo }))
  }

  handleGeocodeCallback = ({ center, zoom }) => {
    this.panMap(zoom, center)
  }

  panMap = (zoom, center) => {
    this.setState(({ mapOptions }) => ({
      mapOptions: {
        ...mapOptions,
        zoom,
        center,
      },
    }))
  }

  stopDrawing = () => {
    if (this.mapService) {
      this.mapService.stopDrawing()
      this.setState({ drawing: false })
      this.polygon = this.mapService.getPolygonsAsArray()
      this.getAggregation()
    }
  }

  startDrawing = () => {
    this.mapService.resetMap()
    this.setState({ drawing: true, realties: [] })
  }

  formatRealtiesCallback = ({ data }) => {
    this.setState({ realties: [] }, () =>
      this.setState({ pristine: false, realties: data }),
    )
  }

  goToReport = () => {
    const {
      businessType,
      query,
      category,
      rooms,
      garages,
      suggestedPrice,
      medianSquareMeterPrice,
      selectedRealty,
      addressCoordinates,
      selectedCityId,
      averageArea,
      sample,
      area,
      totalArea,
    } = this.state
    let categories = ''
    Object.keys(category).forEach((key, index) => {
      if (category[key] && !key.includes('Filter')) {
        if (index !== Object.keys(category).length - 1)
          categories += `${ALL[key].prettyName}, `
        else categories += ALL[key].prettyName
      }
    })
    callRequest(
      () => getCityById(selectedCityId || cityId()),
      data => {
        const report = {
          businessType,
          category: categories,
          address: query,
          neighborhoodId: null,
          rooms: rooms ? rooms : I18n.t('common.notInformed'),
          suites: null,
          garages: garages ? garages : I18n.t('common.notInformed'),
          value: formatToLocale(suggestedPrice),
          totalValue: formatToLocale(suggestedPrice, 2, 2),
          valuePerMeter: formatToLocale(medianSquareMeterPrice, 2, 2),
          realStateAmntStatistics: null,
          statisticAverage: formatToLocale(suggestedPrice),
          statisticAverageMeterValue: formatToLocale(medianSquareMeterPrice),
          lat: addressCoordinates[0],
          lng: addressCoordinates[1],
          cityId: selectedCityId || cityId(),
          city: `${data.name} - ${data.state}`,
          ids: selectedRealty.map(({ Id }) => Id),
          sample:
            selectedRealty.length > 0 ? selectedRealty.length : sample.value,
        }
        if (totalArea)
          report.totalArea = formatToLocale(area || averageArea, 1, 1)
        else report.usefulArea = formatToLocale(area || averageArea, 1, 1)
        this.setState({ loading: true })
        callRequest(
          () => doReport({ data: JSON.stringify(report) }),
          data => {
            this.setState({ loading: false })
            window.open(`/avaliacao-rgi/${data}`, '_tab')
          },
          null,
          () => this.setState({ loading: false }),
        )
      },
    )
  }

  selectRealty = (realty = null, all = null) => {
    let { selectedRealty } = this.state
    if (realty) {
      const index = selectedRealty.indexOf(realty)
      if (index === -1) selectedRealty.push(realty)
      else selectedRealty.splice(index, 1)
    }

    if (all) {
      selectedRealty = flatten(all)
    }

    this.setState({ selectedRealty })
  }

  clearRealty = () => {
    this.setState({ selectedRealty: [] })
  }

  searchEvaluation = values => {
    const {
      businessType,
      category,
      rooms,
      garages,
      totalArea,
      usefulArea,
      optionals,
      area,
      minSample,
      maxSample,
      suggestedMaxArea,
      suggestedMinArea,
      selectedRealty,
      selectedCityId,
      pristine,
    } = this.state

    const transactionType = getTransactionType(businessType)

    const aggregations = [
      { type: 'percentiles', field: transactionType, value: 'Valor' },
      { type: 'percentiles', field: 'area', value: 'area' },
      { type: 'percentiles', field: 'total_area', value: 'totalArea' },
      { type: 'min', field: 'total_area', value: 'minTotalArea' },
      { type: 'max', field: 'total_area', value: 'maxTotalArea' },
      { type: 'avg', field: 'total_area', value: 'avgTotalArea' },
      { type: 'min', field: 'area', value: 'minArea' },
      { type: 'max', field: 'area', value: 'maxArea' },
      { type: 'avg', field: 'area', value: 'avgArea' },
    ]

    const options = {
      businessType,
      rooms,
      garages,
      minUsefulArea: usefulArea
        ? suggestedMinArea
          ? Math.round(suggestedMinArea)
          : Math.round(Number(area) - area * 0.2)
        : 0,
      maxUsefulArea: usefulArea
        ? suggestedMaxArea
          ? Math.round(suggestedMaxArea)
          : Math.round(Number(area) + area * 0.2)
        : 0,
      minTotalArea: totalArea
        ? suggestedMinArea
          ? Math.round(suggestedMinArea)
          : Math.round(Number(area) - area * 0.2)
        : 0,
      maxTotalArea: totalArea
        ? suggestedMaxArea
          ? Math.round(suggestedMaxArea)
          : Math.round(Number(area) + area * 0.2)
        : 0,
      priceRange: {
        minPrice: minSample,
        maxPrice: maxSample,
      },
      optionals: Object.keys(optionals).map(key => key),
      polygon: this.polygon,
      category,
      aggregations,
      source: REALTY_OPTIONS,
      mapped: true,
      noNeighborhood: true,
      cityId: selectedCityId || cityId(),
    }

    const averageArea = usefulArea
      ? this.averageUsefulArea
      : this.averageTotalArea

    if (selectedRealty.length > 0) {
      let meters = selectedRealty.map(hit => {
        return {
          MeterPrice: hit.UsefulArea
            ? Number(hit.Price.replace(/\./g, '').replace(/,/g, '.')) /
              hit.TotalArea
            : 0,
          UsefulMeterPrice: hit.UsefulArea
            ? Number(hit.Price.replace(/\./g, '').replace(/,/g, '.')) /
              hit.UsefulArea
            : 0,
        }
      })

      this.setState(
        state => ({
          loading: false,
          medianSquareMeterPrice: this.extractSquareMeterPrice(
            meters,
            usefulArea,
          ),
          averageArea,
          suggestedMinArea: Number(area)
            ? (Number(area) - Number(area) * 0.2).toFixed(1)
            : (averageArea - averageArea * 0.3).toFixed(1),
          suggestedMaxArea: Number(area)
            ? (Number(area) + Number(area) * 0.2).toFixed(1)
            : (averageArea + averageArea * 0.3).toFixed(1),
        }),
        () =>
          this.setState(state => ({
            suggestedPrice: area
              ? area * state.medianSquareMeterPrice
              : averageArea * state.medianSquareMeterPrice,
          })),
      )
    } else {
      this.setState({ loading: true })
      this.client
        .search(null, {
          ...options,
          filterPaths: ['hits*'],
        })
        .then(({ data: { hits } }) => {
          const transactionType = getTransactionType(businessType)
          let meters = hits.hits.map(hit => {
            return {
              MeterPrice: hit._source.total_area
                ? hit._source[transactionType] / hit._source.total_area
                : 0,
              UsefulMeterPrice: hit._source.area
                ? hit._source[transactionType] / hit._source.area
                : 0,
            }
          })
          this.setState(
            state => ({
              loading: false,
              sample: hits.total,
              medianSquareMeterPrice: this.extractSquareMeterPrice(
                meters,
                usefulArea,
              ),
              hasHits: hits.hits.length > 0,
              averageArea,
              suggestedMinArea: Number(area)
                ? (Number(area) - Number(area) * 0.2).toFixed(1)
                : (averageArea - averageArea * 0.3).toFixed(1),
              suggestedMaxArea: Number(area)
                ? (Number(area) + Number(area) * 0.2).toFixed(1)
                : (averageArea + averageArea * 0.3).toFixed(1),
            }),
            () =>
              this.setState(state => ({
                suggestedPrice: area
                  ? area * state.medianSquareMeterPrice
                  : averageArea * state.medianSquareMeterPrice,
              })),
          )
          hits.hits &&
            formatRealties(hits.hits, businessType, this.formatRealtiesCallback)
        })
        .catch(err => {
          console.log(err)
          this.setState({ loading: false })
        })
    }
    if (!pristine)
      this.setState({ revaluated: true }, () =>
        setTimeout(() => this.setState({ revaluated: false }), 4000),
      )
  }

  extractSquareMeterPrice(data, usefulArea) {
    let value = {}
    if (usefulArea) {
      value = data.map(item => {
        return item.UsefulMeterPrice
      })
    } else {
      value = data.map(item => {
        return item.MeterPrice
      })
    }

    return this.median(value)
  }

  median = arr => {
    arr = arr.sort((a, b) => a - b)
    let i = arr.length / 2
    return i % 1 === 0
      ? ((arr[i - 1] + arr[i]) / 2) * 0.89
      : arr[Math.floor(i)] * 0.89
  }

  getAddressSuggestions = async () => {
    const { query, selectedCityId } = this.state
    getCityById(selectedCityId).then(async ({ data }) => {
      await this.mapService.geocode(
        `${query}, ${data.name}-${data.state}`,
        results => {
          this.setState({
            suggestions: results.map(result => ({
              name: result.formatted_address,
              value: result.geometry,
            })),
          })
        },
      )
    })
  }

  debouncedAddressSuggestions = debounce(this.getAddressSuggestions, 500)

  debouncedGetAggregation = debounce(this.getAggregation, 500)

  setAddress = (event, { suggestion }) => {
    const { value } = suggestion
    this.setState({
      addressCoordinates: [value.location.lat(), value.location.lng()],
      realties: [],
      selectedRealty: [],
      averageArea: '',
      suggestedPrice: undefined,
      medianSquareMeterPrice: '',
      hasHits: false,
    })
    this.handleGeocodeCallback(
      fitBounds(
        {
          ne: {
            lat: value.viewport.getNorthEast().lat(),
            lng: value.viewport.getNorthEast().lng(),
          },
          sw: {
            lat: value.viewport.getSouthWest().lat(),
            lng: value.viewport.getSouthWest().lng(),
          },
        },
        {
          width: this.mapService.map.getDiv().clientWidth,
          height: this.mapService.map.getDiv().clientHeight,
        },
      ),
    )
    this.setRadiusInfo(value)
    this.mapService.createCircle({
      lat: value.location.lat(),
      lng: value.location.lng(),
    })
  }

  setRadiusInfo = selectedAddress => {
    this.setState({ selectedAddress, radiusInfo: true })
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  changeArea = ({ target: { value } }) => {
    if (value) {
      this.setState({
        suggestedMinArea: Number(value) - Number(value) * 0.3,
        suggestedMaxArea: Number(value) + Number(value) * 0.3,
        area: Number(value.replace(',', '.')),
        hasHits: false,
        realties: [],
      })
    } else {
      const average = this.state.usefulArea
        ? this.averageUsefulArea
        : this.averageTotalArea
      this.setState({
        suggestedMinArea: formatToLocale(average - average * 0.2, 1, 1) || '',
        suggestedMaxArea: formatToLocale(average + average * 0.2, 1, 1) || '',
        area: value,
        hasHits: false,
        realties: [],
      })
    }
  }

  selectItem = (name, value) => {
    const arrayValues = this.state[name].split(',')
    const index = arrayValues.indexOf(value)

    if (index === -1) arrayValues.push(value)
    else arrayValues.splice(index, 1)

    this.setState(
      {
        [name]: arrayValues
          .filter(value => value !== '')
          .sort()
          .toString(),
        hasHits: false,
      },
      () => this.getAggregation(),
    )
  }

  renderMicroOption = (name, label, noZero = false) => {
    const baseClass = 'Evaluation__OptionButton'
    const zeroClasses = classNames(baseClass, {
      'Evaluation__OptionButton--active': this.state[name].includes('0'),
    })
    const oneClasses = classNames(baseClass, {
      'Evaluation__OptionButton--active': this.state[name].includes('1'),
    })
    const twoClasses = classNames(baseClass, {
      'Evaluation__OptionButton--active': this.state[name].includes('2'),
    })
    const threeClasses = classNames(baseClass, {
      'Evaluation__OptionButton--active': this.state[name].includes('3'),
    })
    const fourClasses = classNames(baseClass, {
      'Evaluation__OptionButton--active': this.state[name].includes('4'),
    })
    return (
      <React.Fragment>
        <Row className="Evaluation__Row">
          <Col xs={12} className="Evaluation__MicroOption__Label">
            <span className="Evaluation__MicroOption">{label}</span>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="Evaluation__ButtonContainer">
            {!noZero && (
              <div
                className={zeroClasses}
                onClick={() => this.selectItem(name, '0')}
              >
                0
              </div>
            )}
            <div
              className={oneClasses}
              onClick={() => this.selectItem(name, '1')}
            >
              1
            </div>
            <div
              className={twoClasses}
              onClick={() => this.selectItem(name, '2')}
            >
              2
            </div>
            <div
              className={threeClasses}
              onClick={() => this.selectItem(name, '3')}
            >
              3
            </div>
            <div
              className={fourClasses}
              onClick={() => this.selectItem(name, '4')}
            >
              4+
            </div>
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  render() {
    const {
      drawing,
      query,
      suggestions,
      mapOptions: { zoom, center },
      radiusInfo,
      optionalsFilter,
      realties,
      loading,
      medianSquareMeterPrice,
      hasHits,
      suggestedPrice,
      averageArea,
      businessType,
      category,
      optionals,
      rooms,
      garages,
      minSample,
      maxSample,
      area,
      usefulArea,
      totalArea,
      suggestedMinArea,
      suggestedMaxArea,
      selectedRealty,
      pristine,
      selectedCityId,
      revaluated,
      selectedAddress,
    } = this.state
    const isMobile = window.innerWidth < 768
    return (
      <Fragment>
        {isMobile ? <MobileHeader /> : <Header home />}
        <div className="Evaluation">
          <Grid className="Evaluation__Container">
            <Row>
              <Col xs={12}>
                <h1 className="Evaluation__Header">Avaliação de Imóveis</h1>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Formik
                  initialValues={{
                    category: '',
                    businessType: '',
                    address: '',
                    rooms: '',
                    garages: '',
                    optionals: '',
                    minSample: '',
                    maxSample: '',
                    area: '',
                    usefulArea: true,
                    totalArea: false,
                  }}
                  onSubmit={this.searchEvaluation}
                >
                  {({ handleSubmit, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col xs={12} md={6}>
                          <Select
                            name="businessType"
                            value={businessType}
                            label="Tipo de negócio"
                            onChange={event => {
                              this.setState({ realties: [] })
                              this.handleChange(event)
                            }}
                          >
                            <option value="Venda">Venda</option>
                            <option value="Locação">Locação</option>
                          </Select>
                        </Col>
                        <Col xs={12} md={6}>
                          <MultiSelect
                            category
                            name="category"
                            label="Tipo de imóveis"
                            onChange={(name, value) => {
                              this.setState({ [name]: value, realties: [] })
                              this.getAggregation()
                            }}
                            values={category}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6} style={{ position: 'relative' }}>
                          <SelectTypeahead
                            label="Cidade"
                            options={this.cities}
                            onChange={value => {
                              getCityById(value).then(({ data }) => {
                                this.panMap(
                                  data.eemovelProperties.zoom,
                                  data.eemovelProperties.coordinates,
                                )
                                this.setState({
                                  selectedCityId: value,
                                  realties: [],
                                })
                              })
                            }}
                          />
                        </Col>
                        <Col xs={12} md={6} style={{ position: 'relative' }}>
                          <AutoComplete
                            name="address"
                            label="Endereço"
                            placeholder="Digite um endereço ou CEP"
                            value={query}
                            disabled={!this.mapService || !selectedCityId}
                            autoComplete="off"
                            suggestions={suggestions}
                            onSuggestionSelected={this.setAddress}
                            onChange={(event, { newValue }) => {
                              this.setState({ query: newValue }, () =>
                                this.debouncedAddressSuggestions(),
                              )
                            }}
                            classes={{
                              optionContainer: 'Evaluation__OptionContainer',
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <div className="Evaluation__MapContainer">
                            <Map
                              bootstrapURLKeys={{
                                libraries: ['drawing', 'geometry'],
                                key: process.env.REACT_APP_GOOGLE_MAP_KEY,
                                region: 'BR',
                                language: 'pt-BR',
                              }}
                              rotateControl={false}
                              yesIWantToUseGoogleMapApiInternals
                              center={center}
                              zoom={zoom}
                              onGoogleApiLoaded={this.initializeMapService}
                              resetBoundsOnResize
                              options={maps => ({
                                resetBoundsOnResize: true,
                                gestureHandling: 'greedy',
                                mapTypeControl: true,
                                mapTypeControlOptions: {
                                  style: maps.MapTypeControlStyle.DROPDOWN_MENU,
                                  position: maps.ControlPosition.TOP_LEFT,
                                },
                              })}
                            />
                            <div className="Evaluation__MapBtnContainer">
                              {!drawing ? (
                                <Fragment>
                                  <DrawButton
                                    drawPolygon={this.drawPolygon}
                                    drawFreeHand={this.drawFreeHand}
                                    up
                                  />
                                  {selectedAddress && (
                                    <Button
                                      className="Evaluation__MapButton"
                                      onClick={this.toggleRadius}
                                    >
                                      <i className="material-icons">adjust</i>
                                      Mudar raio
                                    </Button>
                                  )}
                                </Fragment>
                              ) : (
                                <Fragment>
                                  <StopDrawingButton
                                    onClick={this.stopDrawing}
                                  />
                                  <Button
                                    className="Evaluation__MapButton"
                                    onClick={this.clearDrawing}
                                  >
                                    <i className="material-icons">close</i>
                                    {I18n.t('components.actions.cancel')}
                                  </Button>
                                </Fragment>
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <MultiSelect
                            label="Opcionais"
                            name="optionals"
                            options={optionalsFilter}
                            disabled={optionalsFilter.length === 0}
                            onChange={(name, value) =>
                              this.setState({ [name]: value, realties: [] })
                            }
                            values={optionals}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6}>
                          {this.renderMicroOption(
                            'rooms',
                            I18n.t('components.filters.room'),
                            true,
                          )}
                        </Col>
                        <Col xs={12} md={6}>
                          {this.renderMicroOption(
                            'garages',
                            I18n.t('components.filters.garages'),
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6}>
                          <Row>
                            <Col xs={12}>Valor</Col>
                          </Row>
                          <Row>
                            <Col xs={6} md={3}>
                              <CurrencyField
                                name="minSample"
                                placeholder="Min"
                                onChange={({ floatValue }) => {
                                  this.debouncedGetAggregation()
                                  this.setState({
                                    minSample: isNaN(floatValue)
                                      ? ''
                                      : floatValue,
                                  })
                                }}
                                value={minSample}
                              />
                            </Col>
                            <Col xs={6} md={3}>
                              <CurrencyField
                                name="maxSample"
                                placeholder="Max"
                                onChange={({ floatValue }) => {
                                  this.debouncedGetAggregation()
                                  this.setState({
                                    maxSample: isNaN(floatValue)
                                      ? ''
                                      : floatValue,
                                  })
                                }}
                                value={maxSample}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6}>
                          <Row>
                            <Col xs={12}>
                              <Col
                                xs={12}
                                md={6}
                                className="Evaluation__NoPadding"
                              >
                                <label
                                  htmlFor="area"
                                  className="Evaluation__Label"
                                >
                                  {I18n.t('evaluation.area')}
                                </label>
                                <input
                                  name="area"
                                  type="number"
                                  className="Evaluation__Input"
                                  placeholder="Área(m²)"
                                  onChange={this.changeArea}
                                  value={area}
                                />
                              </Col>
                            </Col>
                            <Col xs={6} md={3} className="Evaluation__Checkbox">
                              <Checkbox
                                name="usefulArea"
                                onChange={async () => {
                                  this.setState(
                                    {
                                      totalArea: false,
                                      usefulArea: true,
                                      realties: [],
                                    },
                                    () => this.getAggregation(),
                                  )
                                }}
                                label="Área útil"
                                checked={usefulArea}
                              />
                            </Col>
                            <Col xs={6} md={3} className="Evaluation__Checkbox">
                              <Checkbox
                                name="totalArea"
                                onChange={async () => {
                                  this.setState(
                                    {
                                      totalArea: true,
                                      usefulArea: false,
                                      realties: [],
                                    },
                                    () => this.getAggregation(),
                                  )
                                }}
                                label="Área total"
                                checked={totalArea}
                              />
                            </Col>
                          </Row>
                        </Col>
                        {(this.minUsefulArea && this.maxUsefulArea) ||
                        (this.minTotalArea && this.maxTotalArea) ? (
                          <Col xs={12} md={6}>
                            <Row>
                              <Col xs={6} md={3}>
                                <label
                                  htmlFor="suggestedMinArea"
                                  className="Evaluation__Label"
                                >
                                  {I18n.t('evaluation.minArea')}
                                </label>
                                <input
                                  className="Evaluation__Input"
                                  name="suggestedMinArea"
                                  placeholder="Min"
                                  onChange={this.handleChange}
                                  value={suggestedMinArea}
                                />
                              </Col>
                              <Col xs={6} md={3}>
                                <label
                                  htmlFor="suggestedMaxArea"
                                  className="Evaluation__Label"
                                >
                                  {I18n.t('evaluation.maxArea')}
                                </label>
                                <input
                                  className="Evaluation__Input"
                                  name="suggestedMaxArea"
                                  placeholder="Max"
                                  onChange={this.handleChange}
                                  value={suggestedMaxArea}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={6}>
                                <Range
                                  min={
                                    usefulArea
                                      ? this.minUsefulArea
                                      : this.minTotalArea || 0
                                  }
                                  max={
                                    usefulArea
                                      ? this.maxUsefulArea
                                      : this.maxTotalArea || 100
                                  }
                                  value={[
                                    Number(suggestedMinArea) || 0,
                                    Number(suggestedMaxArea) || 0,
                                  ]}
                                  onChange={values => {
                                    this.setState({
                                      suggestedMinArea: values[0],
                                      suggestedMaxArea: values[1],
                                      realties: [],
                                    })
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>
                        ) : null}
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <Button
                            btnStyle="primary"
                            onClick={handleSubmit}
                            block
                            disabled={
                              !suggestedMaxArea || suggestedMaxArea === '0.0'
                            }
                          >
                            {(pristine && I18n.t('evaluation.evaluate')) ||
                              I18n.t('evaluation.revaluate')}
                          </Button>
                        </Col>
                        <Col xs={6}>
                          <Button
                            btnStyle="flat"
                            onClick={this.resetEvaluation}
                            block
                          >
                            {I18n.t('evaluation.clearEvaluation')}
                          </Button>
                        </Col>
                      </Row>
                      {hasHits && (
                        <Row>
                          <Col xs={12}>
                            <Button
                              className="Evaluation__ReportButton"
                              btnStyle="flat"
                              block
                              onClick={this.goToReport}
                            >
                              {I18n.t('evaluation.report')}
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Grid>
          {hasHits && (
            <Row center="xs">
              <Col xs={12} md={6}>
                <Row>
                  <Col xs={12} className="Evaluation__BusinessType">
                    {this.state.businessType}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    {I18n.t('evaluation.area')}:{' '}
                    {area
                      ? area
                      : averageArea
                      ? `${averageArea.toFixed(2)}m²`
                      : 'Sem resultados'}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    {I18n.t('alert.garage')}:{' '}
                    {garages ? garages : I18n.t('common.notInformed')}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    {I18n.t('components.filters.rooms')}:{' '}
                    {rooms ? rooms : I18n.t('common.notInformed')}
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={6}>
                <Row>
                  <Col xs={12}>{I18n.t('evaluation.suggestedPrice')}</Col>
                </Row>
                <Row>
                  <Col xs={12} className="Evaluation__SuggestedPrice">
                    R$ {formatToLocale(suggestedPrice)}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="Evaluation__SquareMeterPrice">
                    {I18n.t('evaluation.squareMeterPrice')}
                    {formatToLocale(medianSquareMeterPrice)}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {hasHits && revaluated && (
            <span className="Evaluation__RevaluatedFeedback">
              {I18n.t('evaluation.successfullyRevaluated')}
            </span>
          )}
          {realties.length > 0 && (
            <RealtyProvider
              value={{
                realties,
                businessType,
                defaultColumnWidth: false,
                visible: realties.length > 0,
                modal: false,
                showClose: false,
                selectedRealty,
                clearRealty: this.clearRealty,
                selectRealty: this.selectRealty,
              }}
            >
              <ShowRealty />
            </RealtyProvider>
          )}
        </div>
        {radiusInfo && this.renderRadiusInfo()}
        {loading && <Spinner visible={loading} />}
      </Fragment>
    )
  }
}

export default Evaluation
