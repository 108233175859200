const businessType = {
  Venda: { name: 'Venda', value: 1 },
  venda: { name: 'Venda', value: 1 },
  transaction_sale: { name: 'Venda', value: 1 },
  1: { name: 'Venda', value: 1 },
  Locação: { name: 'Locação', value: 2 },
  locação: { name: 'Locação', value: 2 },
  Locacao: { name: 'Locação', value: 2 },
  locacao: { name: 'Locação', value: 2 },
  transaction_rent: { name: 'Locação', value: 2 },
  2: { name: 'Locação', value: 2 },
  Temporada: { name: 'Temporada', value: 3 },
  temporada: { name: 'Temporada', value: 3 },
  transaction_season: { name: 'Temporada', value: 3 },
  3: { name: 'Temporada', value: 3 },
}

export const getSearchParams = () => ({
  query: '',
  businessType: '',
  privateBusiness: false,
  drawing: false,
  priceRange: {},
  more: {
    fullAddress: true,
    rooms: '',
    suits: '',
    garages: '',
    minUsefulArea: 0,
    maxUsefulArea: 0,
    minTotalArea: 0,
    maxTotalArea: 0,
    building: '',
    realState: '',
    optionals: [],
    reference: '',
  },
  category: undefined,
  visualization: {
    neighborhoods: true,
    heatMap: false,
    zones: false,
    satellite: false,
  },
})

export const businessTypeEnum = cat => {
  return businessType[cat]
}

export const REALTY_OPTIONS = [
  'created_at',
  'sub_category_id',
  'sub_category_name',
  'neighborhood_name',
  'neighborhood_id',
  'city_id',
  'city_name',
  'city_uf',
  'processed_address_formatted',
  'processed_address_zip_code',
  'processed_address_building_name',
  'transaction_rent',
  'transaction_season',
  'transaction_sale',
  'features_bedroom',
  'features_garage',
  'total_area',
  'area',
  'broker_id',
  'broker_name',
  'broker_raw',
  'amenities',
  'link',
  'sort',
  'is_private',
  'geo_process_pinned',
  'zoning_id',
  'zoning_name',
  'location',
  'meter_price',
  'useful_meter_price',
  'geo_process_address_status',
]
