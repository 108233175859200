import React from 'react'
import { Link } from 'react-router-dom'
import I18n from 'locales'
import { authed, userId } from 'services/auth'
import './index.css'

const onClick = () => {
  if (window.location.pathname === '/') {
    window.scrollTo(0, 500)
  } else {
    setTimeout(() => {
      window.scrollTo(0, 500)
    }, 500)
  }
}

const NavMenu = () => (
  <nav className="NavMenu">
    {authed() ? (
      <React.Fragment>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://faq.eemovel.com.br/"
          className="NavMenu__Link"
        >
          {I18n.t('components.navBar.support')}
        </a>
        <Link to={I18n.t('routes.search')} className="NavMenu__Link">
          {I18n.t('components.navBar.search')}
        </Link>
        {userId() !== 14801 && (
          <Link to={I18n.t('routes.evaluation')} className="NavMenu__Link">
            {I18n.t('components.navBar.evaluation')}
          </Link>
        )}
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Link to="/" className="NavMenu__Link" onClick={onClick}>
          {I18n.t('components.navBar.ourTools')}
        </Link>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://faq.eemovel.com.br/"
          className="NavMenu__Link"
        >
          {I18n.t('components.navBar.support')}
        </a>
        <Link to={I18n.t('routes.whereWeAct')} className="NavMenu__Link">
          {I18n.t('components.navBar.whereWeAct')}
        </Link>
      </React.Fragment>
    )}
  </nav>
)

export default NavMenu
