import React from 'react'
import classNames from 'classnames'
import { CurrencyField } from 'components/Form'
import { FastField, Formik } from 'formik'
import { Col, Row } from 'components/Grid'
import { Button } from 'components/Buttons'
import PriceRangeInfo from 'components/Search/FilterPriceRange/PriceRangeInfo'
import I18n from 'locales'
import './index.css'

const formatingOptions = {
  displayType: 'input',
  decimalSeparator: ',',
  thousandSeparator: '.',
  decimalScale: 2,
  prefix: 'R$',
}

const handleOnChange = (value, field, setFieldValue) => {
  if (!isNaN(value)) setFieldValue(field, value)
}

const handleOnBlur = ({ minPrice, maxPrice }, setFieldValue) => {
  if (minPrice > maxPrice) {
    setFieldValue('minPrice', maxPrice)
    setFieldValue('maxPrice', minPrice)
  }
}

const verifyBeforeSubmit = (values, onSubmit) => {
  if (!values.minPrice) values.minPrice = 0
  if (!values.maxPrice) values.maxPrice = 0
  if (values.minPrice > values.maxPrice) {
    let step = values.maxPrice
    values.maxPrice = values.minPrice
    values.minPrice = step
  }
  onSubmit(values)
}

const FilterPriceRange = ({
  className,
  onSubmit,
  onCancel,
  initialValues,
  ...rest
}) => {
  const classes = classNames(
    'SearchFilter',
    'FilterPriceRangeMobile',
    className,
  )

  return (
    <div className={classes}>
      <Formik
        onSubmit={values => verifyBeforeSubmit(values, onSubmit)}
        initialValues={{ minPrice: 0, maxPrice: 0, ...initialValues }}
      >
        {({ values, setFieldValue, ...props }) => (
          <form
            onSubmit={props.handleSubmit}
            className="FilterPriceRangeMobile__Popover"
          >
            <Row className="FilterPriceRangeMobile__Row">
              <Col>
                <PriceRangeInfo
                  range={{
                    min: rest.minPriceAllowed,
                    max: rest.maxPriceAllowed,
                  }}
                />
              </Col>
            </Row>
            <Row className="FilterPriceRangeMobile__Row">
              <Col xs={6}>
                <FastField
                  component={CurrencyField}
                  label={I18n.t('common.from')}
                  name="minPrice"
                  pattern="[0-9]*"
                  placeholder={I18n.t('common.from')}
                  value={values.minPrice || ''}
                  onFocus={rest.setSearching}
                  onChange={({ floatValue }) =>
                    handleOnChange(floatValue, 'minPrice', setFieldValue)
                  }
                  {...formatingOptions}
                />
              </Col>
              <Col xs={6}>
                <FastField
                  component={CurrencyField}
                  label={I18n.t('common.to')}
                  pattern="[0-9]*"
                  placeholder={I18n.t('common.to')}
                  name="maxPrice"
                  value={values.maxPrice || ''}
                  onFocus={rest.setSearching}
                  onChange={({ floatValue }) =>
                    handleOnChange(floatValue, 'maxPrice', setFieldValue)
                  }
                  onBlur={() => handleOnBlur(values, setFieldValue)}
                  {...formatingOptions}
                />
              </Col>
            </Row>
            <Row between="xs" className="FilterPriceRangeMobile__Row">
              <Col>
                <Button
                  className="FilterPriceRange__Popover__Cancel"
                  btnStyle="link"
                  onClick={onCancel}
                >
                  {I18n.t('components.actions.cancel')}
                </Button>
              </Col>
              <Col>
                <Button btnStyle="link" type="submit">
                  {I18n.t('components.actions.ok')}
                </Button>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </div>
  )
}

FilterPriceRange.defaultProps = {
  minPriceAllowed: 0,
  maxPriceAllowed: 99999999,
}

export default FilterPriceRange
