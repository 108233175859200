import React, { Fragment } from 'react'
import { Col, Row, Grid } from 'components/Grid'
import { Button } from 'components/Buttons'
import SearchClient from 'services/search'
import { flatten } from 'lodash'
import { downloadEvaluationReport, doReport } from 'services/report'
import { formatToLocale } from 'utils/numberFormatter'
import { SearchConsumer } from 'context/Search'
import { RealtyProvider } from 'context/Realty'
import { formatRealties } from 'utils/formatRealties'
import { ShowRealty } from 'components/ShowRealty'
import MapService from 'services/map'
import Map from 'google-map-react'
import I18n from 'i18n-js'
import Spinner from 'components/Spinner'
import { businessTypeEnum } from 'constants/search'
import logo from './logo.png'
import './index.css'

class EvaluationReportPage extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      realty: undefined,
      zone: null,
      neighborhood: null,
      loading: false,
    }

    this.realtiesForDownload = []
    this.client = context.client || new SearchClient()
  }

  setRealty(realty) {
    this.setState({ realty: { sample: realty.sample.value, ...realty } })
  }

  initializeMapService = google => {
    this.mapService = this.context.mapService || new MapService(google)
    this.initializeStreetView()
  }

  initializeStreetView = () => {
    const {
      realty: { lat, lng },
    } = this.state
    const fenway = {
      lat: Number(lat),
      lng: Number(lng),
    }
    this.mapService.initializeStreetView(
      fenway,
      'streetView',
      this.handleStreetViewChange,
    )
  }

  selectRealty = (realty = null, all = null) => {
    let { selectedRealty } = this.state
    if (realty) {
      const index = selectedRealty.indexOf(realty)
      if (index === -1) selectedRealty.push(realty)
      else selectedRealty.splice(index, 1)
    }

    if (all) {
      selectedRealty = flatten(all)
    }

    this.setState({ selectedRealty })
  }

  componentDidMount() {
    const { report } = this.props.match.params
    doReport({ id: report }, true)
      .then(({ data, status, statusText }) => {
        if (status === 200) {
          const { lat, lng } = data.valuationParams
          this.staticImage = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=17&scale=2&size=640x350&markers=color:red%7Clabel:%7C${lat},${lng}&key=${
            process.env.REACT_APP_GOOGLE_MAP_KEY
          }`
          this.setState(
            {
              realty: data.valuationParams,
              realties: data.realtyList
                ? formatRealties(
                    data.realtyList,
                    data.valuationParams.businessType,
                    this.formatRealtiesCallback,
                  )
                : [],
              neighborhood: data.neighborhoodReport,
              zone: data.zoningReport,
            },
            () => (this.realtiesForDownload = this.state.realties),
          )
        } else {
          window.M.toast({ html: statusText, classes: 'Toast--failure' })
        }
      })
      .catch(err => {
        console.error(err)
        window.M.toast({
          html: I18n.t('common.fail'),
          classes: 'Toast--failure',
        })
      })
  }

  formatRealtiesCallback = ({ data }) => {
    this.setState({ realties: data }, () => {
      this.realtiesForDownload = data.map(item => {
        const price = parseFloat(
          item.Price.replace(/\./g, '').replace(/,/g, '.'),
        )
        return {
          AreaUtil: item.UsefulArea,
          Id: item.Id,
          AreaTotal: item.TotalArea,
          Categoria: item.Category,
          Cidade: item.City,
          Quartos: item.Bedrooms,
          Garagens: item.Garages,
          Imobiliaria: item.RealState,
          Valor: price,
          ValorMetro: item.TotalArea ? price / item.TotalArea : 0,
          ValorMetroUtil: item.UsefulArea ? price / item.UsefulArea : 0,
          Longitude:
            item.location && item.location.coordinates
              ? item.location.coordinates[0]
              : null,
          Latitude:
            item.location && item.location.coordinates
              ? item.location.coordinates[1]
              : null,
          Link: item.link,
          TipoNegocio: businessTypeEnum(item.BusinessType).name,
          Edificio: item.Building,
          Localizacao: item.Neighborhood || '',
          Endereco: item.Address,
          DataAnuncio: new Date(item.AdDate),
          DataInclusao: new Date(item.AdDate),
        }
      })
    })
  }

  handleStreetViewChange = (lat, lng, heading, pitch) => {
    this.streetImage = `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${lat},${lng}&fov=120&heading=${heading}&pitch${pitch}&key=${
      process.env.REACT_APP_GOOGLE_MAP_KEY
    }`
  }

  handleDownloadClick = () => {
    const { zone, realty, neighborhood } = this.state
    //refatorar
    let dateDt = []
    let valorDt = []
    let ValorMetroUtil = []
    let ValorMetroTotal = []
    this.realtiesForDownload.forEach(imovel => {
      let dataAnuncio = imovel.DataAnuncio
        ? new Date(imovel.DataAnuncio)
        : new Date(imovel.DataInclusao)
      dataAnuncio.setDate(dataAnuncio.getDate() + 1)
      let dataformatada =
        Math.round((new Date() - dataAnuncio) / (1000 * 60 * 60 * 24)) +
        ' dia(s)'
      let valordtformatado = imovel.Valor
      let valorMetroUtilformatado =
        imovel.ValorMetroUtil === 0
          ? '-'
          : formatToLocale(imovel.ValorMetroUtil)
      let valorMetroTotalformatado =
        imovel.ValorMetro === !isFinite(imovel.ValorMetro)
          ? null
          : formatToLocale(imovel.ValorMetro)
      dateDt.push(dataformatada)
      valorDt.push(formatToLocale(valordtformatado))
      ValorMetroUtil.push(valorMetroUtilformatado)
      ValorMetroTotal.push(valorMetroTotalformatado)
    })

    this.setState({ loading: true })
    downloadEvaluationReport({
      Alturamaxima: zone && zone.maxHeight,
      Area: neighborhood ? neighborhood.area : null,
      Areamin: (zone && zone.minArea) || '',
      Areatotal: realty.totalArea + 'm²',
      Bairro: neighborhood ? neighborhood.name : null,
      Ca: zone && zone.ca,
      Cidade: realty.city.replace(' - ', '-'),
      DataTableImoveis: this.realtiesForDownload,
      Date: dateDt,
      Densidade: neighborhood
        ? neighborhood.density
          ? neighborhood.density
          : null
        : null,
      Domicilio: neighborhood ? neighborhood.residence : null,
      Endereco: realty.address,
      Garagem:
        realty.garages === I18n.t('common.notInformed') ? 0 : realty.garages,
      Imagestreetview: this.streetImage,
      Imagemap: this.staticImage,
      Latitutde: `${realty.lat}`,
      Longitude: `${realty.lng}`,
      Mediana: realty.statisticAverage,
      Populacao: neighborhood ? neighborhood.population : null,
      Quantidade: (realty.ids && realty.ids.length) || 0,
      Quarto: realty.rooms === I18n.t('common.notInformed') ? '' : realty.rooms,
      Recuomin: (zone && zone.minRecoil) || '',
      Rendadomiciliar: neighborhood ? neighborhood.householdIncome : null,
      Rendapercapta: neighborhood ? neighborhood.perCapitaIncome : null,
      Taxapermeabilidade: (zone && zone.permRate) || 0,
      Testadamin: (zone && zone.minTested) || '',
      Tipodoimovel: realty.category,
      Tipodonegocio: realty.businessType,
      To: zone && zone.to,
      Valor: valorDt,
      ValorMetroTotal,
      ValorMetroUtil,
      Valormeadian: realty.statisticAverageMeterValue,
      Valormetro: `R$ ${realty.valuePerMeter}`,
      Valorsugerido: `R$ ${realty.totalValue}`,
      Valortotal: `R$ ${realty.totalValue}`,
      Zoneamento: (zone && zone.name) || '',
    })
      .then(({ request }) => {
        this.setState({ loading: false })
        request.onloadend = function(event) {
          if (request.status === 200) {
            let link = document.createElement('a')
            document.body.appendChild(link)
            link.download = 'Relatorio.pdf'
            link.href = window.URL.createObjectURL(request.response)
            link.click()
          }
        }
      })
      .catch(err => {
        console.error(err)
        this.setState({ loading: false })
      })
  }

  handleError = err => {
    console.error(err)
  }

  renderRealtyFeatures = () => {
    const { realty } = this.state
    return (
      realty && (
        <Col md>
          <Row>
            <Col md={5} className="EvaluationReport__InnerCol">
              <span className="EvaluationReport__Content--bold">
                {I18n.t('report.realty.rooms')}
              </span>
            </Col>
            <Col md className="EvaluationReport__InnerCol">
              {realty.rooms || I18n.t('common.notInformed')}
            </Col>
          </Row>
          <Row>
            <Col md={5} className="EvaluationReport__InnerCol">
              <span className="EvaluationReport__Content--bold">
                {I18n.t('report.realty.garages')}
              </span>
            </Col>
            <Col md className="EvaluationReport__InnerCol">
              {realty.garages || I18n.t('common.notInformed')}
            </Col>
          </Row>
          <Row>
            <Col md={5} className="EvaluationReport__InnerCol">
              <span className="EvaluationReport__Content--bold">
                {realty.totalArea && realty.totalArea !== 0
                  ? I18n.t('report.realty.totalArea')
                  : 'Área útil'}
              </span>
            </Col>
            <Col md className="EvaluationReport__InnerCol">
              {realty.totalArea || realty.usefulArea} m²
            </Col>
          </Row>
          <Row>
            <Col md={5} className="EvaluationReport__InnerCol">
              <span className="EvaluationReport__Content--bold">
                {I18n.t('report.realty.valuePerMeter')}
              </span>
            </Col>
            <Col md className="EvaluationReport__InnerCol">
              {`R$ ${formatToLocale(realty.valuePerMeter)}`}
            </Col>
          </Row>
        </Col>
      )
    )
  }

  renderNeighborhoodFeatures = () => {
    const { neighborhood } = this.state
    return (
      neighborhood &&
      neighborhood.density !== I18n.t('common.notInformed') && (
        <Row className="EvaluationReport__Row__Content">
          <Col md>
            <Row>
              <Col md={5} className="EvaluationReport__InnerCol">
                <span className="EvaluationReport__Content--bold">
                  {I18n.t('report.population')}
                </span>
              </Col>
              <Col
                md
                className="EvaluationReport__InnerCol"
              >{`${neighborhood.population || 0} habitantes`}</Col>
            </Row>
            <Row>
              <Col md={5} className="EvaluationReport__InnerCol">
                <span className="EvaluationReport__Content--bold">
                  {I18n.t('report.residence')}
                </span>
              </Col>
              <Col
                md
                className="EvaluationReport__InnerCol"
              >{`${neighborhood.domicile || 0} moradias`}</Col>
            </Row>
            <Row>
              <Col md={5} className="EvaluationReport__InnerCol">
                <span className="EvaluationReport__Content--bold">
                  {I18n.t('report.householdeIncome')}
                </span>
              </Col>
              <Col md className="EvaluationReport__InnerCol">
                {neighborhood.householdIncome === I18n.t('common.notInformed')
                  ? neighborhood.householdIncome
                  : `R$ ${formatToLocale(neighborhood.householdIncome)}`}
              </Col>
            </Row>
            <Row>
              <Col md={5} className="EvaluationReport__InnerCol">
                <span className="EvaluationReport__Content--bold">
                  {I18n.t('report.perCapitaIncome')}
                </span>
              </Col>
              <Col md className="EvaluationReport__InnerCol">
                {neighborhood.perCapitaIncome === I18n.t('common.notInformed')
                  ? neighborhood.perCapitaIncome
                  : `R$ ${formatToLocale(neighborhood.perCapitaIncome)}`}
              </Col>
            </Row>
            <Row>
              <Col md={5} className="EvaluationReport__InnerCol">
                <span className="EvaluationReport__Content--bold">
                  {I18n.t('report.area')}
                </span>
              </Col>
              <Col md className="EvaluationReport__InnerCol">{`${
                neighborhood.area
              } km²`}</Col>
            </Row>
            <Row>
              <Col md={5} className="EvaluationReport__InnerCol">
                <span className="EvaluationReport__Content--bold">
                  {I18n.t('report.density')}
                </span>
              </Col>
              <Col md className="EvaluationReport__InnerCol">{`${formatToLocale(
                neighborhood.density,
              )} hab/km²`}</Col>
            </Row>
          </Col>
        </Row>
      )
    )
  }

  renderEvaluation = () => {
    const {
      realty: { totalValue },
    } = this.state
    return (
      <Row className="EvaluationReport__Row__Content">
        <Col md>
          <Row>
            <Col
              md
              className="EvaluationReport__InnerCol EvaluationReport__SuggestedValue"
            >
              {`R$ ${formatToLocale(totalValue)}`}
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  renderRegionFeatures = () => {
    const {
      realty: { ids, sample, statisticAverage, statisticAverageMeterValue },
    } = this.state
    return (
      (statisticAverage || statisticAverageMeterValue) && (
        <Row className="EvaluationReport__Row__Content">
          <Col md>
            <Row>
              <Col md={5} className="EvaluationReport__InnerCol">
                <span className="EvaluationReport__Content--bold">
                  {I18n.t('report.sample')}
                </span>
              </Col>
              {ids && (
                <Col md className="EvaluationReport__InnerCol">
                  {sample.value ? sample.value : sample}
                </Col>
              )}
            </Row>
            <Row>
              <Col md={5} className="EvaluationReport__InnerCol">
                <span className="EvaluationReport__Content--bold">
                  {I18n.t('report.medianSquareMeter')}
                </span>
              </Col>
              <Col
                md
                className="EvaluationReport__InnerCol"
              >{`R$ ${formatToLocale(statisticAverageMeterValue)}`}</Col>
            </Row>
            <Row>
              <Col md={5} className="EvaluationReport__InnerCol">
                <span className="EvaluationReport__Content--bold">
                  {I18n.t('report.medianPrice')}
                </span>
              </Col>
              <Col
                md
                className="EvaluationReport__InnerCol"
              >{`R$ ${formatToLocale(statisticAverage)}`}</Col>
            </Row>
          </Col>
        </Row>
      )
    )
  }

  renderZoneFeatures = () => {
    const { zone } = this.state
    return (
      zone && (
        <Row className="EvaluationReport__Row__Content">
          <Col md>
            <Row>
              <Col md={5} className="EvaluationReport__InnerCol">
                <span className="EvaluationReport__Content--bold">
                  {I18n.t('report.initials')}
                </span>
              </Col>
              <Col md className="EvaluationReport__InnerCol">
                {zone.initials}
              </Col>
            </Row>
            <Row>
              <Col md={5} className="EvaluationReport__InnerCol">
                <span className="EvaluationReport__Content--bold">CA</span>
              </Col>
              <Col md className="EvaluationReport__InnerCol">
                {zone.ca}
              </Col>
            </Row>
            <Row>
              <Col md={5} className="EvaluationReport__InnerCol">
                <span className="EvaluationReport__Content--bold">TO </span>
              </Col>
              <Col md className="EvaluationReport__InnerCol">
                {zone.to}
              </Col>
            </Row>
            <Row>
              <Col md={5} className="EvaluationReport__InnerCol">
                <span className="EvaluationReport__Content--bold">
                  {I18n.t('report.maxHeight')}
                </span>
              </Col>
              <Col md className="EvaluationReport__InnerCol">
                {zone.maximumHeight}
              </Col>
            </Row>
            <Row>
              <Col md={5} className="EvaluationReport__InnerCol">
                <span className="EvaluationReport__Content--bold">
                  {I18n.t('report.minRecoil')}
                </span>
              </Col>
              <Col md className="EvaluationReport__InnerCol">
                {zone.minIndent}
              </Col>
            </Row>
            <Row>
              <Col md={5} className="EvaluationReport__InnerCol">
                <span className="EvaluationReport__Content--bold">
                  {I18n.t('report.permeabilityRate')}
                </span>
              </Col>
              <Col md className="EvaluationReport__InnerCol">
                {zone.permeabilityRate}
              </Col>
            </Row>
            <Row>
              <Col md={5} className="EvaluationReport__InnerCol">
                <span className="EvaluationReport__Content--bold">
                  {I18n.t('report.minTestedGround')}
                </span>
              </Col>
              <Col md className="EvaluationReport__InnerCol">
                {zone.minFrontage}
              </Col>
            </Row>
            <Row>
              <Col md={5} className="EvaluationReport__InnerCol">
                <span className="EvaluationReport__Content--bold">
                  {I18n.t('report.minArea')}
                </span>
              </Col>
              <Col md className="EvaluationReport__InnerCol">
                {zone.minArea}
              </Col>
            </Row>
          </Col>
        </Row>
      )
    )
  }

  renderTitle = title => {
    return (
      <Fragment>
        <div className="EvaluationReport__Content__Features__Title">
          {title}
        </div>
        <div className="EvaluationReport__GoldLine" />
      </Fragment>
    )
  }

  render() {
    const { realty, neighborhood, zone, realties, loading } = this.state
    return realty ? (
      <Grid fluid>
        <Row around="md" className="EvaluationReport">
          <Col md={10}>
            <Row className="EvaluationReport__Header Report__Row">
              <Col className="EvaluationReport__Header__Title" xs={12} md={6}>
                {I18n.t('report.realtyReport')}
              </Col>
              <Col xs={12} md={6}>
                <Row end="md">
                  <Col xs={12} className="EvaluationReport__Header__Logo">
                    <img
                      className="EvaluationReport__Header__Logo__Image"
                      src={logo}
                      alt="Logo"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row around="md">
              <Col md={12}>
                <Row
                  around="md"
                  className="EvaluationReport__Content__Address Report__Row"
                >
                  <Col md={12}>
                    <b>{I18n.t('report.typology') + '  '}</b>
                    {`${realty.category} - ${
                      businessTypeEnum(realty.businessType).name
                    }`}
                  </Col>
                  <Col md={12}>
                    <b>{I18n.t('report.realty.address') + '  '}</b>
                    {realty.address}
                  </Col>
                </Row>
                <Row
                  center="md"
                  className="EvaluationReport__Content__Maps Report__Row"
                >
                  <Col
                    md={6}
                    className="EvaluationReport__Content__Maps__Static"
                  >
                    {realty && <img alt="Mapa" src={this.staticImage} />}
                  </Col>
                  <Col
                    md={6}
                    className="EvaluationReport__Content__Maps__StreetView"
                  >
                    <div
                      id="streetView"
                      style={{ width: '100%', height: '100%' }}
                    />
                    <Map
                      bootstrapURLKeys={{
                        libraries: ['drawing', 'geometry'],
                        key: process.env.REACT_APP_GOOGLE_MAP_KEY,
                      }}
                      yesIWantToUseGoogleMapApiInternals
                      onGoogleApiLoaded={this.initializeMapService}
                      center={[0, 0]}
                      zoom={4}
                      style={{ display: 'none' }}
                    />
                  </Col>
                </Row>
                <Row
                  around="md"
                  className="EvaluationReport__Content__Features Report__Row"
                >
                  <Col
                    md={6}
                    className="EvaluationReport__Content__Features__Realty"
                  >
                    {this.renderTitle(I18n.t('report.realtyFeatures'))}
                    <Row className="EvaluationReport__Row__Content">
                      {this.renderRealtyFeatures()}
                    </Row>
                  </Col>
                  <Col
                    md={6}
                    className="EvaluationReport__Content__Features__Neighborhood"
                  >
                    {neighborhood &&
                      neighborhood.density !== I18n.t('common.notInformed') &&
                      this.renderTitle(I18n.t('alert.neighborhood'))}
                    {this.renderNeighborhoodFeatures()}
                  </Col>
                </Row>
                <Row className="EvaluationReport__Content__Features Report__Row">
                  <Col
                    md={6}
                    className="EvaluationReport__Content__Features__Region"
                  >
                    {this.renderTitle(I18n.t('report.regionMetrics'))}
                    {this.renderRegionFeatures()}
                  </Col>
                  {zone && (
                    <Col
                      md={6}
                      className="EvaluationReport__Content__Features__Zone"
                    >
                      {this.renderTitle(I18n.t('report.zoning'))}
                      {this.renderZoneFeatures()}
                    </Col>
                  )}
                </Row>
                <Row
                  around="md"
                  className="EvaluationReport__Content__Features Report__Row"
                >
                  <Col
                    md={12}
                    className="EvaluationReport__Content__Features__Estimative"
                  >
                    {this.renderTitle(I18n.t('report.estimative'))}
                    {this.renderEvaluation()}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {realties && realties.length > 0 && (
            <Col md={10}>
              <RealtyProvider
                value={{
                  realties,
                  defaultColumnWidth: `${98 / 11}%`,
                  visible: true,
                  modal: false,
                  showClose: false,
                  toggle: null,
                  selectedRealty: null,
                  selectRealty: null,
                  exportable: false,
                  selectable: false,
                  showActions: false,
                }}
              >
                <ShowRealty />
              </RealtyProvider>
            </Col>
          )}
        </Row>
        <Button
          btnStyle="primary"
          className="EvaluationReport__Download"
          compact
          onClick={this.handleDownloadClick}
        >
          <i className="material-icons">cloud_download</i>
        </Button>
        <Spinner visible={loading} />
      </Grid>
    ) : null
  }
}

EvaluationReportPage.contextType = SearchConsumer
export default EvaluationReportPage
