import React, { Component, Fragment } from 'react'
import { Grid, Row, Col } from 'components/Grid'
import { Button } from 'components/Buttons'
import { Formik, Field, Form } from 'components/Form'
import I18n from 'locales'
import * as Yup from 'yup'
import {
  getManagedAccounts,
  registerBroker,
  removeBroker,
} from 'services/manager'
import {
  Table,
  Row as TableRow,
  Col as TableCol,
} from 'components/AlertTable/styles'
import ConfirmDialog from 'components/ConfirmDialog'
import { findIndex } from 'lodash'
import './index.css'

const AccountsSchema = Yup.object().shape({
  email: Yup.string()
    .email(I18n.t('login.invalidEmail'))
    .required(I18n.t('common.required')),
  name: Yup.string().required(I18n.t('common.required')),
})

class Accounts extends Component {
  constructor() {
    super()
    this.state = {
      accountsAmount: 0,
      name: '',
      email: '',
      users: [],
      addUser: false,
      limit: 4,
      removing: undefined,
    }
  }

  componentWillMount() {
    const { user } = this.props
    getManagedAccounts(user.userId).then(({ data }) => {
      const max = data.pessoas.length + data.numeroContas
      this.setState({
        limit: max != null ? max : 4,
        accountsAmount: data.numeroContas,
        users: data.pessoas.map(item => ({
          id: item.Id,
          name: item.Name,
          email: item.Email,
          updateDate: new Date(item.UpdatedAt),
          creationDate: new Date(item.CreatedAt),
          removed: item.Deleted,
          managerId: item.ManagerId,
          status: '',
        })),
      })
    })
  }

  renderRow = (user, index) => {
    return (
      <TableRow key={user.name + index}>
        {this.renderCol(user.name, 'Nome', '32.5%')}
        {this.renderCol(user.email, 'Email', '32.5%')}
        {this.renderCol(user.status || 'Cadastrado', 'Status', '27.5%')}
        <TableCol
          title="Excluir"
          action="1"
          width="7.5%"
          label="Excluir"
          textCenter
        >
          <Button
            title="Excluir"
            btnStyle="flat"
            btnSize="sm"
            compact
            block
            onClick={() => this.setState({ removing: user })}
          >
            <i className="material-icons Accounts__Icon">delete</i>
          </Button>
        </TableCol>
      </TableRow>
    )
  }

  handleInput = ({ target: { value, name } }) => {
    this.setState({ [name]: value })
  }

  handleAddBroker = ({ email, name }, actions) => {
    actions.setSubmitting(true)
    this.setState({ status: 'Cadastrando...' }, () => {
      const {
        user: { cityId, realStateId, userId },
      } = this.props
      const payload = {
        CityId: cityId,
        Email: email.toLowerCase(),
        ManagerId: userId,
        RealStateAgencyId: realStateId,
        Name: name,
      }

      registerBroker(payload)
        .then(response => {
          getManagedAccounts(userId).then(({ data }) => {
            const max = data.pessoas.length + data.numeroContas
            this.setState({
              addUser: false,
              limit: max != null ? max : 4,
              accountsAmount: data.numeroContas,
              users: data.pessoas.map(item => ({
                id: item.Id,
                name: item.Name,
                email: item.Email,
                updateDate: new Date(item.UpdatedAt),
                creationDate: new Date(item.CreatedAt),
                removed: item.Deleted,
                managerId: item.ManagerId,
              })),
            })
          })
        })
        .catch(err => {
          window.M.toast({
            classes: 'Toast--failure',
            html: err.response.statusText,
          })
          this.setState({ status: err.response.statusText })
        })
        .then(() => {
          this.setState({ status: '' })
          actions.setSubmitting(false)
        })
    })
  }

  handleRemoveBroker = () => {
    const {
      user: { userId },
    } = this.props

    let { users, removing } = this.state
    users[findIndex(users, user => user.id === removing.id)].status =
      'Removendo...'

    this.setState({ users }, () => {
      const { id } = removing
      removeBroker(id).then(response => {
        this.setState({ removing: undefined })
        getManagedAccounts(userId).then(({ data }) => {
          const max = data.pessoas.length + data.numeroContas
          this.setState({
            limit: max != null ? max : 4,
            accountsAmount: data.numeroContas,
            users: data.pessoas.map(item => ({
              id: item.Id,
              name: item.Name,
              email: item.Email,
              updateDate: new Date(item.UpdatedAt),
              creationDate: new Date(item.CreatedAt),
              removed: item.Deleted,
              managerId: item.ManagerId,
            })),
          })
        })
      })
    })
  }

  renderAddUserRow = () => {
    const { status } = this.state
    return (
      <Formik
        initialValues={{
          email: '',
          name: '',
        }}
        onSubmit={(values, actions) => this.handleAddBroker(values, actions)}
        validationSchema={AccountsSchema}
      >
        {({
          handleChange,
          values: { email, name },
          handleSubmit,
          errors,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <TableRow>
              <TableCol width="32.5%" title="Nome" label="Nome" textCenter>
                <Field
                  classes={{
                    inputWrapper: 'Accounts__InputWrapper',
                    inputComponent: 'Accounts__Input',
                    inputFeedback: 'FormField__Feedback',
                    inputError: 'FormField__Input--error',
                    label: 'FormField__Label',
                  }}
                  placeholder="Nome"
                  name="name"
                  type="text"
                  value={name}
                  error={errors.name}
                  onChange={handleChange}
                />
              </TableCol>
              <TableCol width="32.5%" title="Email" label="Email" textCenter>
                <Field
                  classes={{
                    inputWrapper: 'Accounts__InputWrapper',
                    inputComponent: 'Accounts__Input',
                    inputFeedback: 'FormField__Feedback',
                    inputError: 'FormField__Input--error',
                    label: 'FormField__Label',
                  }}
                  name="email"
                  placeholder="E-mail"
                  type="email"
                  value={email}
                  error={errors.email}
                  onChange={handleChange}
                />
              </TableCol>
              <TableCol width="27.5%" title="Status" label="Status" textCenter>
                {status}
              </TableCol>
              <TableCol
                title="Ações"
                action="1"
                width="7.5%"
                label="Ações"
                textCenter
              >
                <Button
                  title="Salvar"
                  disabled={
                    !!(
                      errors.email ||
                      errors.name ||
                      !name ||
                      !email ||
                      isSubmitting
                    )
                  }
                  type="submit"
                  btnStyle="flat"
                  btnSize="sm"
                  compact
                  block
                >
                  <i className="material-icons Accounts__Icon">save</i>
                </Button>
                <Button
                  title="Cancelar"
                  btnStyle="flat"
                  disabled={isSubmitting}
                  btnSize="sm"
                  compact
                  block
                  onClick={() => this.setState({ addUser: false })}
                >
                  <i className="material-icons Accounts__Icon">cancel</i>
                </Button>
              </TableCol>
            </TableRow>
          </Form>
        )}
      </Formik>
    )
  }

  renderAddButton = () => {
    const { users, limit } = this.state
    return users.length >= limit ? null : (
      <Col xs md={2} className="Accounts__AddButton">
        <Button
          title="Adicionar"
          btnStyle="flat"
          btnSize="md"
          style={{ width: '100%' }}
          onClick={() => this.setState({ addUser: true })}
        >
          <i className="material-icons Accounts__Icon">person_add</i> Adicionar
        </Button>
      </Col>
    )
  }

  renderCol = (title, label, width, center = true) => (
    <TableCol width={width} title={title} label={label} textCenter={center}>
      {title}
    </TableCol>
  )

  renderHeaderCol = (title, width) => (
    <TableCol header title={title} textCenter width={width}>
      <span>{title}</span>
    </TableCol>
  )

  render() {
    const { users, addUser, limit, removing } = this.state

    return (
      <Fragment>
        <Grid fluid>
          <Row>
            <Col xs>
              <Row>
                <Table>
                  <TableRow header>
                    {this.renderHeaderCol('Nome', '32.5%')}
                    {this.renderHeaderCol('Email', '32.5%')}
                    {this.renderHeaderCol('Status', '27.5%')}
                    {this.renderHeaderCol('Remover', '7.5%')}
                  </TableRow>
                  {users && users.map(this.renderRow)}
                  {addUser && this.renderAddUserRow()}
                </Table>
              </Row>
              <Row around="xs" className="Accounts__Footer">
                <Col xs md={10}>
                  <span>{`Corretores: ${users.length} / ${limit}`}</span>
                </Col>
                {this.renderAddButton()}
              </Row>
            </Col>
          </Row>
        </Grid>
        <ConfirmDialog
          text="Deseja realmente remover esse usuário"
          onCancel={() => this.setState({ removing: undefined })}
          onConfirm={this.handleRemoveBroker}
          visible={removing}
        />
      </Fragment>
    )
  }
}

export default Accounts
