import React from 'react'
import { Col, Row, Grid } from 'components/Grid'
import { Button } from 'components/Buttons'
import SearchClient from 'services/search'
import { callRequest } from 'services/requestHandler'
import { getPaymentStatus } from 'services/user'
import { downloadReport } from 'services/report'
import { formatToLocale } from 'utils/numberFormatter'
import { formatRealties } from 'utils/formatRealties'
import {
  getTransactionType as businessTypeEnum,
  translateTransactionType,
} from 'utils/getTransactionType'
import MapService from 'services/map'
import Map from 'google-map-react'
import I18n from 'i18n-js'
import Spinner from 'components/Spinner'
import logo from './logo.png'
import './index.css'

export default class ReportPage extends React.Component {
  constructor(props) {
    super(props)
    const { id, businessType } = props.match.params
    this.state = {
      id,
      businessType: businessTypeEnum(businessType),
      loading: false,
    }
    this.client = new SearchClient()
  }

  initializeMapService = google => {
    this.mapService = new MapService(google)
    this.initializeStreetView()
  }

  initializeStreetView = () => {
    const { realty } = this.state
    const fenway = {
      lat: Number(realty.location.coordinates[1]),
      lng: Number(realty.location.coordinates[0]),
    }
    this.mapService.initializeStreetView(
      fenway,
      'streetView',
      this.handleStreetViewChange,
    )
  }

  componentDidMount() {
    const { id } = this.state
    getPaymentStatus()
    callRequest(
      () => this.client.realtyById({ id }),
      ({ hits: { hits } }) => {
        if (hits) {
          const { businessType } = this.state
          formatRealties(hits, businessType, ({ data }) => {
            this.setState(
              state => ({ ...state, realty: data[0] }),
              () => {
                this.forceUpdate()
                const { realty } = this.state
                this.staticImage = `https://maps.googleapis.com/maps/api/staticmap?center=${
                  realty.location.coordinates[1]
                },${
                  realty.location.coordinates[0]
                }&zoom=16&scale=2&size=450x300&markers=color:red%7Clabel:%7C${
                  realty.location.coordinates[1]
                },${realty.location.coordinates[0]}&key=${
                  process.env.REACT_APP_GOOGLE_MAP_KEY
                }`

                realty.NeighborhoodId &&
                  callRequest(
                    () => this.client.neighborhoodById(realty.NeighborhoodId),
                    data => {
                      data &&
                        data.Id &&
                        this.setState({
                          neighborhood: {
                            id: data.Id,
                            cityId: data.CidadeId,
                            area: data.Area,
                            density:
                              data.Populacao && data.Area
                                ? formatToLocale(
                                    data.Populacao / data.Area,
                                    0,
                                    0,
                                  )
                                : data.Densidade
                                ? formatToLocale(data.Densidade, 0, 0)
                                : I18n.t('common.notInformed'),
                            residence: formatToLocale(data.Domicilio, 0, 0),
                            name: data.Nome,
                            population: formatToLocale(data.Populacao, 0, 0),
                            householdIncome: data.RendaDomiciliar
                              ? formatToLocale(data.RendaDomiciliar)
                              : I18n.t('common.notInformed'),
                            perCapitaIncome: data.RendaPerCapta
                              ? formatToLocale(data.RendaPerCapta)
                              : I18n.t('common.notInformed'),
                          },
                        })
                    },
                    null,
                    this.handleError,
                  )

                callRequest(
                  () => this.client.regionStatistics(realty),
                  result => {
                    const data = result.aggregations.neighborhood.buckets[0]
                    this.setState({
                      region: {
                        sample: formatToLocale(data.doc_count, 0, 0),
                        median: formatToLocale(
                          data.price.values['50.0'] /
                            data.totalArea.values['50.0'],
                          2,
                          2,
                        ),
                        medianValue: formatToLocale(
                          data.price.values['50.0'],
                          2,
                          2,
                        ),
                      },
                    })
                  },
                  null,
                  this.handleError,
                )

                realty.Zoning &&
                  realty.ZoningId &&
                  callRequest(
                    () => this.client.zoneById(realty.ZoningId),
                    data => {
                      const zone = data[0]
                      zone &&
                        this.setState({
                          zone: {
                            name: zone.Sigla || '-',
                            ca: zone.CA || 0,
                            to: parseInt(zone.TO * 100, 10) || 0,
                            maxHeight: zone.AlturaMaxima || 0,
                            minRecoil: zone.RecuoMin || 0,
                            permRate: parseInt(zone.TaxaPermeab * 100, 10) || 0,
                            minTested: zone.TestadaMin || 0,
                            minArea: zone.AreaMin || 0,
                          },
                        })
                    },
                    this.handleError,
                  )
              },
            )
          })
        }
      },
    )
  }

  handleStreetViewChange = (lat, lng, heading, pitch) => {
    this.streetImage = `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${lat},${lng}&fov=120&heading=${heading}&pitch${pitch}&key=${
      process.env.REACT_APP_GOOGLE_MAP_KEY
    }`
  }

  handleDownloadClick = () => {
    const { zone, realty, neighborhood, region, businessType } = this.state

    this.setState({ loading: true })
    setTimeout(() => {
      downloadReport({
        Imagestreetview: this.streetImage,
        Imagemap: this.staticImage,
        Densidade: neighborhood && neighborhood.density.replace(/\./g, ''),
        Zoneamento: zone && zone.name,
        Ca: zone && zone.ca,
        To: zone && zone.to,
        Alturamaxima: zone && zone.maxHeight,
        Recuomin: zone && zone.minRecoil,
        Taxapermeabilidade: zone && zone.permRate,
        Testadamin: zone && zone.minTested,
        Areamin: zone && zone.minArea,
        Bairro: realty.Neighborhood,
        Populacao: neighborhood && neighborhood.population.replace(/\./g, ''),
        Domicilio: neighborhood && neighborhood.residence.replace(/\./g, ''),
        Rendadomiciliar:
          neighborhood &&
          neighborhood.householdIncome.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        Rendapercapta:
          neighborhood &&
          neighborhood.perCapitaIncome.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        Area: neighborhood && neighborhood.area,
        Tipodonegocio: translateTransactionType(businessType),
        Tipodoimovel: realty.Category,
        Endereco: realty.Address,
        Cidade: realty.City,
        Valortotal: `R$ ${realty.Price.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
        Quarto: realty.Bedrooms,
        Garagem: realty.Garages,
        Areautil: realty.UsefulArea,
        Areatotal: realty.TotalArea,
        Quantidade: region && region.sample,
        Mediana: region && region.median,
        Valormeadian: region && region.medianValue,
        Latitutde: realty.location.coordinates[1],
        Longitude: realty.location.coordinates[0],
      })
        .then(({ request }) => {
          this.setState({ loading: false })
          request.onloadend = function(event) {
            if (request.status === 200) {
              let link = document.createElement('a')
              document.body.appendChild(link)
              link.download = 'Relatorio.pdf'
              link.href = window.URL.createObjectURL(request.response)
              link.click()
            }
          }
        })
        .catch(err => {
          let reader = new FileReader()
          reader.onload = function() {
            const parsedError = JSON.parse(reader.result)
            if (parsedError.Message && parsedError.Message.includes('Trial')) {
              window.M.toast({
                classes: 'Toast--failure',
                html: parsedError.Message,
              })
            }
          }
          reader.readAsText(err.response.data)
          this.setState({ loading: false })
        })
    }, 500)
  }

  handleError = err => {
    console.error(err)
  }

  renderRealtyFeatures = () => {
    const { realty } = this.state
    return (
      realty && (
        <Col md>
          <Row>
            <Col md={5} className="Report__InnerCol">
              <span className="Report__Content--bold">
                {I18n.t('report.realty.rooms')}
              </span>
            </Col>
            <Col md className="Report__InnerCol">
              {realty.Bedrooms}
            </Col>
          </Row>
          <Row>
            <Col md={5} className="Report__InnerCol">
              <span className="Report__Content--bold">
                {I18n.t('report.realty.garages')}
              </span>
            </Col>
            <Col md className="Report__InnerCol">
              {realty.Garages}
            </Col>
          </Row>
          <Row>
            <Col md={5} className="Report__InnerCol">
              <span className="Report__Content--bold">
                {I18n.t('report.realty.usefulArea')}
              </span>
            </Col>
            <Col md className="Report__InnerCol">
              {realty.UsefulArea} m²
            </Col>
          </Row>
          <Row>
            <Col md={5} className="Report__InnerCol">
              <span className="Report__Content--bold">
                {I18n.t('report.realty.totalArea')}
              </span>
            </Col>
            <Col md className="Report__InnerCol">
              {realty.TotalArea} m²
            </Col>
          </Row>
          <Row>
            <Col md={5} className="Report__InnerCol">
              <span className="Report__Content--bold">
                {I18n.t('report.realty.price')}
              </span>
            </Col>
            <Col
              md
              className="Report__InnerCol"
            >{`R$ ${realty.Price.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}</Col>
          </Row>
        </Col>
      )
    )
  }

  renderNeighborhoodFeatures = () => {
    const { neighborhood } = this.state
    return (
      neighborhood && (
        <Row className="Report__Row__Content">
          <Col md>
            <Row>
              <Col md={5} className="Report__InnerCol">
                <span className="Report__Content--bold">
                  {I18n.t('report.population')}
                </span>
              </Col>
              <Col
                md
                className="Report__InnerCol"
              >{`${neighborhood.population || 0} habitantes`}</Col>
            </Row>
            <Row>
              <Col md={5} className="Report__InnerCol">
                <span className="Report__Content--bold">
                  {I18n.t('report.residence')}
                </span>
              </Col>
              <Col md className="Report__InnerCol">{`${neighborhood.residence ||
                0} moradias`}</Col>
            </Row>
            <Row>
              <Col md={5} className="Report__InnerCol">
                <span className="Report__Content--bold">
                  {I18n.t('report.householdeIncome')}
                </span>
              </Col>
              <Col md className="Report__InnerCol">{`R$ ${
                neighborhood.householdIncome
              }`}</Col>
            </Row>
            <Row>
              <Col md={5} className="Report__InnerCol">
                <span className="Report__Content--bold">
                  {I18n.t('report.perCapitaIncome')}
                </span>
              </Col>
              <Col md className="Report__InnerCol">{`R$ ${
                neighborhood.perCapitaIncome
              }`}</Col>
            </Row>
            <Row>
              <Col md={5} className="Report__InnerCol">
                <span className="Report__Content--bold">
                  {I18n.t('report.area')}
                </span>
              </Col>
              <Col md className="Report__InnerCol">{`${
                neighborhood.area
              } km²`}</Col>
            </Row>
            <Row>
              <Col md={5} className="Report__InnerCol">
                <span className="Report__Content--bold">
                  {I18n.t('report.density')}
                </span>
              </Col>
              <Col md className="Report__InnerCol">{`${
                neighborhood.density
              } hab/km²`}</Col>
            </Row>
          </Col>
        </Row>
      )
    )
  }

  renderRegionFeatures = () => {
    const { region } = this.state
    return (
      region && (
        <Row className="Report__Row__Content">
          <Col md>
            <Row>
              <Col md={5} className="Report__InnerCol">
                <span className="Report__Content--bold">
                  {I18n.t('report.sample')}
                </span>
              </Col>
              <Col md className="Report__InnerCol">
                {region.sample}
              </Col>
            </Row>
            <Row>
              <Col md={5} className="Report__InnerCol">
                <span className="Report__Content--bold">
                  {I18n.t('report.medianSquareMeter')}
                </span>
              </Col>
              <Col md className="Report__InnerCol">{`R$ ${region.median}`}</Col>
            </Row>
            <Row>
              <Col md={5} className="Report__InnerCol">
                <span className="Report__Content--bold">
                  {I18n.t('report.medianPrice')}
                </span>
              </Col>
              <Col md className="Report__InnerCol">{`R$ ${
                region.medianValue
              }`}</Col>
            </Row>
          </Col>
        </Row>
      )
    )
  }

  renderZoneFeatures = () => {
    const { zone } = this.state
    return (
      zone && (
        <React.Fragment>
          <div className="Report__Content__Features__Title">Zoneamento</div>
          <div className="Report__GoldLine" />
          <Row className="Report__Row__Content">
            <Col md>
              <Row>
                <Col md={5} className="Report__InnerCol">
                  {I18n.t('report.initials')}
                </Col>
                <Col md className="Report__InnerCol">
                  {zone.name}
                </Col>
              </Row>
              <Row>
                <Col md={5} className="Report__InnerCol">
                  CA
                </Col>
                <Col md className="Report__InnerCol">
                  {zone.ca}
                </Col>
              </Row>
              <Row>
                <Col md={5} className="Report__InnerCol">
                  TO{' '}
                </Col>
                <Col md className="Report__InnerCol">
                  {zone.to}
                </Col>
              </Row>
              <Row>
                <Col md={5} className="Report__InnerCol">
                  {I18n.t('report.maxHeight')}
                </Col>
                <Col md className="Report__InnerCol">
                  {zone.maxHeight}
                </Col>
              </Row>
              <Row>
                <Col md={5} className="Report__InnerCol">
                  {I18n.t('report.minRecoil')}
                </Col>
                <Col md className="Report__InnerCol">
                  {zone.minRecoil}
                </Col>
              </Row>
              <Row>
                <Col md={5} className="Report__InnerCol">
                  {I18n.t('report.permeabilityRate')}
                </Col>
                <Col md className="Report__InnerCol">
                  {zone.permRate}
                </Col>
              </Row>
              <Row>
                <Col md={5} className="Report__InnerCol">
                  {I18n.t('report.minTestedGround')}
                </Col>
                <Col md className="Report__InnerCol">
                  {zone.TestadaMin}
                </Col>
              </Row>
              <Row>
                <Col md={5} className="Report__InnerCol">
                  {I18n.t('report.minArea')}
                </Col>
                <Col md className="Report__InnerCol">
                  {zone.AreaMin}
                </Col>
              </Row>
            </Col>
          </Row>
        </React.Fragment>
      )
    )
  }

  renderTitle = title => {
    return (
      <React.Fragment>
        <div className="Report__Content__Features__Title">{title}</div>
        <div className="Report__GoldLine" />
      </React.Fragment>
    )
  }

  render() {
    const { realty, region, neighborhood, loading, businessType } = this.state
    return realty ? (
      <Grid fluid>
        <Row around="md" className="Report">
          <Col md={10}>
            <Row className="Report__Header Report__Row">
              <Col className="Report__Header__Title" xs={12} md={6}>
                {I18n.t('report.realtyReport')}
              </Col>
              <Col xs={12} md={6}>
                <Row end="md">
                  <Col xs={12} className="Report__Header__Logo">
                    <img
                      className="Report__Header__Logo__Image"
                      src={logo}
                      alt="Logo"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row around="md">
              <Col md={12}>
                <Row
                  around="md"
                  className="Report__Content__Address Report__Row"
                >
                  <Col md={12}>
                    <b>{I18n.t('report.typology')}</b>
                    {`${realty.Category} - ${translateTransactionType(
                      businessType,
                    )}`}
                  </Col>
                  <Col md={12}>
                    <b>{I18n.t('report.address')}</b>
                    {realty.Address && `${realty.Address} - `}
                    {realty.Neighborhood && `${realty.Neighborhood} - `}
                    {realty.City && `${realty.City} - ${realty.Uf}`}
                  </Col>
                </Row>
                <Row around="md" className="Report__Content__Maps Report__Row">
                  <Col md={6} className="Report__Content__Maps__Static">
                    {realty && <img alt="Mapa" src={this.staticImage} />}
                  </Col>
                  <Col md={6} className="Report__Content__Maps__StreetView">
                    <div
                      id="streetView"
                      style={{ width: '100%', height: '100%' }}
                    />
                    <Map
                      bootstrapURLKeys={{
                        libraries: ['drawing', 'geometry'],
                        key: process.env.REACT_APP_GOOGLE_MAP_KEY,
                      }}
                      yesIWantToUseGoogleMapApiInternals
                      onGoogleApiLoaded={this.initializeMapService}
                      center={[0, 0]}
                      zoom={4}
                      style={{ display: 'none' }}
                    />
                  </Col>
                </Row>

                <Row
                  around="md"
                  className="Report__Content__Features Report__Row"
                >
                  <Col md={6} className="Report__Content__Features__Realty">
                    {this.renderTitle(I18n.t('report.realtyFeatures'))}
                    <Row className="Report__Row__Content">
                      {this.renderRealtyFeatures()}
                    </Row>
                  </Col>

                  <Col
                    md={6}
                    className="Report__Content__Features__Neighborhood"
                  >
                    {neighborhood &&
                      neighborhood.density !== I18n.t('common.notInformed') &&
                      this.renderTitle(I18n.t('alert.neighborhood'))}
                    {neighborhood &&
                      neighborhood.density !== I18n.t('common.notInformed') &&
                      this.renderNeighborhoodFeatures()}
                  </Col>

                  <Col md={6} className="Report__Content__Features__Region">
                    {region &&
                      region.median &&
                      this.renderTitle(I18n.t('report.regionMetrics'))}
                    {region && region.median && this.renderRegionFeatures()}
                  </Col>

                  <Col md={6} className="Report__Content__Features__Zone">
                    {this.renderZoneFeatures()}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Button
          btnStyle="primary"
          className="Report__Download"
          compact
          onClick={this.handleDownloadClick}
        >
          <i className="material-icons">cloud_download</i>
        </Button>
        <Spinner visible={loading} />
      </Grid>
    ) : (
      <div />
    )
  }
}
