import React, { Component } from 'react'
import I18n from 'locales'
import { Button } from 'components/Buttons'
import { getAllCategoriesAsArray } from 'utils/category'
import {
  deleteAlert,
  getAlerts,
  getAlertRealty,
  updateAlertStatus,
  alertPaginated,
} from 'services/alert'
import { callRequest } from 'services/requestHandler'
import { ShowRealty } from 'components/ShowRealty'
import ConfirmDialog from 'components/ConfirmDialog'
import Spinner from 'components/Spinner'
import { formatRealties } from 'utils/formatRealties'
import { formatAlerts } from 'utils/alertSchema'
import { RealtyProvider } from 'context/Realty'
import Map from 'google-map-react'
import MapService from 'services/map'
import { userId } from 'services/auth'
import { getPaymentStatus } from 'services/user'
import { getCityById } from 'services/location'
import { Table, Row, Col } from './styles'
import './index.css'

const COL_WIDTH = `${100 / 12}%`

class AlertTable extends Component {
  constructor() {
    super()
    this.state = {
      removing: false,
      toBeDeleted: '',
      alerts: [],
      alertsSnapshot: [],
      realties: [],
      hasRealties: false,
      loading: false,
      map: false,
      center: [-23.550421, -46.633468],
      zoom: 14,
      sortName: 'asc',
      sortBusinessType: 'asc',
      sortCategories: 'asc',
      sortMinPrice: 'asc',
      sortMaxPrice: 'asc',
      sortBedroom: 'asc',
      sortGarage: 'asc',
      sortAdDate: 'asc',
    }
  }

  componentDidMount() {
    if (userId() === 14801) window.location = I18n.t('routes.search')

    getPaymentStatus()
    getAlerts().then(({ data }) => this.setAlerts(data))
  }

  initializeMapService = google => {
    this.mapService = new MapService(google, this.callUpdate)
    this.mapService.disableMapMovement()
    this.callUpdate()
  }

  callUpdate = () => {
    this.forceUpdate()
  }

  setAlerts = alerts => {
    this.setState({
      alerts: formatAlerts(alerts),
      alertsSnapshot: formatAlerts(alerts),
    })
  }

  renderRealtyCategories = categories => {
    let categoriesString = ''

    categories.forEach((category, index) => {
      if (index === categories.length - 1) {
        categoriesString += this.getPrettyName(category)
      } else {
        categoriesString += `${this.getPrettyName(category)}, `
      }
    })

    return categoriesString
  }

  renderOptionals = optionals => {
    let optionalsString = ''

    optionals.forEach((optional, index) => {
      if (index === optionals.length - 1) {
        optionalsString += optional
      } else {
        optionalsString += `${optional}, `
      }
    })

    return optionalsString
  }

  getPrettyName = key => {
    for (let { value, prettyName } of getAllCategoriesAsArray()) {
      if (value === Number(key)) return prettyName
    }
  }

  openConfirmDialog = alertId => {
    this.setState({ removing: true, toBeDeleted: alertId })
  }

  closeConfirmDialog = () => {
    this.setState({ removing: false, toBeDeleted: '' })
  }

  getAlerts = () => {
    callRequest(() => getAlerts(), this.setAlerts)
  }

  handleRemoveAlert = () => {
    const { toBeDeleted } = this.state
    callRequest(
      () => deleteAlert(toBeDeleted),
      this.getAlerts,
      null,
      null,
      () => this.setState({ removing: false, toBeDelete: '' }),
    )
  }

  formatRealtiesCallback = ({ data }, paginated) =>
    this.setState({
      totalRealties: data.length,
      realties: data,
      hasRealties: true,
      loading: false,
    })

  handlePaginatedSearch = (options, callback = null) => {
    alertPaginated(options)
      .then(({ data: { hits } }) => {
        const transactionType = hits && hits.hits && hits.hits[0].businessType
        formatRealties(hits.hits, transactionType, this.formatRealtiesCallback)
      })
      .catch(err => {
        this.handleError(err)
      })
      .then(() => {
        callback && callback()
      })
  }

  formatRealtiesCallBackShowRealty = ({ data }) => {
    this.setState(
      {
        businessType: (data && data.length > 0 && data[0].BusinessType) || null,
        realties: (data && data.length > 0 && data) || null,
        hasRealties: data && data.length > 0,
        loading: false,
      },
      () => {
        const { hasRealties } = this.state
        if (!hasRealties) {
          window.M.toast({
            html: 'Sem novos imóveis.',
            classes: 'Toast--failure',
          })
        }
      },
    )
  }

  showRealty = (alertId, transactionType) => {
    this.setState({ loading: true })
    callRequest(
      () => getAlertRealty(alertId),
      data => {
        formatRealties(
          data,
          transactionType,
          this.formatRealtiesCallBackShowRealty,
        )
      },
    )
  }

  hideRealty = () => {
    this.setState({ realties: [], hasRealties: false })
  }

  sortBy = column => {
    let alerts = this.state.alerts
    this.setState(state => ({
      alerts: alerts.sort((a, b) => {
        if (column === 'AdDate') {
          const dateA = new Date(a[column])
          const dateB = new Date(a[column])
          if (dateA < dateB)
            return this.state[`sort${column}`] === 'asc' ? 1 : -1
          if (dateA > dateB)
            return this.state[`sort${column}`] === 'asc' ? -1 : 1
        }
        if (a[column] < b[column])
          return this.state[`sort${column}`] === 'asc' ? -1 : 1
        if (a[column] > b[column])
          return this.state[`sort${column}`] === 'asc' ? 1 : -1
        return 0
      }),
      [`sort${column}`]: this.state[`sort${column}`] === 'asc' ? 'desc' : 'asc',
    }))
  }

  searchRealty = value => {
    let alerts = this.state.alertsSnapshot
    if (value) {
      this.setState(state => ({
        ...state,
        alerts: alerts.filter(item =>
          item.Name.toLowerCase().includes(value.toLowerCase()),
        ),
      }))
    } else {
      this.setState(state => ({
        ...state,
        alerts: this.state.alertsSnapshot,
      }))
    }
  }

  updateAlert = (id, status) => {
    callRequest(
      () => updateAlertStatus(id, status),
      () =>
        window.M.toast({
          html: 'Alerta Atualizado com sucesso',
          classes: 'Toast--success',
        }),
      null,
      null,
      this.getAlerts,
    )
  }

  openMapPolygon = polygon => {
    this.setState({ map: true }, () => {
      setTimeout(() => {
        const fullPoints = []
        const points = polygon
        if (points.length > 0) {
          points.forEach(point => {
            const latLng = this.mapService.createLatLng({
              lat: Number(point[1]),
              lng: Number(point[0]),
            })

            fullPoints.push(latLng)
          })
        }

        const latLng = this.mapService.createGeometryFromPath(fullPoints)

        this.setState({ center: latLng, zoom: 14 })
      }, 2000)
    })
  }

  openMapMultiPolygon = (polygon, cityId) => {
    this.setState({ map: true }, () => {
      setTimeout(() => {
        const points = polygon
        if (points.length > 0) {
          points.forEach(point => {
            const fullPoints = []
            point.forEach(coordinates => {
              const latLng = this.mapService.createLatLng({
                lat: Number(coordinates[1]),
                lng: Number(coordinates[0]),
              })
              fullPoints.push(latLng)
            })
            this.mapService.createGeometryFromPath(fullPoints)
          })
        }
        getCityById(cityId).then(({ data }) =>
          this.setState({
            center: data.eemovelProperties.coordinates,
            zoom: data.eemovelProperties.zoom,
          }),
        )
      }, 2000)
    })
  }

  renderHeaderCol = (sort, title, sortable = true) => (
    <Col
      header
      title={title}
      textCenter
      width={COL_WIDTH}
      onClick={() => (sortable ? this.sortBy(sort) : null)}
      className="AlertTable__Sortable"
    >
      <span>{title}</span>
      {sortable && (
        <i className="material-icons">
          {`keyboard_arrow_${
            this.state[`sort${sort}`] === 'asc' ? 'down' : 'up'
          }`}
        </i>
      )}
    </Col>
  )

  renderCol = (title, label, center = true) => (
    <Col width={COL_WIDTH} title={title} label={label} textCenter={center}>
      {title}
    </Col>
  )

  renderRow = alert => {
    const adDate = new Date(alert.AdDate).toLocaleDateString('pt-BR')
    const name = alert.Name || I18n.t('common.notInformed')
    const businessType = alert.BusinessType || I18n.t('common.notInformed')
    const bedrooms = alert.Bedroom || I18n.t('common.notInformed')
    const garages = alert.Garage || I18n.t('common.notInformed')
    const active = alert.Active ? I18n.t('common.yes') : I18n.t('common.no')
    const privateBusiness = alert.PrivateBusiness
      ? I18n.t('common.yes')
      : I18n.t('common.no')
    const optionals = alert.Optionals
      ? alert.Optionals.length > 0
        ? this.renderOptionals(alert.Optionals)
        : I18n.t('common.notInformed')
      : I18n.t('common.notInformed')
    const minPrice = alert.MinPrice
      ? Number(alert.MinPrice).toLocaleString('pt-BR')
      : I18n.t('common.notInformed')
    const maxPrice = alert.MaxPrice
      ? Number(alert.MaxPrice).toLocaleString('pt-BR')
      : I18n.t('common.notInformed')
    const categories = alert.Categories
      ? this.renderRealtyCategories(alert.CategoriesIds)
      : I18n.t('common.notInformed')

    return (
      <Row key={alert.Id}>
        {this.renderCol(adDate, I18n.t('alert.createdAt'))}
        {this.renderCol(name, I18n.t('alert.name'))}
        {this.renderCol(
          businessType,
          I18n.t('components.filters.businessType'),
        )}
        {this.renderCol(categories, I18n.t('alert.realty'), false)}
        {this.renderCol(minPrice, I18n.t('alert.minPrice'))}
        {this.renderCol(maxPrice, I18n.t('alert.maxPrice'))}
        {this.renderCol(bedrooms, I18n.t('alert.rooms'))}
        {this.renderCol(garages, I18n.t('alert.garage'))}
        {this.renderCol(optionals, I18n.t('alert.optionals'))}
        {this.renderCol(active, I18n.t('alert.active'))}
        {this.renderCol(privateBusiness, I18n.t('alert.private'))}
        <Col
          action="1"
          width={COL_WIDTH}
          label={I18n.t('alert.actions')}
          textCenter
        >
          <Button
            title={alert.Active ? 'Desativar alerta' : 'Ativar Alerta'}
            btnStyle="flat"
            btnSize="sm"
            compact
            block
            onClick={() => this.updateAlert(alert.Id, !alert.Active)}
          >
            <i className="material-icons AlertTable__Icon">
              {alert.Active ? 'notifications_off' : 'notifications'}
            </i>
          </Button>
          <Button
            title={I18n.t('common.delete')}
            btnStyle="flat"
            btnSize="sm"
            compact
            block
            onClick={() => this.openConfirmDialog(alert.Id)}
          >
            <i className="material-icons AlertTable__Icon">delete</i>
          </Button>
          <Button
            title="Ver Imóveis"
            btnStyle="flat"
            btnSize="sm"
            disabled={!alert.Alerted}
            compact
            block
            onClick={() => this.showRealty(alert.Id, alert.BusinessType)}
          >
            <i className="material-icons AlertTable__Icon">visibility</i>
          </Button>
          <Button
            title="Ver Polígono"
            btnStyle="flat"
            btnSize="sm"
            disabled={!alert.PolygonPoint}
            compact
            block
            onClick={() =>
              alert.PolygonPoint.length > 0
                ? this.openMapPolygon(alert.PolygonPoint)
                : this.openMapMultiPolygon(
                    alert.MultiPolygonPoint,
                    alert.CityId,
                  )
            }
          >
            <i className="material-icons AlertTable__Icon">location_on</i>
          </Button>
        </Col>
      </Row>
    )
  }

  render() {
    const {
      removing,
      alerts,
      realties,
      hasRealties,
      loading,
      map,
      center,
      zoom,
      businessType,
    } = this.state
    return (
      <React.Fragment>
        <input
          placeholder={I18n.t('alert.findAlert')}
          className="AlertTable__SearchInput"
          onChange={({ target: { value } }) => this.searchRealty(value)}
        />
        <Table>
          <Row header>
            {this.renderHeaderCol('AdDate', I18n.t('alert.createdAt'))}
            {this.renderHeaderCol('Name', I18n.t('alert.name'))}
            {this.renderHeaderCol(
              'BusinessType',
              I18n.t('components.filters.businessType'),
            )}
            {this.renderHeaderCol('Categories', I18n.t('alert.realty'))}
            {this.renderHeaderCol('MinPrice', I18n.t('alert.minPrice'))}
            {this.renderHeaderCol('MaxPrice', I18n.t('alert.maxPrice'))}
            {this.renderHeaderCol('Bedroom', I18n.t('alert.rooms'))}
            {this.renderHeaderCol('Garage', I18n.t('alert.garage'))}
            {this.renderHeaderCol(
              'Optionals',
              I18n.t('alert.optionals'),
              false,
            )}
            {this.renderHeaderCol('Acitve', I18n.t('alert.active'), false)}
            {this.renderHeaderCol(
              'PrivateBusiness',
              I18n.t('alert.private'),
              false,
            )}
            {this.renderHeaderCol('Action', I18n.t('alert.actions'), false)}
          </Row>
          {alerts && alerts.map(this.renderRow)}
        </Table>
        {removing && (
          <ConfirmDialog
            visible={removing}
            text={I18n.t('alert.confirmDelete')}
            onConfirm={this.handleRemoveAlert}
            onCancel={this.closeConfirmDialog}
          />
        )}
        {map && (
          <div className="AlertTable__Pane">
            <div className="AlertTable__Modal">
              <div className="AlertTable__ModalHeader">
                <i
                  className="material-icons"
                  onClick={() => this.setState({ map: false })}
                >
                  close
                </i>
              </div>
              <div className="AlertTable__MapContainer">
                <Map
                  bootstrapURLKeys={{
                    libraries: ['drawing', 'geometry'],
                    key: process.env.REACT_APP_GOOGLE_MAP_KEY,
                  }}
                  rotateControl={false}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={this.initializeMapService}
                  center={center}
                  zoom={zoom}
                  options={{
                    resetBoundsOnResize: true,
                    gestureHandling: 'greedy',
                    draggable: false,
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {hasRealties && (
          <RealtyProvider
            value={{
              alert: true,
              visible: hasRealties,
              toggle: this.hideRealty,
              businessType,
              realties,
            }}
          >
            <ShowRealty businessType={businessType} />
          </RealtyProvider>
        )}
        <Spinner visible={loading} />
      </React.Fragment>
    )
  }
}

export default AlertTable
