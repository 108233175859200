export const MAP = {
  defaultBounds: [-180, -85, 180, 85],
  defaultZoom: 13,
  defaultCenter: [-24.955278, -53.464267],
  options: {
    maxZoom: 20,
  },
}

export const MAP_MOBILE = {
  defaultBounds: [-180, -85, 180, 85],
  defaultZoom: 13,
  defaultCenter: [-24.955278, -53.464267],
  options: {
    maxZoom: 18,
  },
}
