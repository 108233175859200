import axios from 'axios'
import { headers } from 'services/auth'

const API_URL = process.env.REACT_APP_API_URL
const GET_UF_WITH_CITIES = `${API_URL}/localizacao/estadoscomcidades`
const GET_ADDRESS = cep =>
  `https://viacep.com.br/ws/${cep.replace(/[^\d]+/g, '')}/json`
const GET_CITIES = `${API_URL}/localizacao/listcities`
const GET_CITY_BY_ID = `${API_URL}/localizacao/citybyid?id=`
const GET_GEOCODED_ADDRESS = (address, uf) =>
  `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&components=country:BR|administrative_area:${uf}&location=pt-BR&key=${
    process.env.REACT_APP_GOOGLE_MAP_KEY
  }`

export const getUfWithCities = () => axios.get(GET_UF_WITH_CITIES, { headers })
export const getAddress = cep => axios.get(GET_ADDRESS(cep))
export const getCities = () => axios.get(GET_CITIES, { headers })
export const getCityById = id =>
  axios.get(`${GET_CITY_BY_ID}${id}`, { headers })

export const getGeocodeAddress = async (address, { name = '', state = '' }) => {
  let results = []
  let url = ''
  let cep = false

  if (address && !isNaN(address.replace(/-/g, ''))) {
    cep = true
  } else {
    address = `${address.replace(/[0-9]/g, '')}, ${name}`
    url = GET_GEOCODED_ADDRESS(address, state)
  }

  try {
    if (cep) {
      const response = await getAddress(address)

      if (response.status === 200 && response.data && !response.data.erro) {
        const { data } = response
        results = [
          {
            name: `${data.logradouro}, ${data.bairro}, ${data.localidade} - ${
              data.uf
            }`,
            ...response.data,
          },
        ]
      }
    } else {
      const response = await axios.get(url)

      results = response.data.results.map(result => ({
        name: result.formatted_address,
        ...result,
      }))
    }
  } catch (err) {
    console.error(err)
  }

  return results
}
