import React, { Component, Fragment } from 'react'
import Header from 'components/Header'
import MobileHeader from 'components/MobileHeader'
import Footer from 'components/Footer'
import { Row, Col } from 'components/Grid'
import { Button } from 'components/Buttons'
import Map from 'google-map-react'
import Marker from 'components/Marker'
import { getCities } from 'services/location'
import { getPaymentStatus } from 'services/user'
import I18n from 'locales'
import { deburr } from 'lodash'
import './index.css'

class WhereWeAct extends Component {
  constructor() {
    super()
    this.state = {
      mapLat: -15.7801,
      mapLng: -48.9292,
      mapZoom: window.innerWidth < 768 ? 3 : 4,
      cities: [],
      citiesSnapshot: [],
    }
  }

  componentDidMount() {
    getPaymentStatus()
    getCities().then(({ data }) => {
      this.setState({ cities: data, citiesSnapshot: data })
    })
  }

  setMap = (coordinates, zoom) => {
    this.setState({
      mapLat: coordinates[0],
      mapLng: coordinates[1],
      mapZoom: zoom,
    })
  }

  searchCity = value => {
    let { cities, citiesSnapshot } = this.state

    cities = cities.filter(({ name }) =>
      deburr(name.toLowerCase()).includes(deburr(value.toLowerCase())),
    )

    this.setState({ cities: value ? cities : citiesSnapshot })
    this.forceUpdate()
  }

  renderMarkers = () => {
    return this.state.cities.map(
      ({ name, state, eemovelProperties: { zoom, coordinates } }, index) => {
        return (
          <Marker
            title={`${name} - ${state}`}
            eepin
            onClick={() => this.setMap(coordinates, zoom)}
            key={index}
            lat={coordinates[0]}
            lng={coordinates[1]}
          />
        )
      },
    )
  }

  render() {
    const { mapLat, mapLng, mapZoom, cities } = this.state
    return (
      <Fragment>
        <div className="WhereWeAct__HeaderDesktop">
          <Header />
        </div>
        <div className="WhereWeAct__HeaderMobile">
          <MobileHeader />
        </div>
        <div className="WhereWeAct__Map">
          <Row>
            <Col md={6} xs={12}>
              <div className="WhereWeAct__MapContainer">
                <Map
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAP_KEY,
                  }}
                  rotateControl={false}
                  yesIWantToUseGoogleMapApiInternals
                  center={[mapLat, mapLng]}
                  zoom={mapZoom}
                  options={{
                    resetBoundsOnResize: true,
                    gestureHandling: 'greedy',
                  }}
                >
                  {cities.length > 0 && this.renderMarkers()}
                </Map>
              </div>
            </Col>
            <Col md={6} xs={12}>
              <Row center="xs">
                <Col xs={12}>
                  <h1 className="WhereWeAct__Header">
                    {I18n.t('components.navBar.whereWeAct')}
                  </h1>
                </Col>
              </Row>
              <Row center="xs">
                <Col xs={12}>
                  <input
                    className="WhereWeAct__Input"
                    name="search"
                    autoComplete="off"
                    placeholder={I18n.t('whereWeAct.seIf')}
                    onChange={({ target: { value } }) => {
                      this.searchCity(value)
                    }}
                  />
                </Col>
              </Row>
              <div className="WhereWeAct__Cities">
                {cities.length > 0 &&
                  cities.map(
                    ({
                      name,
                      state,
                      eemovelProperties: { zoom, coordinates },
                    }) => (
                      <Row center="xs" key={`${name} - ${state}`}>
                        <Col xs={12}>
                          <Button
                            btnStyle="link"
                            onClick={() => this.setMap(coordinates, zoom)}
                          >
                            {name} - {state}
                          </Button>
                        </Col>
                      </Row>
                    ),
                  )}
              </div>
            </Col>
          </Row>
        </div>
        <Footer />
      </Fragment>
    )
  }
}

export default WhereWeAct
