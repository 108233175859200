import React from 'react'
import { debounce } from 'lodash'
import './index.css'

const types = {
  warning: `ToastComponent--warning`,
  success: `ToastComponent--success`,
  danger: `ToastComponent--danger`,
}

class ToastComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      visible: false,
      showToastComponent: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.visible) {
      this.setState(
        { showToastComponent: nextProps.visible },
        debounce(
          () =>
            setTimeout(
              () => this.setState({ showToastComponent: false }),
              3000,
            ),
          1000,
        ),
      )
    }
  }

  dismiss = () => {
    this.setState({ showToastComponent: false })
  }

  render() {
    const { type = 'success', children, mobile = false } = this.props
    const { showToastComponent } = this.state
    const status = showToastComponent
      ? 'ToastComponent--open'
      : 'ToastComponent--close'
    const mobileClass = mobile
      ? 'ToastComponent--mobile'
      : 'ToastComponent--desktop'
    return (
      <div className={`ToastComponent ${types[type]} ${status} ${mobileClass}`}>
        <i
          className="material-icons ToastComponent__CloseBtn"
          onClick={this.dismiss}
        >
          close
        </i>
        <div className="ToastComponent__ChildrenContainer">{children}</div>
      </div>
    )
  }
}

export default ToastComponent
