import React from 'react'
import classNames from 'classnames'
import { Grid, Row } from 'components/Grid'
import { Button } from 'components/Buttons'
import I18n from 'locales'
import Badge from 'components/Badge'
import { kFormatter } from 'utils/numberFormatter'
import { SearchConsumer } from 'context/Search'
import FilterNeighborhood from 'components/MobileSearch/FilterNeighborhood'
import FilterCategory from '../FilterCategory'
import FilterPriceRange from '../FilterPriceRange'
import FilterBusinessType from '../FilterBusinessType'
import FilterMore from '../FilterMore'
import './index.css'

class FilterBar extends React.Component {
  constructor() {
    super()
    this.filters = {
      category: false,
      priceRange: false,
      businessType: false,
      more: false,
      neighborhood: false,
    }
    this.state = {
      ...this.filters,
      isOpen: false,
    }
  }

  getCount = filter => {
    const { filters } = this.context
    let count = filter === 'more' ? filters.more.optionals.length : 0
    if (filters[filter]) {
      for (let key of Object.keys(filters[filter])) {
        if (
          filters[filter][key] &&
          !key.includes('Filter') &&
          key !== 'optionals'
        ) {
          count++
        }
      }
    }
    return count
  }

  toggleFilterContainer = (filter = null) => {
    if (filter) {
      this.setState(state => ({
        ...this.filters,
        [filter]: true,
        isOpen: !state.isOpen,
      }))
    } else {
      this.setState(state => ({ ...state, isOpen: !state.isOpen }))
    }
    this.context.setNotSearching()
  }

  onApply = values => {
    const { onSubmit } = this.context
    this.toggleFilterContainer()
    onSubmit(values)
  }

  clearFilters = () => {
    const { clearFilters } = this.context
    this.toggleFilterContainer()
    clearFilters()
  }

  renderCount = filter => {
    let count = this.getCount(filter)
    if (count) {
      return (
        <Badge
          anchor="inline"
          size={20}
          shadow={false}
          className="VisualizeButton__Badge"
        >
          {count}
        </Badge>
      )
    }
  }

  render() {
    const { className } = this.props
    const classes = classNames('FilterBarMobile', className)
    const { isOpen } = this.state
    const filterContainerStatus = isOpen ? '__Open' : '__Close'
    const filterContainerHeaderClasses = `FilterBarMobile__FilterContainerHeader FilterBarMobile__FilterContainerHeader${filterContainerStatus}`
    const filterContainerClasses = `FilterBarMobile__FilterContainer FilterBarMobile__FilterContainer${filterContainerStatus}`

    return (
      <SearchConsumer>
        {({
          filters: {
            businessType,
            category,
            more,
            priceRange,
            privateBusiness,
          },
          currentCity,
          query,
          visualization,
          setPrevSearch,
          error,
          optionalsFilter,
          openDwellers,
          setSearching,
          loadedNeighborhoods,
          mapService,
          clearClusters,
        }) => (
          <div>
            <Grid fluid className={classes}>
              <Row between="xs">
                <div className="FilterBarMobile__Container__Filters">
                  <Button
                    btnStyle="link"
                    className={
                      error.businessType
                        ? 'FilterButton__Btn FilterBarMobile__Error'
                        : 'FilterButton__Btn'
                    }
                    onClick={() => this.toggleFilterContainer('businessType')}
                  >
                    {privateBusiness
                      ? `${businessType} Part.`
                      : businessType ||
                        I18n.t('components.filters.businessType')}
                  </Button>
                  <Button
                    btnStyle="link"
                    className={
                      error.category
                        ? 'FilterButton__Btn FilterBarMobile__Error'
                        : 'FilterButton__Btn'
                    }
                    onClick={() => this.toggleFilterContainer('category')}
                  >
                    {I18n.t('components.filters.category')}
                    {this.renderCount('category')}
                  </Button>
                  <Button
                    btnStyle="link"
                    className="FilterButton__Btn"
                    onClick={() => this.toggleFilterContainer('priceRange')}
                  >
                    {Object.keys(priceRange).length && priceRange.maxPrice
                      ? `${kFormatter(priceRange.minPrice)}-${kFormatter(
                          priceRange.maxPrice,
                        )}`
                      : I18n.t('components.filters.price')}
                  </Button>
                  <Button
                    btnStyle="link"
                    className="FilterButton__Btn"
                    onClick={() => this.toggleFilterContainer('more')}
                  >
                    {I18n.t('components.filters.more')}
                    {this.renderCount('more')}
                  </Button>
                  <Button
                    btnStyle="link"
                    className="FilterButton__Btn"
                    onClick={() => this.toggleFilterContainer('neighborhood')}
                  >
                    Bairros
                  </Button>
                  <Button
                    btnStyle="link"
                    className="FilterButton__Btn"
                    onClick={openDwellers}
                  >
                    {currentCity &&
                    currentCity.eemovelProperties &&
                    currentCity.eemovelProperties.owners
                      ? I18n.t('components.filters.owners')
                      : I18n.t('components.filters.dwellers')}
                  </Button>
                </div>
              </Row>
            </Grid>
            <div className={filterContainerHeaderClasses}>
              <div className="FilterBarMobile__FilterContainerHeader__ActionContainer">
                <div className="FilterBarMobile__FilterContainerHeader__CloseIcon">
                  <Button
                    btnStyle="link"
                    className="FilterButton__Btn"
                    onClick={this.toggleFilterContainer}
                  >
                    <i className="material-icons FilterBarMobile__BackArrow">
                      arrow_back_ios
                    </i>
                    {I18n.t('components.filters.map')}
                  </Button>
                </div>
                <div className="FilterBarMobile__FilterContainerHeader__ClearFilter">
                  <Button
                    btnStyle="link"
                    style={{ display: isOpen ? 'block' : 'none' }}
                    className="FilterButton__Btn"
                    onClick={this.clearFilters}
                  >
                    {I18n.t('components.actions.clearFilters')}
                  </Button>
                </div>
              </div>
            </div>
            <div className={filterContainerClasses}>
              {this.state.category && (
                <FilterCategory
                  initialValues={category}
                  onSubmit={this.onApply}
                  onCancel={this.toggleFilterContainer}
                />
              )}
              {this.state.priceRange && (
                <FilterPriceRange
                  initialValues={priceRange}
                  onSubmit={this.onApply}
                  onCancel={this.toggleFilterContainer}
                  setSearching={setSearching}
                />
              )}
              {this.state.businessType && (
                <FilterBusinessType
                  initialValues={{ businessType, privateBusiness }}
                  onSubmit={this.onApply}
                  onCancel={this.toggleFilterContainer}
                />
              )}
              {this.state.more && (
                <FilterMore
                  initialValues={more}
                  optionalsFilter={optionalsFilter}
                  onSubmit={this.onApply}
                  onCancel={this.toggleFilterContainer}
                  setSearching={setSearching}
                />
              )}
              {this.state.neighborhood && (
                <FilterNeighborhood
                  loadedNeighborhoods={loadedNeighborhoods}
                  mapService={mapService}
                  clearClusters={clearClusters}
                  setSearching={setSearching}
                  onSearch={() =>
                    setPrevSearch(
                      JSON.stringify({
                        query,
                        businessType,
                        category,
                        more,
                        priceRange,
                        privateBusiness,
                        visualization,
                      }),
                    )
                  }
                />
              )}
            </div>
          </div>
        )}
      </SearchConsumer>
    )
  }
}
FilterBar.contextType = SearchConsumer
export default FilterBar
