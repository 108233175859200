import axios from 'axios'
import { headers } from 'services/auth'
import { getTransactionType } from 'utils/getTransactionType'

const API_URL = process.env.REACT_APP_API_URL
const REPORT_DOWNLOAD = `${API_URL}/upload/downloadpdf_relatorioimovel`
const EVALUATION_REPORT_DOWNLOAD = `${API_URL}/upload/downloadpdf_relatorioavaliacao`
const REALTY_REPORT_DOWNLOAD = `${API_URL}/imoveis/relatorio_imoveis?format=`
const REPORT = `${API_URL}/upload/evaluation/`
const REPORT_GET = `${API_URL}/avaliacao/evaluation/`

export const downloadReport = data =>
  axios.post(REPORT_DOWNLOAD, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    responseType: 'blob',
  })

export const downloadEvaluationReport = data =>
  axios.post(EVALUATION_REPORT_DOWNLOAD, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    responseType: 'blob',
  })

export const downloadRealtyReport = ({ format, ids, businessType }) =>
  axios.post(
    `${REALTY_REPORT_DOWNLOAD}${format}&transactionType=${getTransactionType(
      businessType,
    )}`,
    ids,
    {
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    },
  )

export const doReport = (data, get = false) =>
  get
    ? axios.get(`${REPORT_GET}/${data.id}`, { headers })
    : axios.post(REPORT, data, { headers })
