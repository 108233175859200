import axios from 'axios'
import * as auth from 'services/auth'

const API_URL = process.env.REACT_APP_API_URL
const DWELLERS_BY_LOCATION = '/moradores/buscamoradores'
const DWELLERS_BY_DOCUMENT = '/moradores/buscamorador'
const CEP_BY_ADDRESS = (uf, city, street) =>
  `https://viacep.com.br/ws/${uf}/${city}/${street}/json`
const USE_AMOUNT = '/moradores/verificaqtdrequisicoes'
const OWNERS_MATCH_DOCUMENT = '/moradores/buscaproprietarios'
const LIST_OWNERS = ({ street, numbers, cep, cityId }) =>
  `/moradores/listowners?street=${street}&initialNumber=${
    numbers[0]
  }&finalNumber=${numbers[1]}&postalCode=${cep || ''}&cityId=${cityId}`

export default class Dwellers {
  constructor({ host = API_URL, cacheResponses = true } = {}) {
    this.host = host
    this.headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'x-pessoaid': auth.userId() || 48,
      ...auth.headers,
    }
  }

  searchByLocation(options = {}) {
    const payload = {
      Rua: options.street,
      Cep: options.cep ? options.cep.replace('-', '') : '',
      cidade: options.city,
      uf: options.uf,
      numeroInicial: Number(options.numbers[0]),
      numeroFinal: Number(options.numbers[1]),
    }

    return axios.post(this.host + DWELLERS_BY_LOCATION, payload, {
      headers: this.headers,
    })
  }

  searchByDocument(doc) {
    const payload = {
      Documento: btoa(doc),
    }

    return axios.post(this.host + DWELLERS_BY_DOCUMENT, payload, {
      headers: this.headers,
    })
  }

  searchCepByAddress(query = {}) {
    const { address, uf, city } = query

    return axios.get(CEP_BY_ADDRESS(uf, city, address))
  }

  async checkUsesAmount() {
    return await axios.get(this.host + USE_AMOUNT, {
      headers: this.headers,
    })
  }

  searchOwners(options) {
    return axios.get(this.host + LIST_OWNERS(options), {
      headers: this.headers,
    })
  }

  matchDocumentOwners(options) {
    const payload = {
      nome: options.Name,
      uf: options.addresses[0].uf,
      cidade: options.addresses[0].city,
      documento: options.document,
      tipodocumento: options.Type.includes('sica')
        ? 'CPF'
        : options.Type.includes('ídica')
        ? 'CNPJ'
        : null,
      rua: options.addresses[0].street,
      numero: String(options.addresses[0].number),
    }

    return axios.post(this.host + OWNERS_MATCH_DOCUMENT, payload, {
      headers: this.headers,
    })
  }
}
