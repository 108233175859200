export const formatDweller = dweller => {
  const { enderecos } = dweller
  const mainAddress = enderecos[0] || null
  const address = mainAddress
    ? [
        mainAddress.logradouro,
        mainAddress.numero,
        mainAddress.bairro,
        mainAddress.cidade,
        mainAddress.complemento,
      ].join(', ')
    : null

  const type = dweller.tipoPessoa
    ? dweller.tipoPessoa.replace(/pessoa|Pessoa/g, '').trim()
    : null

  const formatedDweller = {
    addresses:
      dweller.enderecos.length > 0
        ? dweller.enderecos.map(item => ({
            street: item.logradouro,
            number: item.numero,
            cep: item.cep,
            city: item.cidade,
            uf: item.uf,
            neighborhood: item.bairro,
            complement: item.complemento,
          }))
        : null,
    telephones:
      dweller.telefones.length > 0
        ? dweller.telefones.map(item => ({
            number: item.telefone,
            type: item.tipo,
            relation: item.relacao,
            name: item.nome,
          }))
        : null,
    emails:
      dweller.emails.length > 0 && dweller.emails[0]
        ? dweller.emails.map(item => ({ email: item.toLowerCase().trim() }))
        : null,
    personInfo: dweller.dadosPf
      ? {
          cbo: dweller.dadosPf.cbo,
          socialClass: dweller.dadosPf.classeSocial,
          birthDate: dweller.dadosPf.dataNascimento,
          entrepreneur: (dweller.dadosPf.empresario && 'Sim') || 'Não',
          scholarship: dweller.dadosPf.grauInstrucao,
          age: dweller.dadosPf.idade,
          name: dweller.dadosPf.nome,
          mothersName: dweller.dadosPf.nomeMae,
          death: dweller.dadosPf.obito,
          income: dweller.dadosPf.renda,
        }
      : null,
    companyInfo: dweller.dadosPj
      ? {
          cnae: dweller.dadosPj.cnae,
          cnaeDescription: dweller.dadosPj.cnaeDescricao,
          openingDate: dweller.dadosPj.dataAbertura,
          income: dweller.dadosPj.faturamento,
          legalNature: dweller.dadosPj.naturezaJuridica,
          fantasyName: dweller.dadosPj.nomeFantasia,
          companySize: dweller.dadosPj.porte,
          workers: dweller.dadosPj.qtdeFuncionarios,
          companyName: dweller.dadosPj.razaoSocial,
        }
      : null,
    document: dweller.documento,
    companyData:
      dweller.dadosEmpresariais && dweller.dadosEmpresariais.length > 0
        ? dweller.dadosEmpresariais.map(item => ({
            ocupation: item.cargoSocio,
            joinDate: item.dataEntrada,
            document: item.documento,
            name: item.nome,
            participation: item.participacao,
          }))
        : null,
    address: address ? address.substring(0, address.length - 2) : null,
    main_uf: mainAddress ? mainAddress.uf : null,
    city: mainAddress ? mainAddress.cidade : null,
    neighborhood: mainAddress ? mainAddress.bairro : null,
    infoStatus: dweller.statusInformacao,
    type,
  }

  return formatedDweller
}

export const formatOwner = dweller => {
  const Type =
    (dweller.tipo_documento &&
      (dweller.tipo_documento.includes('PJ')
        ? 'Pessoa Jurídica'
        : 'Pessoa Física')) ||
    ''

  return {
    addresses: [
      {
        raw: dweller.endereco,
        street: dweller.logradouro,
        number: dweller.numero,
        city: dweller.cidade.Nome,
        uf: dweller.cidade.UF,
        complement: dweller.complemento,
      },
    ],
    Complement: dweller.complemento,
    Name: dweller.proprietario,
    document: dweller.numero_documento,
    FullAddress: `${dweller.logradouro}, ${dweller.numero}`,
    Type,
  }
}
