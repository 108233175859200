import React, { Component } from 'react'
import classNames from 'classnames'
import I18n from 'locales'
import { Field, Checkbox } from 'components/Form'
import { Formik } from 'formik'
import { Col, Row } from 'components/Grid'
import { Button } from 'components/Buttons'
import { verifyBeforeSubmit } from 'components/Search/FilterMore/verifierHelper'
import ArrowSvgIcon from 'components/SvgIcons/ArrowSvgIcon'
import './index.css'

class FilterMoreMobile extends Component {
  constructor(props) {
    super()
    const {
      initialValues: { building, realState, reference, optionals },
    } = props
    this.state = {
      optionalsFilter: [],
      isMoreDetailsOpen: building || realState || reference,
      isOptionalsOpen: optionals.length > 0,
    }
  }

  componentDidMount() {
    const { optionalsFilter } = this.props
    this.setOptionals(optionalsFilter)
  }

  setOptionals = optionals => {
    this.setState({ optionalsFilter: [...optionals] })
  }

  searchOptionals = ({ target: { value } }) => {
    if (value) {
      this.setState({
        optionalsFilter: this.props.optionalsFilter.filter(({ Opcional }) =>
          Opcional.toLowerCase().includes(value),
        ),
      })
    } else {
      this.setState({
        optionalsFilter: this.props.optionalsFilter,
      })
    }
  }

  selectItem = (name, { setFieldValue }, values, value) => {
    const arrayValues = values[name].split(',')
    const index = arrayValues.indexOf(value)

    if (index === -1) arrayValues.push(value)
    else arrayValues.splice(index, 1)

    setFieldValue(
      name,
      arrayValues
        .filter(value => value !== '')
        .sort()
        .toString(),
    )
  }

  renderMicroOption = (name, label, formik, values, noZero = false) => {
    const baseClass = 'FilterMore__OptionButton'
    const zeroClasses = classNames(baseClass, {
      'FilterMore__OptionButton--active': values[name].includes('0'),
    })
    const oneClasses = classNames(baseClass, {
      'FilterMore__OptionButton--active': values[name].includes('1'),
    })
    const twoClasses = classNames(baseClass, {
      'FilterMore__OptionButton--active': values[name].includes('2'),
    })
    const threeClasses = classNames(baseClass, {
      'FilterMore__OptionButton--active': values[name].includes('3'),
    })
    const fourClasses = classNames(baseClass, {
      'FilterMore__OptionButton--active': values[name].includes('4'),
    })
    return (
      <React.Fragment>
        <Row className="FilterMore__Row">
          <Col xs={12} className="FilterMore__MicroOption__Label">
            <span className="FilterMore__MicroOption">{label}</span>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="FilterMore__ButtonContainer">
            {!noZero && (
              <div
                className={zeroClasses}
                onClick={() => this.selectItem(name, formik, values, '0')}
              >
                0
              </div>
            )}
            <div
              className={oneClasses}
              onClick={() => this.selectItem(name, formik, values, '1')}
            >
              1
            </div>
            <div
              className={twoClasses}
              onClick={() => this.selectItem(name, formik, values, '2')}
            >
              2
            </div>
            <div
              className={threeClasses}
              onClick={() => this.selectItem(name, formik, values, '3')}
            >
              3
            </div>
            <div
              className={fourClasses}
              onClick={() => this.selectItem(name, formik, values, '4')}
            >
              4+
            </div>
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  renderMacroOption = label => (
    <Row className="FilterMoreMobile__Row">
      <span className="FilterMoreMobile__MacroOption">{label}</span>
    </Row>
  )

  renderField = (label, placeholder, name, values, formik) => (
    <Row className="FilterMoreMobile__Row">
      <Col xs className="FilterMoreMobile__NoPadFieldColumn">
        <Field
          label={label}
          placeholder={placeholder}
          name={name}
          type="tel"
          pattern="[0-9]*"
          value={values[name]}
          onFocus={this.props.setSearching}
          onChange={({ target: { name, value } }) =>
            formik.setFieldValue(name, Number(value))
          }
        />
      </Col>
    </Row>
  )

  renderAreaField = (names, values, props) => (
    <div>
      <Row between="xs" className="FilterMoreMobile__Row">
        <Col xs={6} className="FilterMoreMobile__CustomPadColumn">
          <Field
            label={I18n.t('common.from')}
            type="tel"
            pattern="[0-9]*"
            name={names[0]}
            value={values[names[0]] === 0 ? '' : values[names[0]]}
            onFocus={this.props.setSearching}
          />
        </Col>
        <Col xs={6} className="FilterMoreMobile__CustomPadColumn">
          <Field
            label={I18n.t('common.to')}
            type="tel"
            pattern="[0-9]*"
            name={names[1]}
            value={values[names[1]] === 0 ? '' : values[names[1]]}
            onFocus={this.props.setSearching}
          />
        </Col>
      </Row>
    </div>
  )

  render() {
    const {
      className,
      onSubmit,
      onCancel,
      initialValues = {},
      optionalsFilter,
    } = this.props
    const classes = classNames('SearchFilter', 'FilterMoreMobile', className)
    const { isOptionalsOpen } = this.state
    return (
      <div className={classes}>
        <Formik
          onSubmit={values => verifyBeforeSubmit(values, onSubmit)}
          initialValues={initialValues}
        >
          {({ values, ...props }) => (
            <form
              onSubmit={props.handleSubmit}
              className="FilterMoreMobile__Form"
            >
              <Row between="xs" className="FilterMoreMobile__Row">
                <span className="FilterMoreMobile__Instructions">
                  {I18n.t('components.filters.moreInstructions')}
                </span>
              </Row>
              <div className="FilterMoreMobile__OptionContainer">
                <Row start="xs">
                  <Col xs={12}>
                    <Checkbox
                      name="fullAddress"
                      label={I18n.t('components.filters.fullAddress')}
                      checked={values.fullAddress}
                      onChange={props.handleChange}
                    />
                  </Col>
                </Row>
                {this.renderMacroOption(I18n.t('components.filters.rooms'))}
                {this.renderMicroOption(
                  'rooms',
                  I18n.t('components.filters.room'),
                  props,
                  values,
                  true,
                )}
                {this.renderMicroOption(
                  'suits',
                  I18n.t('components.filters.suits'),
                  props,
                  values,
                )}
                {this.renderMicroOption(
                  'garages',
                  I18n.t('components.filters.garages'),
                  props,
                  values,
                )}
                {this.renderMacroOption(
                  I18n.t('components.filters.propertyUsefulArea'),
                )}
                {this.renderAreaField(
                  ['minUsefulArea', 'maxUsefulArea'],
                  values,
                  props,
                )}
                {this.renderMacroOption(
                  I18n.t('components.filters.propertyTotalArea'),
                )}
                {this.renderAreaField(
                  ['minTotalArea', 'maxTotalArea'],
                  values,
                  props,
                )}
                <Row className="FilterMoreMobile__MoreDetailsRow">
                  <div
                    className="FilterMoreMobile__MacroOption  FilterMoreMobile__MoreDetails"
                    onClick={() =>
                      this.setState({ isOptionalsOpen: !isOptionalsOpen })
                    }
                  >
                    {I18n.t('components.filters.optionals')}
                    <ArrowSvgIcon
                      className="FilterMoreMobile__ArrowIcon"
                      up={isOptionalsOpen}
                      width={20}
                      height={20}
                    />
                  </div>
                </Row>
                {isOptionalsOpen && optionalsFilter.length > 0 && (
                  <Row className="FilterMoreMobile__Search">
                    <Col xs={12}>
                      <input
                        className="FormField__Input"
                        onChange={this.searchOptionals}
                        placeholder="Buscar Opcional"
                      />
                    </Col>
                  </Row>
                )}
                {isOptionalsOpen && this.state.optionalsFilter.length
                  ? this.state.optionalsFilter.map(({ Opcional }) => (
                      <Row
                        middle="xs"
                        key={Opcional}
                        className="FilterMoreMobile__MoreDetailsRow"
                      >
                        <Col>
                          <Checkbox
                            name={Opcional}
                            label={Opcional}
                            checked={values.optionals.includes(Opcional)}
                            onChange={({ target: { checked } }) => {
                              let optionalss = values.optionals
                              const index = optionalss.indexOf(Opcional)

                              if (index === -1) optionalss.push(Opcional)
                              else optionalss.splice(index, 1)

                              props.setFieldValue('optionals', optionalss)
                            }}
                          />
                        </Col>
                      </Row>
                    ))
                  : isOptionalsOpen && optionalsFilter.length === 0
                  ? 'É necessário selecionar algum tipo de imóvel para ter opcionais'
                  : isOptionalsOpen && 'Nada encontrado'}
                {/* <Row className="FilterMoreMobile__MoreDetailsRow">
                  <div
                    className="FilterMoreMobile__MicroOption  FilterMoreMobile__MoreDetails"
                    onClick={() =>
                      this.setState({ isMoreDetailsOpen: !isMoreDetailsOpen })
                    }
                  >
                    {I18n.t('components.filters.moreDetail')}
                    <ArrowSvgIcon
                      className="FilterMoreMobile__ArrowIcon"
                      up={isMoreDetailsOpen}
                      width={20}
                      height={20}
                    />
                  </div>
                </Row>
                {isMoreDetailsOpen ? (
                  <div>
                    {this.renderMacroOption(
                      I18n.t('components.filters.details'),
                    )}
                    {this.renderField(
                      I18n.t('components.filters.building'),
                      I18n.t('components.filters.name'),
                      'building',
                      values,
                      props,
                    )}
                    {this.renderField(
                      I18n.t('components.filters.realState'),
                      I18n.t('components.filters.name'),
                      'realState',
                      values,
                      props,
                    )}
                    {this.renderField(
                      I18n.t('components.filters.reference'),
                      I18n.t('components.filters.number'),
                      'reference',
                      values,
                      props,
                    )}
                  </div>
                ) : null} */}
              </div>
              <Row between="xs" className="FilterMoreMobile__ActionContainer">
                <Col>
                  <Button
                    className="FilterMoreMobile__Popover__Cancel"
                    btnStyle="link"
                    onClick={onCancel}
                  >
                    {I18n.t('components.actions.cancel')}
                  </Button>
                </Col>
                <Col>
                  <Button btnStyle="link" type="submit">
                    {I18n.t('components.actions.ok')}
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        </Formik>
      </div>
    )
  }
}

export default FilterMoreMobile
