import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'
import Marker from 'components/Marker'
import ClusterMarker from 'components/ClusterMarker'
import PageBase from 'components/PageBase'
import SearchBar from 'components/Search/SearchBar'
import SearchMap from 'components/Search/Map'
import SearchClient from 'services/search'
import DwellersClient from 'services/dwellers'
import MapService from 'services/map'
import I18n from 'locales'
import VisualizeButton from 'components/Search/Map/VisualizeButton'
import DrawButton from 'components/Search/Map/DrawButton'
import StopDrawingButton from 'components/Search/Map/StopDrawingButton'
import {
  businessTypeEnum,
  getSearchParams as SEARCH,
  REALTY_OPTIONS,
} from 'constants/search'
import { SearchProvider } from 'context/Search'
import * as auth from 'services/auth'
import { Button } from 'components/Buttons'
import Alert from 'components/Alert'
import NotificationIcon from 'components/SvgIcons/NotificationSvgIcon'
import Spinner from 'components/Spinner'
import classNames from 'classnames'
import { ShowRealtyPaginated as ShowRealty } from 'components/ShowRealty'
import { MAP } from 'constants/map'
import MarkerContainer from 'components/MarkerContainer'
import InfoWindow from 'components/InfoWindow'
import Dwellers from 'components/Dwellers'
import { RealtyProvider } from 'context/Realty'
import { ALL } from 'constants/categories'
import { getOptionalsByCategory } from 'services/filter'
import { Row, Col } from 'components/Grid'
import { Formik } from 'components/Form'
import Chat from 'components/Chat'
import ReportModal from 'components/ReportModal'
import Statistics from 'components/Statistics'
import Tour from 'components/Tour'
import { wizardStatus, resetWizard, getPaymentStatus } from 'services/user'
import { callRequest } from 'services/requestHandler'
import { formatRealties } from 'utils/formatRealties'
import { getCityById, getCities } from 'services/location'
import './index.css'

class Search extends Component {
  constructor(props, context) {
    super(props, context)

    this.client = new SearchClient()
    this.state = {
      search: SEARCH(),
      clusters: [],
      infoWindowVisible: false,
      infoWindowContent: null,
      prevSearch: JSON.stringify({ ...SEARCH() }),
      mapOptions: {},
      neighborhoods: {},
      error: {
        category: false,
        businessType: false,
      },
      alert: false,
      loading: false,
      showRealty: false,
      optionalsFilter: [],
      dwellers: false,
      realties: [],
      pristine: true,
      radiusInfo: false,
      selectedAddress: {},
      neighborhoodStatistics: [],
      loadedNeighborhoods: [],
      statistics: false,
      tourStep: undefined,
      tour: false,
      addressType: 'mapped',
    }
    this.dwellerService = new DwellersClient()
    this.Materialize = window.M
    this.lastStep = 0
    this.idList = []
    this.city = null
    this.baseCity = null
    this.cities = []

    !auth.getCityIdLocalStorage() && auth.setCityIdLocalStorage(auth.cityId())
  }

  componentDidMount() {
    wizardStatus(auth.userId()).then(({ status, data }) => {
      if (status === 200) {
        this.setWizardStatus(data)
      }
    })
    getPaymentStatus()
    this.getCities()
  }

  componentDidUpdate(prevProps, prevState) {
    this.shouldOptionalsUpdate(prevState)
    this.state.tour && this.updateTour()
  }

  getCities = () => {
    getCities().then(({ data }) => (this.cities = data))
  }

  setWizardStatus = tour => {
    this.setState({ tour })
  }

  updateTour = () => {
    const {
      search: { businessType, category, drawing },
      clusters,
      loading,
      statistics,
    } = this.state
    const stepOne = this.lastStep === 0 && businessType
    const stepTwo =
      this.lastStep === 1 &&
      businessType &&
      category &&
      Object.values(category).some(el => !!el)
    const stepThree =
      this.lastStep === 2 && businessType && category && clusters.length > 0
    const stepFour =
      this.lastStep === 3 && businessType && category && clusters.length === 0
    const stepFive =
      this.lastStep === 4 &&
      businessType &&
      category &&
      clusters.length === 0 &&
      drawing
    const stepSix =
      this.lastStep === 5 &&
      businessType &&
      category &&
      clusters.length > 0 &&
      !loading
    const stepEight =
      this.lastStep === 7 &&
      businessType &&
      category &&
      clusters.length > 0 &&
      !loading &&
      statistics

    if (stepOne) {
      this.setState({ tourStep: 1 })
    } else if (stepTwo) {
      this.setState({ tourStep: 2 })
    } else if (stepThree) {
      this.setState({ tourStep: 3 })
    } else if (stepFour) {
      setTimeout(() => this.setState({ tourStep: 4 }), 1000)
    } else if (stepFive) {
      this.setState({ tourStep: 5 })
    } else if (stepSix) {
      setTimeout(() => this.setState({ tourStep: 6 }), 1000)
    } else if (stepEight) {
      setTimeout(() => this.setState({ tourStep: 8 }), 5000)
    }
  }

  shouldOptionalsUpdate(prevState) {
    let currentCategories = []
    let prevCategories = []
    if (this.state.search.category) {
      if (Object.keys(this.state.search.category).length) {
        Object.keys(this.state.search.category).forEach(key => {
          if (this.state.search.category[key] && !key.includes('Filter'))
            currentCategories.push(ALL[key].name)
        })
      }

      if (prevState.search.category) {
        Object.keys(prevState.search.category).forEach(key => {
          if (prevState.search.category[key] && !key.includes('Filter'))
            prevCategories.push(ALL[key].name)
        })
      }

      if (
        currentCategories.length &&
        currentCategories.toString() !== prevCategories.toString()
      ) {
        callRequest(
          () => getOptionalsByCategory(currentCategories),
          this.setOptionals,
        )
      }

      if (prevCategories.length > 0 && !currentCategories.length) {
        this.setOptionals([])
      }
    }
  }

  goToStep = step => {
    this.setState({ tourStep: step })
  }

  changeCity = cityId => {
    getCityById(cityId).then(({ data }) => {
      if (data.eemovelProperties) {
        auth.setCityIdLocalStorage(cityId)
        this.city = data
        this.resetMap()
      } else {
        getCityById(
          this.baseCity.eemovelProperties ? this.baseCity.id : 5270,
        ).then(({ data }) => {
          this.city = data
          auth.setCityIdLocalStorage(data.id)
          this.resetMap()
        })
      }
    })
    this.callUpdate()
  }

  clearDrawing = () => {
    this.setState(state => ({
      ...state,
      search: { ...state.search, drawing: false },
      clusters: [],
    }))
    this.mapService.stopDrawing()
    this.mapService.clearPolygons()
    this.resetMap()
    this.callUpdate()
  }

  drawPolygon = () => {
    if (this.mapService) {
      this.startDrawing()
      this.mapService.drawPolygon()
    }
  }

  drawFreeHand = () => {
    if (this.mapService) {
      this.startDrawing()
      this.mapService.drawFreeHand()
    }
  }

  formatStateForQuery = () => {
    const {
      search: {
        priceRange,
        businessType,
        more,
        query,
        category,
        privateBusiness,
      },
      addressType,
    } = this.state
    const polygon = this.mapService.getPolygonsAsArray()

    let priceType
    switch (businessTypeEnum(businessType).value) {
      case 2:
        priceType = 'transaction_rent'
        break
      case 3:
        priceType = 'transaction_season'
        break
      default:
        priceType = 'transaction_sale'
        break
    }

    const aggregations = [
      { type: 'percentiles', field: priceType, value: 'price' },
      { type: 'percentiles', field: 'area', value: 'area' },
      { type: 'percentiles', field: 'total_area', value: 'totalArea' },
      { type: 'max', field: 'neighborhood_id', value: 'neighborhoodId' },
    ]
    const source = ['location', 'link_key', 'type']
    const mapped = true

    return {
      priceRange,
      businessType: priceType,
      polygon,
      query,
      category,
      aggregations,
      source,
      mapped,
      addressType,
      privateBusiness,
      ...more,
    }
  }

  getWarningMessage = () => {
    const { category, businessType } = this.state.error

    if (category && businessType)
      return I18n.t('warningMessages.noBusinessAndCategory')

    if (!businessType && category) return I18n.t('warningMessages.noCategory')

    if (businessType && !category) return I18n.t('warningMessages.noBusiness')

    return I18n.t('warningMessages.noBusinessAndCategory')
  }

  setOptionals = optionals => {
    this.setState(state => ({ ...state, optionalsFilter: optionals }))
  }

  handleMapChange = mapOptions => {
    this.setState(
      {
        mapOptions,
      },
      () => {
        const { clusters } = this.state
        if (this.mapService && this.mapService.clusterExists) {
          const newClusters = this.mapService.getClusters(mapOptions)
          JSON.stringify(clusters) !== JSON.stringify(newClusters) &&
            this.setState({ clusters: newClusters })
        }
      },
    )
  }

  handleGeocodeCallback = (status, { center, zoom }) => {
    this.setState({ dwellers: false })
    if (status === 'OK') {
      this.panMap(zoom, center)
    } else {
      this.handleError(status)
    }
  }

  handleOnSearch = values => {
    const {
      minPrice,
      maxPrice,
      query,
      more,
      businessType,
      category,
      privateBusiness,
    } = values
    const priceRange =
      minPrice >= 0 || maxPrice >= 0 ? { minPrice, maxPrice } : null

    const addressSource = more || this.state.search.more
    this.setState(
      prevState => ({
        ...prevState,
        search: {
          ...prevState.search,
          businessType: businessType || prevState.search.businessType,
          privateBusiness:
            (privateBusiness === undefined &&
              prevState.search.privateBusiness) ||
            privateBusiness,
          priceRange: priceRange ? priceRange : prevState.search.priceRange,
          query: query || prevState.search.query,
          more: { ...(more || prevState.search.more) },
          category: category || prevState.search.category,
        },
        addressType: addressSource.fullAddress ? 'mapped' : 'both',
      }),
      () => this.validateSearch(),
    )

    if (query) {
      this.mapService.geocode(query, this.handleGeocodeCallback)
    }
  }

  handleSubmitSearch = () => {
    const { query } = this.state
    const options = this.formatStateForQuery()
    this.setState(state => ({ ...state, loading: true, pristine: false }))

    //count and region stats
    this.client
      .searchV2(null, {
        ...options,
        mapped: undefined,
        filterPaths: ['aggregations.*'],
        aggs: {
          type: 'terms',
          field: 'geo_process_pinned',
          name: 'counts',
        },
      })
      .then(({ data: { aggregations } }) => {
        if (aggregations) {
          const {
            neighborhood,
            my_buckets: { buckets },
          } = aggregations
          this.setState({
            neighborhoodStatistics: neighborhood.buckets,
          })
          if (buckets && buckets.length) {
            const mappeds = buckets
              .filter(item => item.key.address_status < 3)
              .reduce((acc, curr) => (acc += curr.doc_count), 0)
            const unmappeds = buckets
              .filter(item => item.key.address_status > 2)
              .reduce((acc, curr) => (acc += curr.doc_count), 0)
            this.setState({
              unmappeds: unmappeds || 0,
              mappeds: mappeds || 0,
            })
          } else {
            this.setState({
              unmappeds: 0,
              mappeds: 0,
            })
          }
        }
      })
      .catch(err => {
        this.handleError(err)
      })

    //search
    this.client
      .searchV2(query, {
        ...options,
        source: REALTY_OPTIONS,
        size: 100000,
        filterPaths: ['hits.hits*'],
      })
      .then(results => {
        this.setState(state => ({ ...state, loading: false }))
        this.parseResults(results)
      })
      .catch(err => {
        this.handleError(err)
        this.Materialize.toast({
          html: I18n.t('common.fail'),
          classes: 'Search__Toast--failure',
        })
        this.setState(state => ({ ...state, loading: false }))
      })
  }

  handleError = err => {
    console.error(err)
  }

  handleNeighborhoodsResponse = (
    { data },
    cityId = auth.getCityIdLocalStorage(),
    load = true,
  ) => {
    const neighborhoods = data.map(neighborhood => ({
      id: neighborhood.Id,
      name: neighborhood.Nome,
      path: this.mapService.decodePath(neighborhood.PontosPoligono),
      latLng: {
        lat: neighborhood.PontoCentralLatitude,
        lng: neighborhood.PontoCentralLongitude,
      },
    }))
    this.setState({ loadedNeighborhoods: neighborhoods })
    load &&
      this.mapService.loadNeighborhoods(neighborhoods, this.callUpdate, cityId)
  }

  handleZonesResponse = ({ data }) => {
    this.mapService.loadZones(
      data.map(zone => ({
        id: zone.Id,
        name: zone.Sigla,
        path: this.mapService.decodePath(zone.Encodes[0]),
      })),
      this.callUpdate,
    )
  }

  handleSelectVisualization = (
    visualization = null,
    formover = null,
    cityId = auth.getCityIdLocalStorage(),
  ) => {
    const currentVisualization =
      visualization || this.state.search.visualization

    if (visualization && formover) {
      this.setState(
        state => ({ search: { ...state.search, visualization } }),
        () => formover.toggle(),
      )
    }

    this.mapService.clearMap()

    if (currentVisualization.neighborhoods) {
      this.mapService.selectedNeighborhoods.length === 0 &&
        !this.mapService.loadedNeighborhoods[cityId] &&
        callRequest(
          () => this.client.neighborhoods(cityId),
          data => this.handleNeighborhoodsResponse({ data }, cityId),
          null,
          this.handleError,
        )

      this.mapService.selectedNeighborhoods.length === 0 &&
        this.mapService.loadedNeighborhoods[cityId] &&
        this.mapService.renderNeighborhoods(cityId)

      this.mapService.selectedNeighborhoods.length > 0 &&
        this.mapService.stopSelecting()
    }

    if (currentVisualization.zones) {
      !this.mapService.loadedZones[cityId] &&
        callRequest(
          () => this.client.zones(cityId),
          data => this.handleZonesResponse({ data }),
          null,
          this.handleError,
        )
      this.mapService.loadedZones[cityId] && this.mapService.renderZones()

      callRequest(
        () => this.client.neighborhoods(cityId),
        data => this.handleNeighborhoodsResponse({ data }, cityId, false),
        null,
        this.handleError,
      )
    }

    if (currentVisualization.satellite) {
      this.mapService.changeToSatelliteView()
    } else {
      this.mapService.changeToRoadmapView()
    }
  }

  handleClearFilters = () => {
    this.setState(
      state => ({
        ...state,
        search: { ...SEARCH() },
        prevSearch: JSON.stringify({ ...SEARCH() }),
        mappeds: null,
        unmappeds: null,
        alert: false,
        pristine: true,
      }),
      () => {
        this.clearClusters()
        this.validateSearch(this.state.search)
      },
    )
  }

  handleSetPrevSearch = (prevSearch, submit) => {
    const { alert } = this.state
    if (alert) {
      this.setState(
        state => ({
          ...state,
          alert: false,
          prevSearch: JSON.stringify(prevSearch),
        }),
        () =>
          this.setState(state => ({
            ...state,
            alert: true,
          })),
      )
    } else {
      this.setState(state => ({
        ...state,
        prevSearch: JSON.stringify(prevSearch),
      }))
    }
    submit && this.handleSubmitSearch()
  }

  callUpdate = () => {
    this.forceUpdate()
  }

  initializeMapService = google => {
    callRequest(
      () => getCityById(auth.cityId()),
      data => {
        this.mapService = new MapService(google, this.callUpdate)
        auth.setCityIdLocalStorage(auth.cityId())
        this.handleSelectVisualization(
          null,
          null,
          data.eemovelProperties ? auth.cityId() : 5270,
        )
        if (data.eemovelProperties) {
          this.city = data
          this.baseCity = data
          this.panMap(
            this.city.eemovelProperties.zoom,
            this.city.eemovelProperties.coordinates,
          )
        } else {
          auth.setCityIdLocalStorage(5270)
          getCityById(5270).then(({ data }) => {
            this.city = data
            this.baseCity = data
            this.panMap(
              this.city.eemovelProperties.zoom,
              this.city.eemovelProperties.coordinates,
            )
          })
          window.M.toast({
            html:
              'Infelizmente não conseguimos localizar sua cidade, redirecionamos você para São Paulo',
            classes: 'Toast--failure',
          })
        }
        this.callUpdate()
      },
    )
  }

  parseResults = results => {
    const { data } = results
    if (data.hits) {
      this.mapService.loadClusters(
        data.hits.hits
          .filter(item => item._source.geo_process_address_status < 3)
          .map(realState => ({
            esIndex: realState._id,
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: realState._source.location.coordinates,
            },
          })),
      )
      this.setState(state => ({
        ...state,
        clusters: this.mapService.getClusters(state.mapOptions),
      }))
    }
  }

  shouldShowAlertButton = () => {
    const { prevSearch, search } = this.state
    const defaultSearch = JSON.stringify({ ...SEARCH() })
    const currentSearch = JSON.stringify({ ...search })
    return (
      prevSearch !== defaultSearch &&
      currentSearch !== defaultSearch &&
      !search.drawing
    )
  }

  stopDrawing = () => {
    if (this.mapService) {
      this.mapService.stopDrawing()
      this.setState(
        state => ({
          ...state,
          search: { ...state.search, drawing: false },
        }),
        () => {
          const { search } = this.state
          this.validateSearch(search)
        },
      )
    }
  }

  startDrawing = () => {
    this.clearClusters()
    this.mapService.resetMap()
    this.setState(state => ({
      search: { ...state.search, drawing: true },
      clusters: [],
    }))
  }

  toggleTour = () => {
    const { tour } = this.state
    this.setState(
      state => ({ ...state, tour: !tour }),
      () => resetWizard(auth.userId()),
    )
  }

  toggleAlert = () => {
    const { alert } = this.state
    this.setState(state => ({ ...state, alert: !alert }))
  }

  toggleShowRealty = mapped => {
    const { showRealty, tourStep } = this.state
    this.idList = []
    this.setState(state => ({
      ...state,
      mappedClick: mapped,
      showRealty: !showRealty,
      tourStep: tourStep === 8 ? 9 : tourStep === 9 ? 10 : tourStep,
      realties: [],
    }))
  }

  toggleDwellers = () => {
    this.setState(state => ({
      ...state,
      dwellers: !state.dwellers,
      tourStep: state.tourStep === 10 ? 11 : state.tourStep,
      statistics: false,
    }))
  }

  setDwellersNextStep = () => {
    setTimeout(() => this.setState({ tourStep: 12 }), 2000)
  }

  toggleStatistics = () => {
    this.setState(state => ({
      ...state,
      statistics: !state.statistics,
      tourStep: state.tourStep === 6 ? 7 : state.tourStep,
      dwellers: false,
    }))
  }

  toggleReportModal = () => {
    this.setState(state => ({
      ...state,
      reportModalVisible: false,
      reportRealtyId: null,
    }))
  }

  openReportModal = id => {
    this.setState(state => ({
      ...state,
      reportRealtyId: id,
      reportModalVisible: true,
    }))
  }

  validateSearch = (prevState = null) => {
    const { businessType, category } = this.state.search
    let valid = true
    let categoryCount = 0

    if (category) {
      Object.keys(category).forEach(key => {
        if (category[key]) categoryCount++
      })
    }

    if (categoryCount === 0) {
      this.setState(state => ({
        ...state,
        error: { ...state.error, category: true },
      }))
      valid = false
    } else {
      this.setState(state => ({
        ...state,
        error: { ...state.error, category: false },
      }))
    }

    if (businessType === '') {
      this.setState(state => ({
        ...state,
        error: { ...state.error, businessType: true },
      }))
      valid = false
    } else {
      this.setState(state => ({
        ...state,
        error: { ...state.error, businessType: false },
      }))
    }

    if (prevState) this.handleSetPrevSearch(prevState, valid)
    if (!valid) {
      this.Materialize.toast({
        html: this.getWarningMessage(),
        classes: 'Search__Toast',
      })
    }
  }

  resetMap = city => {
    this.state.infoWindowVisible && this.toggleInfoWindow()
    this.clearClusters()
    this.mapService.resetMap()
    this.handleSelectVisualization()
    this.setState(({ mapOptions }) => ({
      neighborhoodStatistics: [],
      statistics: false,
      dwellers: false,
      mappeds: 0,
      unmappeds: 0,
      pristine: true,
      mapOptions: {
        ...mapOptions,
        center: this.city.eemovelProperties.coordinates,
        zoom: this.city.eemovelProperties.zoom,
      },
    }))
  }

  getOffset(zoom) {
    switch (zoom) {
      case 14:
        return 0.009
      case 15:
        return 0.007
      case 16:
        return 0.003
      case 17:
        return 0.0015
      case 18:
        return 0.0007
      case 19:
        return 0.00035
      case 20:
        return 0.00016
      default:
        return 0.009
    }
  }

  handlePinOnClick = pin => {
    const {
      mapOptions: { zoom },
      search: { businessType },
    } = this.state
    const newLat = pin.geometry.coordinates[1] + this.getOffset(zoom)
    this.panMap(this.state.mapOptions.zoom, [
      newLat,
      pin.geometry.coordinates[0],
    ])
    this.setState(
      {
        lastClickedPin: pin.geometry.coordinates,
        infoWindowVisible: true,
        infoWindowContent: null,
      },
      () => {
        this.client
          .realtyById({
            id: pin.esIndex,
            source: REALTY_OPTIONS,
          })
          .then(({ data }) => {
            data.hits &&
              data.hits.hits &&
              formatRealties(
                data.hits.hits,
                businessType,
                this.formatRealtiesInfoWindowCallback,
              )
          })
      },
    )
  }

  toggleInfoWindow = () => {
    this.setState({
      infoWindowContent: null,
      infoWindowVisible: !this.state.infoWindowVisible,
    })
  }

  renderMarkers = () => {
    const zoomClick = (id, geometry) => {
      this.panMap(
        Math.floor(
          this.mapService.getClusterExpansionZoom(id) + 2,
          MAP.options.maxZoom,
        ),
        geometry,
      )
    }

    const zoomedClick = clusterId => {
      this.toggleShowRealty()
      this.idList = this.mapService
        .getClusterLeaves(clusterId)
        .map(leaf => leaf.esIndex)
      this.handlePageChange({
        id: this.idList,
        sort: {
          created_at: 'desc',
          _id: 'asc',
        },
      })
    }

    return this.state.clusters.map((item, index) => {
      const { properties, geometry } = item
      return (
        <MarkerContainer
          key={index}
          lat={geometry.coordinates[1]}
          lng={geometry.coordinates[0]}
        >
          {!properties ? (
            <Marker onClick={() => this.handlePinOnClick(item)} />
          ) : (
            <ClusterMarker
              zoomClick={() =>
                zoomClick(properties.cluster_id, geometry.coordinates.reverse())
              }
              zoomedClick={() => zoomedClick(properties.cluster_id)}
              currentZoom={this.state.mapOptions.zoom}
              pointsCount={properties.point_count_abbreviated}
            />
          )}
        </MarkerContainer>
      )
    })
  }

  formatRealtiesCallback = ({ data }) => {
    this.setState({ realties: data })
  }

  formatRealtiesInfoWindowCallback = ({ data }) => {
    this.setState({ infoWindowContent: data[0] })
  }

  clearClusters = () => {
    this.setState({ clusters: [] })
    this.mapService.clearClusters()
  }

  renderMapButtons = () => {
    const {
      alert,
      search: { visualization, drawing },
      tourStep,
    } = this.state
    const alertContainer = classNames('Search__AlertContainer', {
      'Search__AlertContainer--open': alert,
      'Search__AlertContainer--close': !alert,
    })
    return (
      <div className="Search__MapBtnContainer">
        {!drawing &&
          tourStep !== 2 &&
          this.mapService &&
          !this.mapService.isSelectingNeighborhoods && (
            <div className="Search__ButtonContainer">
              <VisualizeButton
                onSubmit={this.handleSelectVisualization}
                initialValues={visualization}
                zoning={
                  this.city &&
                  this.city.eemovelProperties &&
                  this.city.eemovelProperties.zoning
                }
              />
            </div>
          )}
        {this.mapService &&
          !this.mapService.isSelectingNeighborhoods &&
          tourStep !== 2 && (
            <div className="Search__ButtonContainer">
              {!drawing ? (
                <DrawButton
                  drawPolygon={this.drawPolygon}
                  drawFreeHand={this.drawFreeHand}
                />
              ) : (
                <Fragment>
                  <StopDrawingButton onClick={this.stopDrawing} />
                  <Button
                    className="Search__MapButton"
                    onClick={this.clearDrawing}
                  >
                    <i className="material-icons">close</i>
                    {I18n.t('components.actions.cancel')}
                  </Button>
                </Fragment>
              )}
            </div>
          )}
        {this.shouldShowAlertButton() &&
          this.mapService &&
          !this.mapService.isSelectingNeighborhoods &&
          auth.userId() !== 14801 && (
            <Button className="Search__MapButton" onClick={this.toggleAlert}>
              <NotificationIcon />
              {I18n.t('components.actions.createAlert')}
            </Button>
          )}
        <div data-tut="step-four">
          {this.mapService && this.mapService.polygons.length !== 0 && (
            <Button className="Search__MapButton" onClick={this.resetMap}>
              <i className="material-icons">autorenew</i>
              {I18n.t('components.filters.resetMap')}
            </Button>
          )}
        </div>
        {this.mapService &&
          this.mapService.selectedNeighborhoods.length > 0 &&
          this.mapService.polygons.length === 0 &&
          !this.state.search.drawing && (
            <Button
              className="Search__MapButton Search__Animated"
              onClick={this.selectNeighborhoods}
            >
              <i className="material-icons">check</i>
              {I18n.t('components.actions.apply')}
            </Button>
          )}
        <div className={alertContainer}>
          {alert && <Alert onCancel={this.toggleAlert} />}
        </div>
      </div>
    )
  }

  selectNeighborhoods = () => {
    this.mapService.stopSelecting()
    this.validateSearch(this.state.search)
  }

  handleShowRealtyBtnClick = (mapped = false, onlyUnmapped = false) => {
    let options = {
      ...this.formatStateForQuery(),
      source: REALTY_OPTIONS,
      aggregations: undefined,
      sort: {
        created_at: 'desc',
        _id: 'asc',
      },
      filterPaths: ['hits.*'],
    }

    this.toggleShowRealty(mapped)
    this.handlePaginatedSearch(options)
  }

  handlePageChange = (
    { fullAddress, sort, searchAfter, query, id, rest },
    callback,
  ) => {
    let options = {
      ...this.formatStateForQuery(),
      sort,
      searchAfter,
      query,
      id,
      source: REALTY_OPTIONS,
      aggregations: undefined,
      filterPaths: ['hits.*'],
    }
    this.handlePaginatedSearch(options, callback)
  }

  handlePaginatedSearch = (options, callback = null) => {
    const { businessType } = this.state.search
    this.client
      .searchPaginated(options)
      .then(({ data: { hits } }) => {
        // TODO add count request
        this.setState({ totalRealties: hits.total.value })
        hits.hits &&
          formatRealties(hits.hits, businessType, this.formatRealtiesCallback)
      })
      .catch(err => {
        this.handleError(err)
      })
      .then(() => {
        callback && callback()
      })
  }

  panMap = (zoom, center) => {
    this.setState(({ mapOptions }) => ({
      mapOptions: {
        ...mapOptions,
        zoom,
        center,
      },
    }))
  }

  closeRadiusInfo = () => {
    this.setState(state => ({ ...state, radiusInfo: false }))
  }

  setRadiusInfo = selectedAddress => {
    this.setState(state => ({
      ...state,
      selectedAddress,
      radiusInfo: true,
      dwellers: false,
    }))
  }

  changeRadius = ({ radius }) => {
    const { selectedAddress } = this.state
    const parsedRadius = Number(radius)
    this.mapService.createCircle(
      {
        lat: selectedAddress.location.lat(),
        lng: selectedAddress.location.lng(),
      },
      parsedRadius,
    )
    this.panMap(this.state.mapOptions.zoom, {
      lat: selectedAddress.location.lat(),
      lng: selectedAddress.location.lng(),
    })
    this.closeRadiusInfo()
    this.validateSearch(this.state.search)
  }

  renderRadiusInfo = () => {
    const { radiusInfo } = this.state
    const radiusClasses = classNames({
      'Search__Pane--hidden': !radiusInfo,
      Search__Pane: radiusInfo,
    })
    return (
      <div className={radiusClasses}>
        <div className="Search__Radius">
          <Row end="xs">
            <Col xs={12}>
              <i className="material-icons" onClick={this.closeRadiusInfo}>
                close
              </i>
            </Col>
          </Row>
          <div className="Search__RadiusContainer">
            <span>Em um raio de quantos metros você deseja procurar?</span>
            <Formik
              initialValues={{ radius: 250 }}
              onSubmit={this.changeRadius}
            >
              {({ handleSubmit, handleChange, values: { radius } }) => (
                <form onSubmit={handleSubmit}>
                  <input name="radius" value={radius} onChange={handleChange} />
                  <Button type="submit" btnStyle="primary" btnSize="xs" compact>
                    <i className="material-icons">check</i>
                  </Button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    )
  }

  renderGadgets = () => {
    const {
      loading,
      showRealty,
      realties,
      totalRealties,
      mappedClick,
      search: { drawing, businessType },
    } = this.state
    return (
      <Fragment>
        <Spinner visible={loading} />
        {showRealty && (
          <RealtyProvider
            value={{
              realties,
              totalRealties,
              mappedClick,
              idList: this.idList,
              visible: showRealty,
              handlePageChange: this.handlePageChange,
              toggle: this.toggleShowRealty,
            }}
          >
            <ShowRealty businessType={businessType} />
          </RealtyProvider>
        )}
        {this.renderRadiusInfo()}
        {this.mapService &&
          (this.mapService.isSelectingNeighborhoods || drawing) && (
            <div className="Search__GlassPane" />
          )}
        <Chat />
      </Fragment>
    )
  }

  render() {
    const {
      mapOptions,
      prevSearch,
      error,
      clusters,
      search,
      loading,
      infoWindowVisible,
      lastClickedPin,
      infoWindowContent,
      optionalsFilter,
      dwellers,
      unmappeds,
      mappeds,
      pristine,
      statistics,
      neighborhoodStatistics,
      tourStep,
      tour,
      reportRealtyId,
      reportModalVisible,
      loadedNeighborhoods,
    } = this.state
    const {
      query,
      businessType,
      privateBusiness,
      drawing,
      priceRange,
      more,
      category,
      visualization,
    } = search
    const { center, zoom } = mapOptions
    const mapContainer = classNames({
      Search__MapContainer: !dwellers,
      'Search__MapContainer--active': dwellers,
    })
    const sideContainer = classNames('Search__SideContainer', {
      'Search__SideContainer--active': dwellers || statistics,
    })
    return (
      <SearchProvider
        value={{
          filters: {
            businessType,
            privateBusiness,
            drawing,
            priceRange,
            more,
            category,
          },
          currentCity: this.city,
          city: this.baseCity,
          cities: this.cities,
          Materialize: this.Materialize,
          goToStep: this.goToStep,
          client: this.client,
          dwellerService: this.dwellerService,
          changeCity: this.changeCity,
          mapService: this.mapService,
          handleGeocodeCallback: this.handleGeocodeCallback,
          setRadiusInfo: this.setRadiusInfo,
          clearFilters: this.handleClearFilters,
          onSubmit: this.handleOnSearch,
          onSearch: this.handleSubmitSearch,
          setPrevSearch: this.validateSearch,
          polygons: this.mapService ? this.mapService.getPolygonsAsArray() : [],
          unmappeds,
          mappeds,
          visualization,
          prevSearch,
          error,
          optionalsFilter,
          openDwellers: this.toggleDwellers,
          clusters,
          query,
          loadedNeighborhoods,
          clearClusters: this.clearClusters,
        }}
      >
        <PageBase
          fluid
          className="Search"
          TopComponent={() => (
            <div className="Search__Bars">
              <SearchBar />
            </div>
          )}
        >
          <Helmet>
            <title>EEmovel - {I18n.t('pages.search.title')}</title>
          </Helmet>
          {((unmappeds || mappeds) && !loading && (
            <div className="Search__ShowRealtyBar">
              <div className="Search__ShowRealtyBar__Container">
                <div className="Search__ShowRealtyBar__Count">
                  {I18n.t('components.filters.foundAds')}
                  {mappeds > 0 && !unmappeds > 0 && (
                    <span
                      className="Search__ShowRealtyBar__Count__Text"
                      onClick={() =>
                        this.setState(
                          { addressType: 'mapped' },
                          this.handleShowRealtyBtnClick,
                        )
                      }
                    >{`${mappeds.toLocaleString(
                      'pt-BR',
                    )} com endereço completo`}</span>
                  )}
                  {mappeds > 0 && unmappeds > 0 && (
                    <span
                      className="Search__ShowRealtyBar__Count__Text"
                      onClick={() =>
                        this.setState(
                          { addressType: 'mapped' },
                          this.handleShowRealtyBtnClick,
                        )
                      }
                    >{`${mappeds.toLocaleString('pt-BR')} com endereço`}</span>
                  )}
                  {unmappeds > 0 && (
                    <Fragment>
                      {' - '}
                      <span
                        className="Search__ShowRealtyBar__Count__Text"
                        onClick={() =>
                          this.setState(
                            { addressType: 'unmapped' },
                            this.handleShowRealtyBtnClick,
                          )
                        }
                      >{`${unmappeds.toLocaleString(
                        'pt-BR',
                      )} sem endereço`}</span>
                    </Fragment>
                  )}
                </div>
                {(mappeds || unmappeds) && (
                  <div className="Search__ShowRealtyBar__Total">
                    Total:{' '}
                    <span className="Search__ShowRealtyBar__Total__Text">
                      {mappeds && unmappeds
                        ? (mappeds + unmappeds).toLocaleString('pt-BR')
                        : mappeds
                        ? mappeds.toLocaleString('pt-BR')
                        : unmappeds.toLocaleString('pt-BR')}{' '}
                      {I18n.t('components.filters.opportunities')}
                    </span>
                  </div>
                )}
                <Button
                  className="Search__ShowRealtyBar__ShowRealtyBtn"
                  onClick={() =>
                    this.setState(
                      prev => ({
                        addressType: prev.search.more.fullAddress
                          ? 'mapped'
                          : 'both',
                      }),
                      this.handleShowRealtyBtnClick,
                    )
                  }
                  data-tut="step-nine"
                >
                  <i className="material-icons">location_city</i>
                  {I18n.t('components.showRealtyBtn.show')}
                </Button>
                {neighborhoodStatistics.length > 0 &&
                  !statistics &&
                  auth.userId() !== 14801 && (
                    <Button
                      className="Search__ShowRealtyBar__ShowRealtyBtn"
                      onClick={this.toggleStatistics}
                      data-tut="step-seven"
                    >
                      <i className="material-icons">poll</i>
                      Estatísticas
                    </Button>
                  )}
              </div>
            </div>
          )) ||
            (!unmappeds && !mappeds && !pristine && !loading && (
              <div className="Search__ShowRealtyBar">
                <div className="Search__ShowRealtyBar__Container">
                  <div className="Search__ShowRealtyBar__NotFound">
                    <div>{I18n.t('components.showRealty.noRealtyFound')}</div>
                  </div>
                </div>
              </div>
            ))}
          <div className="Search__ContentContainer" data-tut="step-six">
            <div className={mapContainer} data-tut="step-three">
              <SearchMap
                onChange={this.handleMapChange}
                useMapsApi
                onGoogleApiLoaded={this.initializeMapService}
                streetViewControl
                zoom={zoom}
                maxZoom={MAP.options.maxZoom}
                center={center}
                visualization={visualization}
              >
                {this.renderMarkers()}
                {infoWindowVisible && (
                  <InfoWindow
                    reportRealtyId={reportRealtyId}
                    reportModalVisible={reportModalVisible}
                    toggleReportModal={this.toggleReportModal}
                    openReportModal={this.openReportModal}
                    toggleInfoWindow={this.toggleInfoWindow}
                    content={infoWindowContent || 'Carregando...'}
                    lat={lastClickedPin ? lastClickedPin[1] : null}
                    lng={lastClickedPin ? lastClickedPin[0] : null}
                    businessType={businessType}
                  />
                )}
              </SearchMap>
              {this.renderMapButtons()}
            </div>
            <div className={sideContainer}>
              {dwellers && (
                <Dwellers
                  modal={false}
                  visible={dwellers}
                  close={this.toggleDwellers}
                  setDwellersStep={this.setDwellersNextStep}
                />
              )}
              {statistics && (
                <Statistics
                  statistics={neighborhoodStatistics}
                  active={statistics}
                  close={this.toggleStatistics}
                />
              )}
            </div>
            <Tour
              onClose={this.toggleTour}
              getCurrentStep={step => (this.lastStep = step)}
              isOpen={tour}
              goToStep={tourStep}
            />
          </div>
          {this.renderGadgets()}
          {reportModalVisible && (
            <ReportModal
              id={reportRealtyId}
              visible={reportModalVisible}
              toggle={this.toggleReportModal}
            />
          )}
        </PageBase>
      </SearchProvider>
    )
  }
}

export default Search
