import React, { Fragment, PureComponent } from 'react'
import { Formik, Field, Form } from 'components/Form'
import { Row, Col } from 'components/Grid'
import { Button } from 'components/Buttons'
import { updatePassword, getPaymentStatus } from 'services/user'
import { callRequest } from 'services/requestHandler'
import * as Yup from 'yup'
import I18n from 'locales'
import Spinner from 'components/Spinner'
import './index.css'

const AccessDataSchema = Yup.object().shape({
  email: Yup.string().required(I18n.t('login.required')),
  currentPassword: Yup.string().required(I18n.t('login.required')),
  newPassword: Yup.string().required(I18n.t('login.required')),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], I18n.t('errors.passwordMustMatch'))
    .required(I18n.t('login.required')),
})

class AccessData extends PureComponent {
  constructor() {
    super()
    this.state = {
      ufs: [],
      loading: false,
    }
    this.Materialize = window.M
  }

  componentDidMount() {
    getPaymentStatus()
  }

  updateUserPassword = ({ email, currentPassword, newPassword }, actions) => {
    const payload = {
      email,
      currentPassword,
      newPassword,
      userId: this.props.user.Id,
    }
    const setNotLoading = () => this.setState({ loading: false })
    callRequest(
      () => updatePassword(payload),
      () => {
        setNotLoading()
        actions.resetForm()
        this.Materialize.toast({
          html: I18n.t('common.successfullyAltered'),
          classes: 'AccessData__Toast--success',
        })
      },
      setNotLoading,
      setNotLoading,
    )
  }

  render() {
    const {
      user: { email },
      mobile,
    } = this.props
    const { loading } = this.state
    return (
      <Fragment>
        <Formik
          initialValues={{
            email,
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
          }}
          onSubmit={(values, actions) =>
            this.updateUserPassword(values, actions)
          }
          validationSchema={AccessDataSchema}
        >
          {({
            handleChange,
            values: { email, currentPassword, newPassword, confirmNewPassword },
            handleSubmit,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col xs={12} lg={6}>
                  <Field
                    name="email"
                    label="E-mail"
                    type="email"
                    value={email}
                    disabled
                    error={errors.email}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={6}>
                  <Field
                    name="currentPassword"
                    label={I18n.t('accessData.currentPassword')}
                    type="password"
                    value={currentPassword}
                    placeholder={I18n.t(
                      'accessData.currentPasswordPlaceholder',
                    )}
                    error={errors.currentPassword}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={6}>
                  <Field
                    name="newPassword"
                    label={I18n.t('accessData.newPassword')}
                    type="password"
                    value={newPassword}
                    placeholder={I18n.t('accessData.newPasswordPlaceholder')}
                    error={errors.newPassword}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={6}>
                  <Field
                    name="confirmNewPassword"
                    label={I18n.t('accessData.confirmNewPassword')}
                    type="password"
                    value={confirmNewPassword}
                    placeholder={I18n.t(
                      'accessData.confirmNewPasswordPlaceholder',
                    )}
                    error={errors.confirmNewPassword}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="AccessData__BtnRow">
                <Col xs={12}>
                  <Button type="submit" btnStyle="primary" block={mobile}>
                    {I18n.t('common.save')}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
        <Spinner visible={loading} />
      </Fragment>
    )
  }
}

export default AccessData
