import React from 'react'
import { Link } from 'react-router-dom'
import I18n from 'locales'
import { Grid, Row, Col } from 'components/Grid'
import { logout, userId } from 'services/auth'
import './index.css'

const onClick = () => {
  if (window.location.pathname === '/') {
    window.scrollTo(0, 300)
  } else {
    setTimeout(() => {
      window.scrollTo(0, 300)
    }, 500)
  }
}

const HeaderLoggedIn = () => (
  <div>
    <Grid>
      <Row className="NavMenuMobile__Sidebar__Link">
        <Link to="/" onClick={onClick}>
          {I18n.t('components.navBar.home')}
        </Link>
      </Row>
      <Row className="NavMenuMobile__Sidebar__Link">
        <Link to="/preferencias">
          {I18n.t('components.navBar.preferences')}
        </Link>
      </Row>
      <Row className="NavMenuMobile__Sidebar__Link">
        <a
          href="http://faq.eemovel.com.br/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {I18n.t('components.navBar.support')}
        </a>
      </Row>
      {userId() !== 14801 && (
        <Row className="NavMenuMobile__Sidebar__Link">
          <Link to="/avaliacao">{I18n.t('components.navBar.evaluation')}</Link>
        </Row>
      )}
      <Row className="NavMenuMobile__Sidebar__Link">
        <Link to="/busca">{I18n.t('components.navBar.search')}</Link>
      </Row>
      <Row className="NavMenuMobile__Sidebar__Link">
        <Link onClick={() => logout()} to="/">
          {I18n.t('components.navBar.logout')}
        </Link>
      </Row>
    </Grid>
    <Grid className="NavMenuMobile__SocialMediaContainer">
      <Row>
        <span className="NavMenuMobile__SocialMediaText">
          {I18n.t('components.navBar.socialMediaText')}
        </span>
      </Row>
      <Row>
        <Col xs={6}>
          <a
            href="https://www.facebook.com/EEmovel/"
            target="_blank"
            rel="noopener noreferrer"
            className="NavMenuMobile__SocialMediaLink"
          >
            {I18n.t('components.navBar.facebook')}
          </a>
        </Col>
        <Col xs={6}>
          <a
            href="https://www.youtube.com/channel/UCP_YYG2TqBjEIVZm5TsBjFA"
            target="_blank"
            rel="noopener noreferrer"
            className="NavMenuMobile__SocialMediaLink"
          >
            {I18n.t('components.navBar.youtube')}
          </a>
        </Col>
      </Row>
    </Grid>
  </div>
)

export default HeaderLoggedIn
