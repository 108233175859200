import React from 'react'
import classNames from 'classnames'
import MobileHeader from 'components/MobileHeader'
import { Row, Col } from 'components/Grid'
import './index.css'

const MobilePageBase = ({
  className,
  fluid,
  children,
  TopComponent,
  BottomComponent,
  FilterComponent,
}) => {
  const classes = classNames('PageBaseMobile', className)

  return (
    <div className={classes}>
      <MobileHeader searchBar={TopComponent && <TopComponent />} />
      <FilterComponent />
      <Row className="PageBaseMobile__Grid">
        <Col xs>{children}</Col>
      </Row>
      {BottomComponent && <BottomComponent />}
    </div>
  )
}

export default MobilePageBase
