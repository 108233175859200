import React, { Component } from 'react'
import { Field, Form, Checkbox, InputMask, AutoComplete } from 'components/Form'
import { Button } from 'components/Buttons'
import { Formik } from 'formik'
import { Row, Col } from 'components/Grid'
import {
  TELEPHONE_VALIDATION,
  CITY_VALIDATION,
  GET_PHONE_MASK,
} from 'utils/mask'
import { getCities, submitRegister } from 'services/register'
import { debounce } from 'lodash'
import Spinner from 'components/Spinner'
import I18n from 'locales'
import * as Yup from 'yup'
import Chat from 'components/Chat'
import classNames from 'classnames'
import './index.css'

const RegisterSchema = Yup.object().shape({
  firstName: Yup.string().required(I18n.t('login.required')),
  lastName: Yup.string().required(I18n.t('login.required')),
  email: Yup.string()
    .email(I18n.t('login.invalidEmail'))
    .required(I18n.t('login.required')),
  password: Yup.string()
    .min(6, I18n.t('login.tooShort'))
    .required(I18n.t('login.required')),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], I18n.t('errors.passwordMustMatch'))
    .required(I18n.t('login.required')),
  telephone: Yup.string()
    .matches(TELEPHONE_VALIDATION, I18n.t('errors.invalidTelephone'))
    .required(I18n.t('login.required')),
  city: Yup.string()
    .matches(CITY_VALIDATION, I18n.t('errors.invalidCity'))
    .required(I18n.t('login.required')),
  individualRealState: Yup.boolean(),
  realState: Yup.boolean(),
  realStateName: Yup.string().when('realState', {
    is: true,
    then: Yup.string().required(I18n.t('login.required')),
    otherwise: Yup.string(),
  }),
})

const ALLOWED_AUTO_COMPLETE_METHODS = ['click', 'enter', 'type']

class Register extends Component {
  constructor() {
    super()
    this.state = {
      suggestions: [],
      realStateName: '',
      cityId: '',
      loading: false,
      modalMessage: false,
    }
  }

  getCitySuggestions = async () => {
    const { city } = this.state
    this.setState({ loading: true })
    const cities = await getCities(city)
    this.setState({ suggestions: cities, loading: false })
  }

  setCityId = (event, { suggestion: { value } }) => {
    console.log('cidadeId', value)
    this.setState({ cityId: value })
  }

  openModalMessage = () => {
    this.setState({ modalMessage: true })
  }

  closeModalMessage = () => {
    this.setState({ modalMessage: false })
  }

  debouncedCitySuggestions = debounce(this.getCitySuggestions, 500)

  render() {
    const { suggestions, cityId, modalMessage, loading } = this.state
    const modalClasses = classNames({
      Register__Pane: modalMessage,
      'Register__Pane--hidden': !modalMessage,
    })
    return (
      <div className="Register">
        <div className="Register__InfoContainer">
          <h2 className="Register__Header">
            {I18n.t('register.surpriseYourself')}
          </h2>
          <h4 className="Register__InfoHeader">
            {I18n.t('register.smartSearch')}
          </h4>
          <p className="Register__Info">{I18n.t('register.find')}</p>
          <h4 className="Register__InfoHeader">
            {I18n.t('register.realtyRating')}
          </h4>
          <p className="Register__Info">{I18n.t('register.do')}</p>
          <h4 className="Register__InfoHeader">
            {I18n.t('register.marketReport')}
          </h4>
          <p className="Register__Info">{I18n.t('register.understand')}</p>
          <Button btnStyle="primary" onClick={() => (window.location = '/')}>
            {I18n.t('register.home')}
          </Button>
        </div>
        <div className="Register__FormContainer">
          <h2 className="Register__Header">{I18n.t('register.register')}</h2>
          <Formik
            onSubmit={(values, actions) =>
              submitRegister(
                { ...values, cityId },
                actions,
                visible => this.setState({ loading: visible }),
                this.openModalMessage,
              )
            }
            validationSchema={RegisterSchema}
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              password: '',
              confirmPassword: '',
              telephone: '',
              city: '',
              individualRealState: true,
              realState: false,
              realStateName: '',
            }}
          >
            {({
              values,
              setFieldValue,
              handleSubmit,
              errors,
              handleChange,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col xs={6}>
                    <Field
                      autoComplete="firstName"
                      name="firstName"
                      value={values.firstName}
                      error={errors.firstName}
                      placeholder={I18n.t('register.firstName')}
                      label=""
                    />
                  </Col>
                  <Col xs={6} className="Register__NoPadLeft">
                    <Field
                      autoComplete="lastName"
                      name="lastName"
                      value={values.lastName}
                      error={errors.lastName}
                      placeholder={I18n.t('register.lastName')}
                      label=""
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Field
                      autoComplete="email"
                      type="email"
                      name="email"
                      value={values.email}
                      error={errors.email}
                      placeholder={I18n.t('login.email')}
                      label=""
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <Field
                      autoComplete="password"
                      type="password"
                      name="password"
                      value={values.password}
                      error={errors.password}
                      placeholder={I18n.t('login.password')}
                      label=""
                    />
                  </Col>
                  <Col xs={6} className="Register__NoPadLeft">
                    <Field
                      autoComplete="confirmPassword"
                      type="password"
                      name="confirmPassword"
                      value={values.confirmPassword}
                      error={errors.confirmPassword}
                      placeholder={I18n.t('register.confirmPassword')}
                      label=""
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <InputMask
                      autoComplete="telephone"
                      name="telephone"
                      type="tel"
                      mask={GET_PHONE_MASK}
                      value={values.telephone}
                      error={errors.telephone}
                      placeholder={I18n.t('register.telephone')}
                      onChange={handleChange}
                      label=""
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <AutoComplete
                      autoComplete="city"
                      name="city"
                      placeholder={I18n.t('register.searchCity')}
                      value={values.city}
                      error={errors.city}
                      suggestions={suggestions}
                      onSuggestionSelected={this.setCityId}
                      onChange={(event, { newValue, method }) => {
                        if (ALLOWED_AUTO_COMPLETE_METHODS.includes(method)) {
                          setFieldValue('city', newValue)
                          this.setState({ city: newValue, cityId: '' }, () =>
                            this.debouncedCitySuggestions(),
                          )
                        }
                      }}
                    />
                  </Col>
                </Row>
                {values.realState && (
                  <Row>
                    <Col xs={12}>
                      <Field
                        autoComplete="realStateName"
                        name="realStateName"
                        placeholder={I18n.t('register.realState')}
                        value={values.realStateName}
                        error={errors.realStateName}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs={12} className="Register__Btn">
                    <Row>
                      <Col xs>
                        <Checkbox
                          reverse
                          name="individualRealState"
                          label={I18n.t('register.individualRealState')}
                          checked={values.individualRealState}
                          onChange={({ target: { checked } }) => {
                            setFieldValue('individualRealState', true)
                            setFieldValue('realState', false)
                          }}
                        />
                      </Col>
                      <Col xs>
                        <Checkbox
                          reverse
                          name="realState"
                          label={I18n.t('components.filters.realState')}
                          checked={values.realState}
                          onChange={({ target: { checked } }) => {
                            setFieldValue('realState', true)
                            setFieldValue('individualRealState', false)
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Button
                      className="Register__Btn"
                      btnStyle="primary"
                      type="submit"
                      btnSize="lg"
                      block
                    >
                      {I18n.t('components.actions.register')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
          <Spinner visible={loading} />
          <div className={modalClasses}>
            <div className="Register__Modal">
              <Row end="xs">
                <Col xs={12}>
                  <i
                    className="material-icons"
                    onClick={this.closeModalMessage}
                  >
                    close
                  </i>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <h1>
                    Nossa equipe entrará em contato pelo telefone para liberar
                    seu período de teste! Em caso de dúvidas, você pode enviar
                    um e-mail para contato@eemovel.com.br
                  </h1>
                </Col>
              </Row>
              <Row center="xs">
                <Col xs={12}>
                  <Button
                    btnStyle="primary"
                    onClick={() => this.props.history.push('/')}
                  >
                    Página inicial
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
          <Chat />
        </div>
      </div>
    )
  }
}

export default Register
