const formatData = data => ({
  Id: data.Id || '',
  PeopleId: data.PessoaId || '',
  Name: data.Nome || '',
  CityId: data.CidadeId || '',
  City: {
    Id: data.cidade.Id || '',
    Name: data.cidade.Nome || '',
    StateID: data.cidade.EstadoId || '',
    State: {
      Id: data.cidade.Estado.Id || '',
      Name: data.cidade.Estado.Nome || '',
      UF: data.cidade.Estado.UF || '',
      Cities: data.cidade.Estado.Cidades || '',
    },
    LatitudeCentralPoint: data.cidade.PontoCentralLatitude || '',
    LongitudeCentralPoint: data.cidade.PontoCentralLongitude || '',
    BoundBox: data.cidade.BoundBox || '',
  },
  AdDate: data.DataInclusao || '',
  AlertDate: data.DataAlerta || '',
  BussinessTypeCode: data.TipoNegocio || '',
  RealtyType: data.TipoImovel || '',
  BusinessType: data.TipoNegocioNome || '',
  Categories: data.TipoImovelNome || '',
  PolygonPoint: data.PontosPoligono || '',
  MultiPolygonPoint: data.PontosMultiPoligono || '',
  MaxPrice: data.ValorMaximo || '',
  MinPrice: data.ValorMinimo || '',
  Bedroom: data.Quarto || '',
  Suit: data.Suite || '',
  Garage: data.Garagem || '',
  MinArea: data.AreaMinima || '',
  MaxArea: data.AreaMaxima || '',
  Building: data.Edificio || null,
  RealStateId: data.ImobiliariaId || null,
  Active: data.Ativo || null,
  Alerted: data.Alertado || '',
  Deleted: data.Excluido || '',
  Readed: data.Lido || '',
  NeighborhoodId: data.BairroId || '',
  ZoningId: data.ZoneamentoId || '',
  Address: data.Endereco || '',
  Optionals: data.OpcionaisIds || [],
  CategoriesIds: data.TipoImovelIds || '',
  PrivateBusiness: data.Particular || '',
})

export const formatAlerts = alertArray =>
  alertArray.map(alert => formatData(alert))
