import React, { Component } from 'react'
import { deburr } from 'lodash'
import './index.css'

class FilterNeighborhoodMobile extends Component {
  constructor() {
    super()
    this.state = {
      active: false,
      neighborhoods: [],
    }
    this.neighborhoods = undefined
  }

  componentDidMount(prevProps, prevState) {
    this.setNeighborhoods(this.props.loadedNeighborhoods)
    this.props.setSearching(true)
    setTimeout(() => this.input.focus(), 500)
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  setNeighborhoods = neighborhoods => {
    this.setState({ neighborhoods })
  }

  render() {
    const { setSearching } = this.props
    const { neighborhoods } = this.state
    return (
      <div className="FilterNeighborhoodMobile">
        <div className="FilterNeighborhoodMobile__Container">
          <div>
            <input
              className="FilterNeighborhoodMobile__Input"
              placeholder="Pesquise aqui"
              ref={el => (this.input = el)}
              onFocus={() => setSearching(true)}
              onBlur={() => setSearching(false)}
              onChange={({ target: { value } }) => {
                this.setState({
                  neighborhoods: this.props.loadedNeighborhoods.filter(
                    ({ name }) =>
                      deburr(name.toLowerCase()).includes(
                        deburr(value.toLowerCase()),
                      ),
                  ),
                })
              }}
            />
          </div>
          <div className="FilterNeighborhoodMobile__ScrollContainer">
            {neighborhoods.length > 0 &&
              neighborhoods.map(({ id, name, path }) => (
                <div
                  className="FilterNeighborhoodMobile__Option"
                  key={id}
                  onClick={() => {
                    this.props.clearClusters()
                    this.props.mapService.addPolygonFromPath({ path })
                    this.props.onSearch()
                  }}
                >
                  {name}
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  }
}

export default FilterNeighborhoodMobile
