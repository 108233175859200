import React from 'react'
import classNames from 'classnames'
import { Formik } from 'formik'
import I18n from 'locales'
import { Col, Row } from 'components/Grid'
import { Button } from 'components/Buttons'
import {
  getHouseAsArray,
  getApartmentAsArray,
  getComercialAsArray,
  getOthersAsArray,
  getAllCategoriesAsArray,
} from 'utils/category'
import {
  renderMacroCheckbox,
  renderMicroCheckboxes,
} from 'components/Search/FilterCategory/FilterCategoryUtils'
import './index.css'

const house = getHouseAsArray()
const apartment = getApartmentAsArray()
const comercial = getComercialAsArray()
const others = getOthersAsArray()
const all = getAllCategoriesAsArray()

const FilterCategory = ({
  className,
  initialValues = {},
  onSubmit,
  onCancel,
}) => {
  const classes = classNames('SearchFilter', 'FilterCategoryMobile', className)
  return (
    <div className={classes}>
      <Formik
        onSubmit={values => onSubmit({ category: values })}
        initialValues={{ ...initialValues }}
      >
        {({ values, ...props }) => (
          <form
            onSubmit={props.handleSubmit}
            className="FilterCategoryMobile__Form"
          >
            <div className="FilterCategoryMobile__OptionContainer">
              {renderMacroCheckbox(
                'allFilter',
                I18n.t('components.filters.all'),
                props,
                all,
                values,
                true,
              )}
              {renderMacroCheckbox(
                'apartmentFilter',
                I18n.t('components.filters.apartment'),
                props,
                apartment,
                values,
                true,
              )}
              {renderMicroCheckboxes(apartment, props, values, true)}
              {renderMacroCheckbox(
                'houseFilter',
                I18n.t('components.filters.houses'),
                props,
                house,
                values,
                true,
              )}
              {renderMicroCheckboxes(house, props, values, true)}
              {renderMacroCheckbox(
                'comercialFilter',
                I18n.t('components.filters.comercial'),
                props,
                comercial,
                values,
                true,
              )}
              {renderMicroCheckboxes(comercial, props, values, true)}
              {renderMacroCheckbox(
                'othersFilter',
                I18n.t('components.filters.others'),
                props,
                others,
                values,
                true,
              )}
              {renderMicroCheckboxes(others, props, values, true)}
            </div>
            <Row between="xs" className="FilterCategoryMobile__ActionContainer">
              <Col>
                <Button
                  className="FilterPriceRange__Popover__Cancel"
                  btnStyle="link"
                  onClick={onCancel}
                >
                  {I18n.t('components.actions.cancel')}
                </Button>
              </Col>
              <Col>
                <Button btnStyle="link" type="submit">
                  {I18n.t('components.actions.ok')}
                </Button>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default FilterCategory
