import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Switch, Route, Redirect } from 'react-router'
import I18n from 'locales'
import Search from 'pages/Search'
import MobileSearch from 'pages/MobileSearch'
import Preference from 'pages/Preference'
import EvaluationReport from 'pages/EvaluationReport'
import MobilePreference from 'pages/MobilePreference'
import Login from 'pages/Login'
import Register from 'pages/Register'
import Report from 'pages/Report'
import OurTools from 'pages/OurTools'
import WhereWeAct from 'pages/WhereWeAct'
import Evaluation from 'pages/Evaluation'
import UnionTransition from 'pages/UnionTransition'
import PrivateRoute from 'components/PrivateRoute'
import { verifyValidity } from 'services/auth'
import './index.css'

class App extends Component {
  componentDidMount() {
    verifyValidity()
    setInterval(verifyValidity, 900000)
  }

  render() {
    const isMobile = window.innerWidth <= 800
    return (
      <React.Fragment>
        <BrowserRouter>
          <main className="App">
            <Switch>
              <Route exact path="/" component={OurTools} />
              <Route exact path={I18n.t('routes.login')} component={Login} />
              <Route
                exact
                path={I18n.t('routes.partner')}
                component={UnionTransition}
              />
              <Route
                exact
                path={I18n.t('routes.register')}
                component={Register}
              />
              <Route
                exact
                path={I18n.t('routes.whereWeAct')}
                component={WhereWeAct}
              />
              <Route
                exact
                path={I18n.t('routes.evaluationRgi')}
                component={isMobile ? EvaluationReport : EvaluationReport}
              />
              <PrivateRoute
                exact
                path={I18n.t('routes.search')}
                component={isMobile ? MobileSearch : Search}
              />
              <PrivateRoute
                exact
                path={I18n.t('routes.rgi')}
                component={Report}
              />
              <PrivateRoute
                exact
                path={I18n.t('routes.preferences')}
                component={isMobile ? MobilePreference : Preference}
              />
              <PrivateRoute
                exact
                path={I18n.t('routes.evaluation')}
                component={Evaluation}
              />
              <PrivateRoute
                exact
                path={I18n.t('routes.evaluationRgi')}
                component={EvaluationReport}
              />
              <PrivateRoute
                exact
                path={I18n.t('routes.preferences')}
                component={isMobile ? MobilePreference : Preference}
              />
              <Redirect to="/" />
            </Switch>
          </main>
        </BrowserRouter>
      </React.Fragment>
    )
  }
}

export default App
