import React from 'react'
import { Button } from 'components/Buttons'
import I18n from 'locales'
import Alert from 'components/Alert'
import './index.css'

const AlertContainer = ({ onToggle, visible }) => {
  const status = visible ? '--open' : '--close'
  const headerClasses = `AlertContainer__Header AlertContainer__Header${status}`
  const containerClasses = `AlertContainer AlertContainer${status}`
  return (
    <React.Fragment>
      <div className={headerClasses}>
        <div className="AlertContainer__Header__ActionContainer">
          <div className="AlertContainer__Header__CloseIcon">
            <Button
              btnStyle="link"
              className="AlertContainer__Btn"
              onClick={onToggle}
            >
              <i className="material-icons AlertContainer__BackArrow">
                arrow_back_ios
              </i>
              {I18n.t('components.filters.map')}
            </Button>
          </div>
        </div>
      </div>
      <div className={containerClasses}>
        <Alert onCancel={onToggle} mobile />
      </div>
    </React.Fragment>
  )
}

export default AlertContainer
