export const HOUSE = {
  house: { name: 'Casa', value: 2, prettyName: 'Casa Padrão', type: 'Casa' },
  houseLoft: { name: 'Sobrado', value: 4, prettyName: 'Sobrado', type: 'Casa' },
  semiDetachedHouse: {
    name: 'CasaGeminada',
    value: 13,
    prettyName: 'Casa Geminada',
    type: 'Casa',
  },
  condoHouse: {
    name: 'CasaCondominio',
    value: 14,
    prettyName: 'Casa em Condomínio',
    type: 'Casa',
  },
  semiDetachedHouseLoft: {
    name: 'SobradoGeminado',
    value: 15,
    prettyName: 'Sobrado Geminado',
    type: 'Casa',
  },
  condoLoft: {
    name: 'SobradoCondominio',
    value: 16,
    prettyName: 'Sobrado em Condomínio',
    type: 'Casa',
  },
}

export const APARTMENT = {
  apartment: {
    name: 'Apartamento',
    value: 1,
    prettyName: 'Apartamento Padrão',
    type: 'Apartamento',
  },
  kitchenette: {
    name: 'Quitinete',
    value: 7,
    prettyName: 'Quitinete',
    type: 'Apartamento',
  },
  studio: {
    name: 'Studio',
    value: 8,
    prettyName: 'Studio',
    type: 'Apartamento',
  },
  duplex: {
    name: 'Duplex',
    value: 9,
    prettyName: 'Duplex',
    type: 'Apartamento',
  },
  triplex: {
    name: 'Triplex',
    value: 10,
    prettyName: 'Triplex',
    type: 'Apartamento',
  },
  flat: { name: 'Flat', value: 11, prettyName: 'Flat', type: 'Apartamento' },
  penthouse: {
    name: 'Cobertura',
    value: 22,
    prettyName: 'Cobertura',
    type: 'Apartamento',
  },
  loft: { name: 'Loft', value: 12, prettyName: 'Loft', type: 'Apartamento' },
}

export const COMERCIAL = {
  comercialRoom: {
    name: 'SalaComercial',
    value: 5,
    prettyName: 'Sala Comercial',
    type: 'Comercial',
  },
  store: { name: 'Loja', value: 18, prettyName: 'Loja', type: 'Comercial' },
  housing: {
    name: 'Conjunto',
    value: 19,
    prettyName: 'Conjunto',
    type: 'Comercial',
  },
  slab: { name: 'Laje', value: 20, prettyName: 'Laje', type: 'Comercial' },
  mezzanine: {
    name: 'Sobreloja',
    value: 21,
    prettyName: 'Sobreloja',
    type: 'Comercial',
  },
  comercialHome: {
    name: 'CasaComercial',
    value: 23,
    prettyName: 'Casa Comercial',
    type: 'Comercial',
  },
  comercialSpot: {
    name: 'Ponto',
    value: 17,
    prettyName: 'Ponto',
    type: 'Comercial',
  },
}

export const CUSTOM = {
  store: { name: 'Loja', value: 18, prettyName: 'Loja', type: 'Comercial' },
  comercialHome: {
    name: 'CasaComercial',
    value: 23,
    prettyName: 'Casa Comercial',
    type: 'Comercial',
  },
  comercialSpot: {
    name: 'Ponto',
    value: 17,
    prettyName: 'Ponto',
    type: 'Comercial',
  },
}

export const OTHERS = {
  terrain: { name: 'Terreno', value: 3, prettyName: 'Terreno', type: 'Outros' },
  warehouse: {
    name: 'Barracao',
    value: 6,
    prettyName: 'Galpão',
    type: 'Outros',
  },
}

export const ALL = { ...HOUSE, ...APARTMENT, ...COMERCIAL, ...OTHERS }
