import React from 'react'
import classNames from 'classnames'
import I18n from 'locales'
import { userInfo } from 'services/auth'
import './index.css'

const Sidebar = ({ hash }) => {
  // const initialClasses = classNames('Sidebar__Item', {
  //   title: 'Busca',
  //   'Sidebar__Item--active': hash === I18n.t('routes.preferenceInitial'),
  // })
  const alertClasses = classNames('Sidebar__Item', {
    'Sidebar__Item--active': hash === I18n.t('routes.preferenceAlert'),
  })
  const generalDataClasses = classNames('Sidebar__Item', {
    'Sidebar__Item--active': hash === I18n.t('routes.preferenceGeneralData'),
  })
  const accessDataClasses = classNames('Sidebar__Item', {
    'Sidebar__Item--active': hash === I18n.t('routes.preferenceAccessData'),
  })
  const plansClasses = classNames('Sidebar__Item', {
    'Sidebar__Item--active': hash === I18n.t('routes.preferencePlans'),
  })
  const accountsClasses = classNames('Sidebar__Item', {
    'Sidebar__Item--active': hash === I18n.t('routes.preferenceAccounts'),
  })
  const usersClasses = classNames('Sidebar__Item', {
    'Sidebar__Item--active': hash === I18n.t('routes.preferenceUsers'),
  })
  return (
    <div className="Sidebar">
      {/* <a className={initialClasses} href={I18n.t('routes.preferenceInitial')}>
        {I18n.t('sidebar.initial')}
      </a> */}
      <a
        className={generalDataClasses}
        href={I18n.t('routes.preferenceGeneralData')}
      >
        {I18n.t('sidebar.generalData')}
      </a>
      <a
        className={accessDataClasses}
        href={I18n.t('routes.preferenceAccessData')}
      >
        {I18n.t('sidebar.accessData')}
      </a>
      <a className={plansClasses} href={I18n.t('routes.preferencePlans')}>
        {I18n.t('sidebar.plans')}
      </a>
      {(userInfo().roles.includes('Manager') ||
        userInfo().profileType === 3) && (
        <React.Fragment>
          <a
            className={accountsClasses}
            href={I18n.t('routes.preferenceAccounts')}
          >
            {I18n.t('sidebar.accounts')}
          </a>
        </React.Fragment>
      )}
      {(userInfo().roles.includes('Admin') ||
        userInfo().roles.includes('Sales') ||
        userInfo().roles.includes('Staff')) && (
        <React.Fragment>
          <a className={usersClasses} href={I18n.t('routes.preferenceUsers')}>
            {I18n.t('sidebar.users')}
          </a>
        </React.Fragment>
      )}
      {userInfo().userId !== 14801 && (
        <a className={alertClasses} href={I18n.t('routes.preferenceAlert')}>
          {I18n.t('sidebar.alert')}
        </a>
      )}
    </div>
  )
}

const SidebarMobile = ({ hash }) => {
  // const initialClasses = classNames('SidebarMobile__Item', {
  //   'SidebarMobile__Item--active': hash === I18n.t('routes.preferenceInitial'),
  // })
  const alertClasses = classNames('Sidebar__Item', {
    'SidebarMobile__Item--active': hash === I18n.t('routes.preferenceAlert'),
  })
  const generalDataClasses = classNames('Sidebar__Item', {
    'SidebarMobile__Item--active':
      hash === I18n.t('routes.preferenceGeneralData'),
  })
  const accessDataClasses = classNames('Sidebar__Item', {
    'SidebarMobile__Item--active':
      hash === I18n.t('routes.preferenceAccessData'),
  })
  const plansClasses = classNames('Sidebar__Item', {
    'SidebarMobile__Item--active': hash === I18n.t('routes.preferencePlans'),
  })
  const accountsClasses = classNames('Sidebar__Item', {
    'SidebarMobile__Item--active': hash === I18n.t('routes.preferenceAccounts'),
  })
  const usersClasses = classNames('Sidebar__Item', {
    'Sidebar__Item--active': hash === I18n.t('routes.preferenceUsers'),
  })
  return (
    <div className="SidebarMobile">
      {/* <a className={initialClasses} href={I18n.t('routes.preferenceInitial')}>
        <i className="material-icons">home</i>
      </a> */}
      <a
        className={generalDataClasses}
        href={I18n.t('routes.preferenceGeneralData')}
      >
        <i className="material-icons">settings</i>
      </a>
      <a
        className={accessDataClasses}
        href={I18n.t('routes.preferenceAccessData')}
      >
        <i className="material-icons">vpn_key</i>
      </a>
      <a className={plansClasses} href={I18n.t('routes.preferencePlans')}>
        <i className="material-icons">view_carousel</i>
      </a>
      {(userInfo().roles.includes('Manager') ||
        userInfo().profileType === 3) && (
        <React.Fragment>
          <a
            className={accountsClasses}
            href={I18n.t('routes.preferenceAccounts')}
          >
            <i className="material-icons">supervisor_account</i>
          </a>
        </React.Fragment>
      )}
      {(userInfo().roles.includes('Admin') ||
        userInfo().roles.includes('Sales') ||
        userInfo().roles.includes('Staff')) && (
        <React.Fragment>
          <a className={usersClasses} href={I18n.t('routes.preferencesUsers')}>
            <i className="material-icons">person</i>
          </a>
        </React.Fragment>
      )}
      {userInfo().userId !== 14801 && (
        <a className={alertClasses} href={I18n.t('routes.preferenceAlert')}>
          <i className="material-icons">notifications</i>
        </a>
      )}
    </div>
  )
}

export default (window.innerWidth < 768 ? SidebarMobile : Sidebar)
