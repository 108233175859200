import React, { Component } from 'react'
import Header from 'components/MobileHeader'
import Sidebar from 'components/Sidebar'
import AlertTable from 'components/AlertTable'
import GeneralData from 'components/GeneralData'
import AccessData from 'components/AccessData'
import Accounts from 'components/Accounts'
import Plans from 'components/Plans'
import { userInfo } from 'services/auth'
import { getPaymentStatus } from 'services/user'
import { getAlerts } from 'services/alert'
import { callRequest } from 'services/requestHandler'
import I18n from 'locales'
import Chat from 'components/Chat'
import './index.css'

class Preference extends Component {
  constructor() {
    super()
    this.user = userInfo()
    this.state = {
      alerts: [],
    }
  }

  componentDidMount() {
    getPaymentStatus()
    callRequest(getAlerts, this.setAlerts)
  }

  setAlerts = alerts => {
    this.setState({ alerts })
  }

  renderContent() {
    const { hash } = this.props.location
    const { alerts } = this.state
    switch (hash) {
      case I18n.t('routes.preferenceInitial'):
        return <h1>Ops! Nada aqui ainda :(</h1>
      case I18n.t('routes.preferenceAlert'):
        return <AlertTable alerts={alerts} />
      case I18n.t('routes.preferenceGeneralData'):
        return <GeneralData user={this.user} mobile />
      case I18n.t('routes.preferenceAccessData'):
        return <AccessData user={this.user} mobile />
      case I18n.t('routes.preferenceAccounts'):
        if (this.user.profileType === 3) {
          return <Accounts user={this.user} />
        } else {
          return <GeneralData user={this.user} />
        }
      case I18n.t('routes.preferencePlans'):
        return <Plans />
      default:
        // return <h1>Ops! Nada aqui ainda :(</h1>
        return <GeneralData user={this.user} mobile />
    }
  }

  renderHeader() {
    const { hash } = this.props.location
    switch (hash) {
      case I18n.t('routes.preferenceInitial'):
        return (
          <h1 className="Preference__Header">
            Bem vindo, {this.user.username}
          </h1>
        )
      case I18n.t('routes.preferenceAlert'):
        return <h1 className="Preference__Header">{I18n.t('alert.alerts')}</h1>
      case I18n.t('routes.preferenceGeneralData'):
        return (
          <h1 className="Preference__Header">{I18n.t('generalData.header')}</h1>
        )
      case I18n.t('routes.preferenceAccessData'):
        return (
          <h1 className="Preference__Header">{I18n.t('accessData.header')}</h1>
        )
      case I18n.t('routes.preferencePlans'):
        return <h1 className="Preference__Header">{I18n.t('plans.plans')}</h1>
      default:
        return (
          <h1 className="Preference__Header">{I18n.t('generalData.header')}</h1>
        )
      // return (
      //   <h1 className="Preference__Header">
      //     Bem vindo, {this.user.username}
      //   </h1>
      // )
    }
  }

  render() {
    const { hash } = this.props.location
    return (
      <React.Fragment>
        <Header />
        <Sidebar hash={hash} />
        <div className="PreferenceMobile__Container">
          {this.renderHeader()}
          {/* <span className="Preference__Info">
            Um texto explicativo de explicações explicadas
          </span> */}
          <div className="PreferenceMobile__Content">
            {this.renderContent()}
          </div>
        </div>
        <Chat />
      </React.Fragment>
    )
  }
}

export default Preference
