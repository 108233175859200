import React, { Fragment, Component } from 'react'
import { Grid, Row, Col } from 'components/Grid'
import { Field, Formik, InputMask, Form, Checkbox } from 'components/Form'
import { validateYupSchema, yupToFormErrors } from 'formik'
import {
  BETWEEN_CPF_CNPJ,
  CPF_CNPJ_VALIDATION,
  CEP_MASK,
  CEP_VALIDATION,
  CARD_MASK,
  CARD_VALIDATION,
  EXPIRE_CARD_MASK,
  EXPIRE_CARD_VALIDATION,
} from 'utils/mask'
import { downloadContract } from 'services/user'
import { getAddress } from 'services/location'
import { Button } from 'components/Buttons'
import * as Yup from 'yup'
import i18n from 'locales'
import Contract from 'components/Contract'
import Spinner from 'components/Spinner'
import './index.css'
import { getDiscount, checkout, arrivedAtCheckout } from 'services/payment'
import { callRequest } from 'services/requestHandler'
import { userId } from 'services/auth'

const UserInformationSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(6, i18n.t('login.tooShort'))
    .required(i18n.t('login.required')),
  cpfCnpj: Yup.string()
    .matches(CPF_CNPJ_VALIDATION, i18n.t('errors.invalidCpfCnpj'))
    .required(i18n.t('login.required')),
})

const BillingInformationSchema = Yup.object().shape({
  cep: Yup.string()
    .matches(CEP_VALIDATION, i18n.t('errors.zipCodeNotFound'))
    .required(i18n.t('login.required')),
  uf: Yup.string().required(i18n.t('login.required')),
  city: Yup.string().required(i18n.t('login.required')),
  address: Yup.string().required(i18n.t('login.required')),
  number: Yup.string().required(i18n.t('login.required')),
  neighborhood: Yup.string().required(i18n.t('login.required')),
})

const PaymentInformationSchema = Yup.object().shape({
  cardName: Yup.string()
    .min(6, i18n.t('login.tooShort'))
    .required(i18n.t('login.required')),
  cardNumber: Yup.string()
    .matches(CARD_VALIDATION, i18n.t('errors.invalidNumber'))
    .min(19, i18n.t('errors.invalidNumber'))
    .max(19, i18n.t('errors.invalidNumber'))
    .required(i18n.t('login.required')),
  code: Yup.string()
    .min(3, i18n.t('errors.invalid'))
    .max(4, i18n.t('errors.invalid'))
    .required(i18n.t('login.required')),
  expireDate: Yup.string()
    .matches(EXPIRE_CARD_VALIDATION, i18n.t('errors.invalidDate'))
    .required(i18n.t('login.required')),
})

const PLANS = {
  starter: {
    name: 'Starter',
    price: 279.9,
    id: 35,
    integration: 'metropolitana_sp_start',
  },
  pro: {
    name: 'Pro',
    price: 399.9,
    id: 36,
    integration: 'metropolitana_sp_pro',
  },
  enterprise: {
    name: 'Enterprise',
    price: 599.9,
    id: 37,
    integration: 'metropolitana_sp_enterprise',
  },
}

class Plans extends Component {
  constructor() {
    super()
    this.state = {
      selectedPlan: '',
      userInformation: {
        fullName: '',
        cpfCnpj: '',
      },
      billingInformation: {
        cep: '',
        uf: '',
        city: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
      },
      paymentInformation: {
        cardName: '',
        cardNumber: '',
        code: '',
        expireDate: '',
      },
      ticket: false,
      card: false,
      userInformed: false,
      billingInformed: false,
      paymentInformed: false,
      termsAccepted: false,
      addressInformation: undefined,
      loading: false,
      discountPercentage: '',
      discountCode: '',
      cardToken: '',
    }
    this.Iugu = window.Iugu
  }

  componentDidMount() {
    this.Iugu.setAccountID(process.env.REACT_APP_IUGU_ACCOUNT_ID)
  }

  selectedPlan = plan => {
    this.setState(state => ({ ...state, selectedPlan: plan }))
    arrivedAtCheckout()
  }

  clearPlan = () => {
    this.setState(state => ({ ...state, selectedPlan: '' }))
  }

  clearUser = () => {
    this.setState(state => ({ ...state, userInformed: false }))
  }

  clearPayment = () => {
    this.setState(state => ({ ...state, paymentInformed: false }))
  }

  clearDiscount = setFieldValue => {
    this.setState(state => ({
      ...state,
      discountPercentage: '',
      discountCode: '',
    }))
    setFieldValue('discountCode', '')
  }

  downloadContract = () => {
    const { selectedPlan, discountPercentage } = this.state
    const { fullName, cpfCnpj } = this.state.userInformation
    const {
      address,
      number,
      uf,
      city,
      neighborhood,
      cep,
    } = this.state.billingInformation

    downloadContract({
      Nome: fullName,
      Documento: cpfCnpj,
      Logradouro: `${address}, ${number}`,
      Bairro: neighborhood,
      Cidade: city,
      Estado: uf,
      CEP: cep,
      SubscriptionId: PLANS[selectedPlan].id,
      Discount: discountPercentage
        ? ((PLANS[selectedPlan].price * discountPercentage) / 100).toFixed(2)
        : 0,
      Price: PLANS[selectedPlan].price,
    }).then(({ request }) => {
      request.onloadend = () => {
        if (request.status === 200) {
          let link = document.createElement('a')
          document.body.appendChild(link)
          link.download = 'contrato_de_prestacao_de_servico.pdf'
          link.href = window.URL.createObjectURL(request.response)
          link.click()
        }
      }
    })
  }

  getAddress = (setFieldValue, setFieldError, submitForm) => {
    const { cep } = this.state.billingInformation
    this.setState(state => ({ ...state, loading: true }))
    const failure = () => {
      setFieldValue('address', '')
      setFieldValue('city', '')
      setFieldValue('uf', '')
      setFieldValue('neighborhood', '')
      this.setState(state => ({
        ...state,
        addressInformation: undefined,
        billingInformation: {
          cep: '',
          uf: '',
          city: '',
          address: '',
          number: '',
          complement: '',
          neighborhood: '',
        },
        hasAddress: false,
        loading: false,
      }))
      setFieldError('cep', i18n.t('errors.zipCodeNotFound'))
    }
    callRequest(
      () => getAddress(cep),
      data => {
        this.setState(state => ({
          ...state,
          addressInformation: data,
          addressSuccess: true,
          hasAddress: true,
          billingInformation: {
            ...state.billingInformation,
            address: data.logradouro,
            city: data.localidade,
            uf: data.uf,
            neighborhood: data.bairro,
            number: '',
            complement: '',
          },
          loading: false,
        }))
        setFieldValue('address', data.logradouro)
        setFieldValue('city', data.localidade)
        setFieldValue('uf', data.uf)
        setFieldValue('neighborhood', data.bairro)
        submitForm()
      },
      failure,
      failure,
    )
  }

  handleSelectTicket = () => {
    this.setState(state => ({
      ...state,
      ticket: true,
      card: false,
      cardToken: '',
      paymentInformed: true,
    }))
  }

  handleSelectCard = async () => {
    await this.setState(state => ({
      ...state,
      ticket: false,
      card: true,
      paymentInformed: false,
    }))
  }

  handleAcceptTerms = () => {
    this.setState(state => ({ ...state, termsAccepted: !state.termsAccepted }))
  }

  handleUserInformationChange = async (setFieldValue, name, value) => {
    await setFieldValue(name, value)
    this.setState(state => ({
      ...state,
      userInformation: { ...state.userInformation, [name]: value },
    }))
  }

  handleBillingInformationChange = async (setFieldValue, name, value) => {
    await setFieldValue(name, value)
    this.setState(state => ({
      ...state,
      billingInformation: { ...state.billingInformation, [name]: value },
    }))
  }

  handlePaymentInformationChange = async (setFieldValue, name, value) => {
    await setFieldValue(name, value)
    this.setState(state => ({
      ...state,
      paymentInformation: { ...state.paymentInformation, [name]: value },
    }))
  }

  handleInformUser = () => {
    this.setState(state => ({
      ...state,
      userInformed: true,
    }))
  }

  handleInformBilling = () => {
    this.setState(state => ({
      ...state,
      billingInformed: true,
    }))
  }

  handleInformPayment = async (values, actions) => {
    const { card } = this.state

    if (card) {
      const {
        cardNumber,
        cardName,
        code,
        expireDate,
      } = this.state.paymentInformation
      let creditCard = this.Iugu.CreditCard(
        cardNumber,
        expireDate.split('/')[0],
        `20${expireDate.split('/')[1]}`,
        cardName,
        cardName,
        code,
      )

      const { valid, errors } = this.validateCreditCard()

      if (valid) {
        this.setState({ loading: true })
        this.Iugu.createPaymentToken(creditCard, response => {
          if (!response.errors) {
            this.setState(state => ({
              ...state,
              cardToken: response.id,
              paymentInformed: true,
              loading: false,
            }))
          } else {
            const { errors } = response
            console.log(errors)
          }
        })
      } else {
        window.M.toast({
          classes: 'Toast--failure',
          html: `As seguintes informações são inválidas: ${errors}`,
        })
      }
    }
  }

  validateCreditCard = () => {
    const { cardNumber, code, expireDate } = this.state.paymentInformation
    const numberValid = this.Iugu.utils.validateCreditCardNumber(cardNumber)
    const expirationValid = this.Iugu.utils.validateExpirationString(expireDate)
    const errors = []
    let valid = true

    if (numberValid && expirationValid) {
      const cardBrand = this.Iugu.utils.getBrandByCreditCardNumber(cardNumber)
      const codeValid = this.Iugu.utils.validateCVV(code, cardBrand)

      if (!codeValid) {
        valid = false
        errors.push('Código de segurança')
      }
    } else {
      valid = false
    }

    if (!numberValid) errors.push('Numero do cartão')
    if (!expirationValid) errors.push('Data de validade')

    return { valid, errors: errors.toString().replace(/,/g, ', ') }
  }

  handleDiscount = ({ discountCode }, { setFieldError }) => {
    getDiscount(discountCode)
      .then(({ data, status }) => {
        if (status === 200 && data) {
          this.setState(state => ({
            discountPercentage: data.ValorDesconto,
            discountCode,
          }))
        } else {
          setFieldError('discountCode', i18n.t('errors.invalidCoupon'))
        }
      })
      .catch(err => {
        setFieldError('discountCode', i18n.t('errors.invalidCoupon'))
        console.log(err)
      })
  }

  finalizeCheckout = async () => {
    const {
      selectedPlan,
      discountCode,
      card,
      billingInformation,
      userInformation,
      cardToken,
    } = this.state

    const paymentMethod = card
      ? {
          Tipo: 'credit_card',
          Token: cardToken,
        }
      : {
          Tipo: 'bank_slip',
          Token: null,
        }

    const payload = {
      AssinaturaId: PLANS[selectedPlan].id,
      CodigoIntegracao: PLANS[selectedPlan].integration,
      CodigoPromocional: discountCode,
      MetodoPagamento: paymentMethod,
      PessoaId: userId(),
      QuantidadeCobrança: 1,
      QuantidadeContas: 1,
      QuantidadeParcelas: 1,
      Titular: {
        Bairro: billingInformation.neighborhood,
        CEP: billingInformation.cep,
        Cidade: billingInformation.city,
        Complemento: billingInformation.complement,
        CpfCnpj:
          userInformation.cpfCnpj.length === 14
            ? userInformation.cpfCnpj
                .replace('.', '')
                .replace('.', '')
                .replace('-', '')
            : userInformation.cpfCnpj
                .replace('.', '')
                .replace('.', '')
                .replace('/', '')
                .replace('-', ''),
        Email: '',
        Estado: billingInformation.uf,
        Nome: userInformation.fullName,
        Numero: billingInformation.number,
        Rua: billingInformation.address,
      },
    }
    this.setState(state => ({ ...state, loading: true }))
    checkout(payload)
      .then(data => {
        if (data.status === 200) {
          if (card) {
            window.location = i18n.t('routes.search')
          } else {
            data.request.onloadend = () => {
              const response = JSON.parse(data.request.response)
              const message = JSON.parse(response.Mensagem)
              window.open(message.fatura[0].secure_url + '.pdf', '_tab')
              window.location = i18n.t('routes.search')
            }
          }
          this.setState(state => ({ ...state, loading: false }))
        }
      })
      .catch(err => console.log(err))
  }

  validateForm = values => {
    let errors = {}
    try {
      validateYupSchema(values, PaymentInformationSchema, true)
    } catch (err) {
      errors = yupToFormErrors(err)
    }

    const expiration = values.expireDate.split('/')
    const currentMonth = new Date().getMonth() + 1
    const currentYear = new Date().getFullYear() - 2000
    const expirationMonth =
      expiration.length === 2 ? Number(expiration[0]) : null
    const expirationYear =
      expiration.length === 2 ? Number(expiration[1]) : null

    if (expirationMonth && expirationYear) {
      const valid =
        expirationYear < currentYear ||
        (currentYear === expirationYear && expirationMonth < currentMonth) ||
        expirationMonth > 12

      if (valid)
        errors = { ...errors, expireDate: i18n.t('errors.invalidDate') }
      else delete errors.expireDate
    }

    return errors
  }

  renderOption = (title, alertNotIncluded) => (
    <Row center="xs" className="Plans__Option">
      <Col xs={12}>
        <p className={alertNotIncluded ? 'Plans__NotIncluded' : ''}>{title}</p>
      </Col>
    </Row>
  )

  renderPlan = (
    plan,
    price,
    alertNotIncluded = false,
    privateBussiness = false,
  ) => {
    let citiesAvailable = 'plans.oneCitySearch'
    let searchesAvailable = 'plans.twentySearches'
    switch (plan) {
      case 'Pro':
        citiesAvailable = 'plans.threeCitiesSearch'
        searchesAvailable = 'plans.fortySearches'
        break
      case 'Enterprise':
        citiesAvailable = 'plans.allCitiesSearch'
        searchesAvailable = 'plans.hundredSearches'
        break
      default:
        break
    }
    return (
      <Col xs={12} md={4}>
        <div className="Plans__Container">
          <Row center="xs" className="Plans__Header">
            <Col xs={12}>
              <h1>{plan}</h1>
            </Col>
          </Row>
          <Row center="xs" className="Plans__Price">
            <Col xs={12}>
              <h2>R$ {price}</h2>
            </Col>
          </Row>
          {this.renderOption(i18n.t('plans.smartSearch'))}
          {this.renderOption(i18n.t('plans.evaluation'))}
          {this.renderOption(i18n.t('plans.captationAlerts'), alertNotIncluded)}
          {this.renderOption(i18n.t('plans.rgi'))}
          {this.renderOption(i18n.t(citiesAvailable))}
          {this.renderOption(i18n.t(searchesAvailable))}
          {this.renderOption(i18n.t('plans.adMetrics'))}
          {this.renderOption(
            privateBussiness ? i18n.t('plans.privateFilter') : '',
          )}
          <Row center="xs">
            <Col xs={12}>
              <Button
                btnStyle="primary"
                block
                onClick={() => this.selectedPlan(plan.toLowerCase())}
              >
                {i18n.t('components.actions.sign')}
              </Button>
            </Col>
          </Row>
        </div>
      </Col>
    )
  }

  renderPrice = price => {
    const { discountPercentage } = this.state
    let newPrice = price

    if (discountPercentage) {
      newPrice = price - (price * discountPercentage) / 100
    }

    return newPrice.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }

  renderCheckout = () => {
    const {
      selectedPlan,
      userInformed,
      paymentInformed,
      discountPercentage,
      discountCode,
    } = this.state
    return (
      <Fragment>
        <Row center="xs">
          <Col md={6} xs={12} className="Plans__CheckoutContainer">
            <Row>
              <Col xs={12} md={10}>
                <Row start="xs">
                  <Col xs={12} className="Plans__CheckoutHeader">
                    {i18n.t('plans.selectedPlan')}
                  </Col>
                </Row>
                <Row start="xs">
                  <Col xs={12} className="Plans__Info">
                    {PLANS[selectedPlan].name}
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={2}>
                <Row start="xs" end="md">
                  <Col xs={12} className="Plans__CheckoutHeader">
                    {i18n.t('alert.value')}
                  </Col>
                </Row>
                <Row start="xs" end="md">
                  <Col xs={12} className="Plans__Info">
                    R$ {this.renderPrice(PLANS[selectedPlan].price)}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row center="xs">
          <Col xs={12} md={6} className="Plans__NoPad">
            <Row start="xs">
              <Col xs={12}>
                <Formik
                  onSubmit={this.handleDiscount}
                  initialValues={{ discountCode }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    errors,
                    setFieldValue,
                    values,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <input
                        name="discountCode"
                        value={values.discountCode}
                        label="Código Promocional"
                        className="Plans__DiscountInput"
                        placeholder="Cupom promocional"
                        onChange={handleChange}
                      />
                      {discountPercentage ? (
                        <Button
                          type="button"
                          btnStyle="primary"
                          onClick={() => this.clearDiscount(setFieldValue)}
                        >
                          {i18n.t('components.actions.edit')}
                        </Button>
                      ) : (
                        <Button onClick={handleSubmit} btnStyle="primary">
                          {i18n.t('components.actions.calculate')}
                        </Button>
                      )}
                      {errors.discountCode && (
                        <span className="Plans__Error">
                          {i18n.t('errors.invalidCoupon')}
                        </span>
                      )}
                    </Form>
                  )}
                </Formik>
              </Col>
              <Col xs={12}>
                <Button
                  btnStyle="link"
                  className="Plans__LinkBtn"
                  onClick={this.clearPlan}
                >
                  {i18n.t('plans.plan')}
                </Button>
                {selectedPlan && userInformed && (
                  <Fragment>
                    <i className="material-icons Plans__BreadcrumbsArrow">
                      keyboard_arrow_right
                    </i>
                    <Button
                      btnStyle="link"
                      className="Plans__LinkBtn"
                      onClick={this.clearUser}
                    >
                      {i18n.t('plans.personal')}
                    </Button>
                  </Fragment>
                )}
                {selectedPlan && userInformed && paymentInformed && (
                  <Fragment>
                    <i className="material-icons Plans__BreadcrumbsArrow">
                      keyboard_arrow_right
                    </i>
                    <Button
                      btnStyle="link"
                      className="Plans__LinkBtn"
                      onClick={this.clearPayment}
                    >
                      {i18n.t('plans.payment')}
                    </Button>
                  </Fragment>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment>
    )
  }

  renderUserInformation = ({
    setFieldValue,
    handleSubmit,
    errors,
    values,
    setFieldError,
    submitForm,
  }) => {
    return (
      <Col md={6} xs={12}>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} className="Plans__NoPadLeft">
              <InputMask
                label={i18n.t('plans.cpfCnpj')}
                name="cpfCnpj"
                type="tel"
                placeholder={i18n.t('plans.cpfCnpjPlaceholder')}
                mask={BETWEEN_CPF_CNPJ}
                value={values.cpfCnpj}
                error={errors.cpfCnpj}
                onChange={({ target: { name, value } }) =>
                  this.handleUserInformationChange(setFieldValue, name, value)
                }
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="Plans__NoPad">
              <Field
                label={i18n.t('plans.fullName')}
                name="fullName"
                placeholder={i18n.t('plans.fullNamePlaceholder')}
                value={values.fullName}
                error={errors.fullName}
                onChange={({ target: { name, value } }) =>
                  this.handleUserInformationChange(setFieldValue, name, value)
                }
              />
            </Col>
          </Row>
          <Row className="Plans__Row">
            <Col xs={12} className="Plans__NoPad">
              <Button btnStyle="primary" type="submit" block>
                {i18n.t('common.continue')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    )
  }

  renderBillingInformation = ({
    setFieldValue,
    handleSubmit,
    errors,
    values,
    setFieldError,
    submitForm,
  }) => {
    const { addressInformation } = this.state
    return (
      <Col md={6} xs={12}>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} className="Plans__NoPad">
              <InputMask
                label={i18n.t('plans.cep')}
                name="cep"
                type="tel"
                placeholder={i18n.t('plans.cepPlaceholder')}
                mask={CEP_MASK}
                value={values.cep}
                error={errors.cep}
                onBlur={() =>
                  this.getAddress(setFieldValue, setFieldError, submitForm)
                }
                onChange={({ target: { name, value } }) =>
                  this.handleBillingInformationChange(
                    setFieldValue,
                    name,
                    value,
                  )
                }
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className="Plans__NoPadLeft">
              <Field
                regularField
                label={i18n.t('generalData.ufWithInfo')}
                name="uf"
                placeholder={i18n.t('generalData.ufWithInfo')}
                value={values.uf}
                error={errors.uf}
                disabled={addressInformation}
                maxLength={2}
                onChange={({ target: { name, value } }) =>
                  this.handleBillingInformationChange(
                    setFieldValue,
                    name,
                    value,
                  )
                }
              />
            </Col>
            <Col xs={12} md={6} className="Plans__NoPadRight">
              <Field
                regularField
                label={i18n.t('generalData.city')}
                name="city"
                placeholder={i18n.t('generalData.city')}
                value={values.city}
                error={errors.city}
                disabled={addressInformation}
                onChange={({ target: { name, value } }) =>
                  this.handleBillingInformationChange(
                    setFieldValue,
                    name,
                    value,
                  )
                }
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="Plans__NoPad">
              <Field
                regularField
                label={i18n.t('alert.neighborhood')}
                name="neighborhood"
                placeholder={i18n.t('alert.neighborhood')}
                value={values.neighborhood}
                error={errors.neighborhood}
                onChange={({ target: { name, value } }) =>
                  this.handleBillingInformationChange(
                    setFieldValue,
                    name,
                    value,
                  )
                }
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className="Plans__NoPadLeft">
              <Field
                regularField
                label={i18n.t('alert.address')}
                name="address"
                placeholder={i18n.t('plans.addressPlaceholder')}
                value={values.address}
                error={errors.address}
                onChange={({ target: { name, value } }) =>
                  this.handleBillingInformationChange(
                    setFieldValue,
                    name,
                    value,
                  )
                }
              />
            </Col>
            <Col xs={12} md={3} className="Plans__NoPadMobile">
              <Field
                regularField
                label={i18n.t('components.filters.number')}
                name="number"
                type="tel"
                placeholder={i18n.t('plans.numberPlaceholder')}
                value={values.number}
                error={errors.number}
                onChange={({ target: { name, value } }) =>
                  this.handleBillingInformationChange(
                    setFieldValue,
                    name,
                    value,
                  )
                }
              />
            </Col>
            <Col xs={12} md={3} className="Plans__NoPadRight">
              <Field
                regularField
                label={i18n.t('plans.complement')}
                name="complement"
                placeholder={i18n.t('plans.complement')}
                value={values.complement}
                error={errors.complement}
                onChange={({ target: { name, value } }) =>
                  this.handleBillingInformationChange(
                    setFieldValue,
                    name,
                    value,
                  )
                }
              />
            </Col>
          </Row>
          <Row className="Plans__Row">
            <Col xs={12} className="Plans__NoPad">
              <Button btnStyle="primary" type="submit" block>
                {i18n.t('common.continue')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    )
  }

  renderPaymentMethod = ({ setFieldValue, handleSubmit, errors, values }) => {
    return (
      <Col md={6} xs={12}>
        <Form onSubmit={handleSubmit}>
          <Row className="Plans__Row">
            <Col xs={12} className="Plans__NoPad">
              <Field
                label={i18n.t('plans.cardName')}
                name="cardName"
                placeholder={i18n.t('plans.cardNamePlaceholder')}
                value={values.cardName}
                error={errors.cardName}
                onChange={({ target: { name, value } }) =>
                  this.handlePaymentInformationChange(
                    setFieldValue,
                    name,
                    value,
                  )
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={12} className="Plans__NoPadLeft">
              <InputMask
                label={i18n.t('plans.cardNumber')}
                name="cardNumber"
                type="tel"
                placeholder={i18n.t('plans.cardNumberPlaceholder')}
                mask={CARD_MASK}
                value={values.cardNumber}
                error={errors.cardNumber}
                onChange={({ target: { name, value } }) =>
                  this.handlePaymentInformationChange(
                    setFieldValue,
                    name,
                    value,
                  )
                }
              />
            </Col>
            <Col md={3} xs={12} className="Plans__NoPadMobile">
              <Field
                label={i18n.t('plans.code')}
                name="code"
                type="tel"
                placeholder={i18n.t('plans.codePlaceholder')}
                value={values.code}
                error={errors.code}
                onChange={({ target: { name, value } }) =>
                  value.length <= 4 &&
                  this.handlePaymentInformationChange(
                    setFieldValue,
                    name,
                    value,
                  )
                }
              />
            </Col>
            <Col md={3} xs={12} className="Plans__NoPadMobile">
              <InputMask
                label={i18n.t('plans.expireDate')}
                name="expireDate"
                type="tel"
                placeholder={i18n.t('plans.expireDatePlaceholder')}
                mask={EXPIRE_CARD_MASK}
                value={values.expireDate}
                error={errors.expireDate}
                onChange={({ target: { name, value } }) =>
                  this.handlePaymentInformationChange(
                    setFieldValue,
                    name,
                    value,
                  )
                }
              />
            </Col>
          </Row>
          <Row className="Plans__Row">
            <Col xs={12} className="Plans__NoPad">
              <Button btnStyle="primary" type="submit" block>
                {i18n.t('common.continue')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    )
  }

  render() {
    const {
      selectedPlan,
      userInformation,
      userInformed,
      paymentInformed,
      paymentInformation,
      billingInformation,
      billingInformed,
      card,
      ticket,
      termsAccepted,
      loading,
      discountPercentage,
    } = this.state
    const firstStep = !selectedPlan
    const secondStep = selectedPlan
    const thirdStep = selectedPlan && !userInformed
    const fourthStep = selectedPlan && userInformed && !billingInformed
    const fifthStep =
      selectedPlan && userInformed && billingInformed && !paymentInformed
    const sixthStep =
      selectedPlan &&
      userInformed &&
      billingInformed &&
      card &&
      !paymentInformed
    const seventhStep =
      selectedPlan && userInformed && billingInformed && paymentInformed
    return (
      <Fragment>
        <Grid>
          <Row center="xs">
            {firstStep && (
              <Fragment>
                {this.renderPlan('Starter', '279,90', true)}
                {this.renderPlan('Pro', '399,90')}
                {this.renderPlan('Enterprise', '599,90', false, true)}
              </Fragment>
            )}
            {secondStep && <Col xs={12}>{this.renderCheckout()}</Col>}
          </Row>
          {thirdStep && (
            <Row center="xs" className="Plans__Row">
              <Formik
                initialValues={{ ...userInformation }}
                validationSchema={UserInformationSchema}
                onSubmit={this.handleInformUser}
              >
                {props => selectedPlan && this.renderUserInformation(props)}
              </Formik>
            </Row>
          )}
          {fourthStep && (
            <Row center="xs" className="Plans__Row">
              <Formik
                initialValues={{ ...billingInformation }}
                validationSchema={BillingInformationSchema}
                onSubmit={this.handleInformBilling}
              >
                {props => selectedPlan && this.renderBillingInformation(props)}
              </Formik>
            </Row>
          )}
          {fifthStep && (
            <Fragment>
              <Row center="xs" className="Plans__Row">
                <Col md={6} xs={12}>
                  <p>{i18n.t('plans.paymentMethod')}</p>
                </Col>
              </Row>
              <Row center="xs" className="Plans__Row">
                <Col md={3} xs={6}>
                  <Checkbox
                    label={i18n.t('plans.bankSlip')}
                    checked={ticket}
                    onChange={this.handleSelectTicket}
                  />
                </Col>
                <Col md={3} xs={6}>
                  <Checkbox
                    label={i18n.t('plans.card')}
                    checked={card}
                    onChange={this.handleSelectCard}
                  />
                </Col>
              </Row>
            </Fragment>
          )}
          {sixthStep && (
            <Fragment>
              <Row center="xs" className="Plans__Row">
                <Formik
                  initialValues={{ ...paymentInformation }}
                  validate={this.validateForm}
                  onSubmit={this.handleInformPayment}
                >
                  {props => selectedPlan && this.renderPaymentMethod(props)}
                </Formik>
              </Row>
            </Fragment>
          )}
          {seventhStep && (
            <Row center="xs">
              <Col xs={12} md={6}>
                <div className="Plans__Contract">
                  <Contract
                    name={userInformation.fullName}
                    document={userInformation.cpfCnpj}
                    locality={`${billingInformation.city} - ${
                      billingInformation.uf
                    }`}
                    address={`${billingInformation.address}, ${
                      billingInformation.number
                    }, ${billingInformation.neighborhood}`}
                    cep={billingInformation.cep}
                    plan={PLANS[selectedPlan]}
                    discount={
                      discountPercentage
                        ? (PLANS[selectedPlan].price * discountPercentage) / 100
                        : null
                    }
                  />
                </div>
                <div className="Plans__AcceptTerms">
                  <Checkbox
                    label={i18n.t('plans.readedAndAccept')}
                    checked={termsAccepted}
                    onChange={this.handleAcceptTerms}
                  />
                  <Button btnStyle="link" onClick={this.downloadContract}>
                    {i18n.t('plans.contractDownload')}
                  </Button>
                </div>
                <p className="Plans__InfoContract">
                  {i18n.t('plans.whenFinalize')}
                  <a
                    href="https://plataforma.eemovel.com.br/termosdeuso"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {i18n.t('plans.userTerms')}
                  </a>
                  {i18n.t('plans.withA')}
                  <a
                    href="https://plataforma.eemovel.com.br/termosdeuso"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {i18n.t('plans.privacyDeclaration')}
                  </a>
                  {i18n.t('plans.andDeclare')}
                </p>
                <Button
                  btnStyle="primary"
                  type="submit"
                  disabled={!termsAccepted}
                  onClick={this.finalizeCheckout}
                  block
                >
                  {i18n.t('common.finish')}
                </Button>
              </Col>
            </Row>
          )}
        </Grid>
        <Spinner visible={loading} />
      </Fragment>
    )
  }
}

export default Plans
