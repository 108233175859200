import React, { Component } from 'react'
import { Table, Row, Col } from 'components/AlertTable/styles'
import { Button } from 'components/Buttons'
import classNames from 'classnames'
import { PaginateArray } from 'utils/arrayPagination'
import I18n from 'locales'
import moment from 'moment'
import { downloadRealtyReport } from 'services/report'
import { FadeLoader } from 'react-spinners'
import { COLORS } from 'constants/colors'
import { flatten, debounce } from 'lodash'
import ReportModal from 'components/ReportModal'
import { RealtyConsumer } from 'context/Realty'
import './index.css'

const WIDTH = `${98 / 13}%`

class ShowRealty extends Component {
  constructor(props, context) {
    super(props, context)
    this.WIDTH = context.defaultColumnWidth || WIDTH
    this.state = {
      initialized: false,
      tempSearch: false,
      sortRealState: 'asc',
      sortNeighborhood: 'asc',
      sortBuilding: 'asc',
      sortPrice: 'asc',
      sortBedrooms: 'asc',
      sortGarages: 'asc',
      sortTotalArea: 'asc',
      sortMeterPrice: 'asc',
      sortUsefulArea: 'asc',
      sortUsefulMeterPrice: 'asc',
      sortAddress: 'asc',
      sortAdDate: 'desc',
      filtered: false,
      reportModalVisible: false,
      selectedItems: [],
      realties: [],
      page: 0,
    }
  }

  componentDidMount() {
    this.handleLoadMessage()
    this.context.alert && this.setRealties()
  }

  componentDidUpdate() {
    !this.context.alert &&
      this.context.realties.length &&
      !this.state.initialized &&
      this.setRealties()
  }

  setRealties = () => {
    this.setState(
      state => ({
        ...state,
        initialized: true,
        loadMessage: null,
        realties: PaginateArray(this.context.realties),
      }),
      () => this.sortBy('AdDate'),
    )
  }

  selectPage = (unselect = false) => {
    const { selectRealty = null } = this.context
    const { realties } = this.state
    let { selectedItems } = this.state

    selectRealty && selectRealty(null, realties)
    selectedItems = flatten(realties)

    if (unselect) {
      selectRealty && selectRealty(null, [])
      this.setState(state => ({ ...state, selectedItems: [] }))
    } else this.setState(state => ({ ...state, selectedItems }))
  }

  handleLoadMessage = () => {
    setTimeout(
      () =>
        this.setState({
          loadMessage: I18n.t('showRealty.loadMessage'),
          timeOutMessage: I18n.t('showRealty.timeOutMessage'),
        }),
      1500,
    )
  }

  sortBy = (column, sortOrder) => {
    const { filtered } = this.state
    let realties = [
      ...((filtered && [].concat(...this.state.realties)) ||
        this.context.realties),
    ]
    let order = sortOrder || this.state[`sort${column}`]
    this.setState(state => ({
      ...state,
      page: 0,
      realties: PaginateArray(
        realties.sort((a, b) => {
          if (column === 'AdDate') {
            const dateA = new Date(a[column])
            const dateB = new Date(b[column])
            if (dateA < dateB) return order === 'asc' ? -1 : 1
            if (dateA > dateB) return order === 'asc' ? 1 : -1
          } else {
            if (
              column === 'MeterPrice' ||
              column === 'Price' ||
              column === 'UsefulMeterPrice'
            ) {
              const A = Number(a[column].replace(/\./g, '').replace(/,/g, '.'))
              const B = Number(b[column].replace(/\./g, '').replace(/,/g, '.'))
              if (A < B) return order === 'asc' ? 1 : -1
              if (A > B) return order === 'asc' ? -1 : 1
            } else {
              if (a[column] < b[column]) return order === 'asc' ? 1 : -1
              if (a[column] > b[column]) return order === 'asc' ? -1 : 1
            }
          }
          return 0
        }),
      ),
      [`sort${column}`]: order === 'asc' ? 'desc' : 'asc',
      selectedItems: [],
    }))
  }

  isPageSelected = () => {
    const { realties, page, selectedItems } = this.state

    if (realties.length > 0) {
      for (let realty of realties[page]) {
        if (!selectedItems.includes(realty)) return false
      }

      return true
    }

    return false
  }

  renderHeader = () => {
    const selected = this.isPageSelected()
    const { selectable = true, showActions = true } = this.context
    const icon = selected ? 'check_box' : 'check_box_outline_blank'
    return (
      <Row header>
        {selectable && (
          <Col textCenter header width="2%" className-="ShowRealty__Sortable">
            <div
              className="ShowRealty__Selection"
              onClick={() => this.selectPage(selected)}
            >
              <i className="material-icons">{icon}</i>
            </div>
          </Col>
        )}
        {this.renderHeaderCol('RealState', I18n.t('alert.realState'))}
        {this.renderHeaderCol('Neighborhood', I18n.t('alert.neighborhood'))}
        {this.renderHeaderCol('Building', I18n.t('alert.building'))}
        {this.renderHeaderCol('Price', I18n.t('alert.value'))}
        {this.renderHeaderCol('Bedrooms', I18n.t('alert.room'))}
        {this.renderHeaderCol('Garages', I18n.t('alert.garageMin'))}
        {this.renderHeaderCol('TotalArea', I18n.t('alert.totalArea'))}
        {this.renderHeaderCol('MeterPrice', I18n.t('alert.totalAreaValue'))}
        {this.renderHeaderCol('UsefulArea', I18n.t('alert.usefulArea'))}
        {this.renderHeaderCol(
          'UsefulMeterPrice',
          I18n.t('alert.userfulAreaValue'),
        )}
        {this.renderHeaderCol('Address', I18n.t('alert.address'))}
        {this.renderHeaderCol('AdDate', I18n.t('alert.inclusion'))}
        {showActions && (
          <Col textCenter header width={this.WIDTH}>
            {I18n.t('alert.actions')}
          </Col>
        )}
      </Row>
    )
  }

  searchRealty = value => {
    let realties = [...this.context.realties]
    if (value) {
      this.setState(
        state => ({
          ...state,
          page: 0,
          filtered: true,
          tempSearch: false,
          realties: PaginateArray(
            realties.filter(
              item =>
                item.RealState.toLowerCase().includes(value.toLowerCase()) ||
                String(item.Price).includes(value) ||
                String(item.Price)
                  .replace(/\.|,/g, '')
                  .includes(value) ||
                String(item.Neighborhood)
                  .toLowerCase()
                  .includes(value.toLowerCase()) ||
                String(item.Bedrooms).includes(value) ||
                String(item.Garages).includes(value) ||
                String(item.Building)
                  .toLowerCase()
                  .includes(value.toLowerCase()) ||
                String(item.Address)
                  .toLowerCase()
                  .includes(value.toLowerCase()) ||
                String(item.TotalArea).includes(value) ||
                String(item.MeterPrice).includes(value) ||
                String(item.MeterPrice)
                  .replace(/\.|,/g, '')
                  .includes(value) ||
                String(item.UsefulArea).includes(value) ||
                String(item.UsefulMeterPrice).includes(value) ||
                String(item.UsefulMeterPrice)
                  .replace(/\.|,/g, '')
                  .includes(value),
            ),
          ),
        }),
        () => {
          this.state.realties.length === 0 &&
            this.setState({ tempSearch: true })
          this.sortBy('AdDate', 'desc')
        },
      )
    } else {
      this.setState(
        state => ({
          ...state,
          page: 0,
          filtered: false,
          realties: PaginateArray(this.context.realties),
        }),
        () => this.sortBy('AdDate', 'desc'),
      )
    }
  }

  debouncedSearchRealty = debounce(this.searchRealty, 350)

  handleSearchRealty = value => this.debouncedSearchRealty(value)

  handleSelect = realty => {
    const { selectRealty = null } = this.context
    if (selectRealty) {
      selectRealty(realty)
    }

    let { selectedItems } = this.state
    let index = selectedItems.indexOf(realty)

    if (index === -1) selectedItems.push(realty)
    else selectedItems.splice(index, 1)

    this.setState(state => ({ ...state, selectedItems }))
  }

  exportReport = format => {
    const { selectedItems } = this.state
    const { clearRealty } = this.context
    const ids = selectedItems.map(({ Id }) => Id)
    this.setState({ loading: true })
    const { businessType } = this.context
    downloadRealtyReport({ ids, format, businessType })
      .then(({ request, data: { Message } }) => {
        clearRealty && clearRealty()
        this.setState({ selectedItems: [] }, () =>
          this.setState({ loading: false }),
        )
        request.onloadend = function(event) {
          if (request.status === 200) {
            let link = document.createElement('a')
            document.body.appendChild(link)
            link.download = `Relatorio.${format}`
            link.href = window.URL.createObjectURL(request.response)
            link.click()
          } else {
            window.M.toast({
              classes: 'Toast--failure',
              html: Message,
            })
          }
        }
      })
      .catch(err => {
        console.error(err)
        let classes = 'Toast--failure'
        let html = I18n.t('common.fail')
        if (err.response.status === 401) {
          html = 'Usuário Trial não pode efetuar download.'
        }
        window.M.toast({
          classes,
          html,
        })
        this.setState({ loading: false })
      })
  }

  renderCol = (title, label, center = true) => (
    <Col title={title} label={label} textCenter={center} width={this.WIDTH}>
      {title}
    </Col>
  )

  renderHeaderCol = (sort, title, center = true) => (
    <Col
      textCenter={center}
      header
      title={title}
      width={this.WIDTH}
      onClick={() => this.sortBy(sort)}
      className="ShowRealty__Sortable"
    >
      <span>{title}</span>
      <i className="material-icons">
        {`keyboard_arrow_${
          this.state[`sort${sort}`] === 'desc' ? 'down' : 'up'
        }`}
      </i>
    </Col>
  )

  openReportModal = id => {
    this.setState(state => ({
      reportRealtyId: id,
      reportModalVisible: true,
    }))
  }

  renderRow = (realty, index) => {
    const { businessType } = this.context
    const now = moment(new Date())
    const realState = realty.RealState || I18n.t('common.notInformed')
    const neighborhood = realty.Neighborhood || I18n.t('common.notInformed')
    const building = realty.Building || I18n.t('common.notInformed')
    const rooms =
      realty.Bedrooms >= 0 ? realty.Bedrooms : I18n.t('common.notInformed')
    const garages =
      realty.Garages >= 0 ? realty.Garages : I18n.t('common.notInformed')
    const totalArea = realty.TotalArea || I18n.t('common.notInformed')
    const meterPrice = realty.MeterPrice
    const usefulArea = realty.UsefulArea || I18n.t('common.notInformed')
    const address = realty.Address || I18n.t('common.notInformed')
    const price = realty.Price || I18n.t('common.notInformed')
    const usefulMeterPrice =
      realty.UsefulMeterPrice || I18n.t('common.notInformed')
    const duration = moment
      .duration(now.diff(realty.AdDate))
      .asDays()
      .toFixed(0)
    const adDate = realty.AdDate
      ? `${duration} dia(s)`
      : I18n.t('common.notInformed')
    const selected = this.context.selectRealty
      ? this.context.selectedRealty.includes(realty)
      : this.state.selectedItems.includes(realty)
    const { selectable = true, showActions = true } = this.context
    const icon = selected ? 'check_box' : 'check_box_outline_blank'
    const link = realty.Link.includes('zapimoveis')
      ? `${realty.Link}?${new Date()
          .toDateString()
          .split(' ')
          .join('-')}`
      : realty.Link

    return (
      <Row className="ShowRealty__Row" key={index}>
        {selectable && (
          <Col textCenter width="2%">
            <div
              onClick={() => this.handleSelect(realty)}
              className="ShowRealty__Selection"
            >
              <i className="material-icons">{icon}</i>
            </div>
          </Col>
        )}
        {this.renderCol(realState, I18n.t('alert.realState'), false)}
        {this.renderCol(neighborhood, I18n.t('alert.neighborhood'))}
        {this.renderCol(building, I18n.t('alert.building'))}
        {this.renderCol(price, I18n.t('alert.value'))}
        {this.renderCol(rooms, I18n.t('alert.room'))}
        {this.renderCol(garages, I18n.t('alert.garageMin'))}
        {this.renderCol(totalArea, I18n.t('alert.totalArea'))}
        {this.renderCol(meterPrice, I18n.t('alert.totalAreaValue'))}
        {this.renderCol(usefulArea, I18n.t('alert.usefulArea'))}
        {this.renderCol(usefulMeterPrice, I18n.t('alert.userfulAreaValue'))}
        {this.renderCol(address, I18n.t('alert.address'), false)}
        {this.renderCol(adDate, I18n.t('alert.inclusion'))}
        {showActions && (
          <Col
            label={I18n.t('alert.actions')}
            action="1"
            textCenter
            width={this.WIDTH}
          >
            <Row>
              <a
                title="Ver Anúncio"
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className="ShowRealty__Link ShowRealty__Actions"
              >
                <i className="material-icons">visibility</i>
              </a>
            </Row>
            {!this.context.alert && (
              <React.Fragment>
                <Row>
                  <Button
                    title="Relatório"
                    btnStyle="primary"
                    btnSize="sm"
                    className="ShowRealty__Actions"
                    block
                    onClick={() =>
                      window.open(`/rgi/${realty.Id}/${businessType}`, '_tab')
                    }
                  >
                    <i className="material-icons">list_alt</i>
                  </Button>
                </Row>
                <Row>
                  <Button
                    title="Reportar"
                    btnStyle="primary"
                    btnSize="sm"
                    className="ShowRealty__Actions"
                    onClick={() => this.openReportModal(realty.Id)}
                    block
                  >
                    <i className="material-icons">bug_report</i>
                  </Button>
                </Row>
              </React.Fragment>
            )}
          </Col>
        )}
      </Row>
    )
  }

  toggleReportModal = () => {
    this.setState({
      reportModalVisible: false,
      reportRealtyId: null,
    })
  }

  render() {
    const {
      visible,
      toggle,
      modal = true,
      showClose = true,
      exportable = true,
      searchable = true,
      defaultColumnWidth,
    } = this.context
    const {
      page,
      selectedItems,
      loadMessage,
      tempSearch,
      realties,
      reportModalVisible,
      reportRealtyId,
    } = this.state
    const classes = classNames({
      ShowRealty: visible,
      'ShowRealty--hidden': !visible,
    })
    return (
      <div>
        <div
          className={
            modal
              ? classes
              : `ShowRealty__Evaluation${(defaultColumnWidth && '--nomargin') ||
                  ''}`
          }
        >
          <div
            className={modal ? 'ShowRealty__Modal' : 'ShowRealty__NotModal'}
            data-tut="step-ten"
          >
            <div className="ShowRealty__Header">
              {showClose && (
                <i className="material-icons" onClick={toggle}>
                  close
                </i>
              )}
              <div className="ShowRealty__Toolbar">
                {searchable && (
                  <input
                    placeholder={I18n.t('alert.search')}
                    className={`ShowRealty__SearchInput ${defaultColumnWidth &&
                      'ShowRealty__SearchInput--small'}`}
                    onChange={({ target: { value } }) =>
                      this.handleSearchRealty(value)
                    }
                  />
                )}
                {exportable && (
                  <div>
                    <Button
                      disabled={selectedItems.length === 0}
                      btnStyle="primary"
                      onClick={() => this.exportReport('pdf')}
                    >
                      <i className="material-icons">picture_as_pdf</i>
                      {I18n.t('showRealty.exportPDF')}
                    </Button>
                    <Button
                      disabled={selectedItems.length === 0}
                      btnStyle="primary"
                      onClick={() => this.exportReport('xlsx')}
                    >
                      <i className="material-icons">description</i>
                      {I18n.t('showRealty.exportCSV')}
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className="ShowRealty__Body">
              <Table className="ShowRealty__Table">
                {this.renderHeader()}
                <div className="ShowRealty__ScrollContainer">
                  {realties && realties.length > 0 ? (
                    realties[page].map((realty, index) =>
                      this.renderRow(realty, index),
                    )
                  ) : loadMessage && !tempSearch ? (
                    <div className="ShowRealty__Loader">
                      <FadeLoader
                        sizeUnit="px"
                        size={50}
                        color={COLORS.gray28}
                        visible
                      />
                      {loadMessage}
                    </div>
                  ) : (
                    tempSearch && 'Opa, nenhum imóvel correspondente :('
                  )}
                </div>
              </Table>
            </div>
            <div className="ShowRealty__Footer">
              {page > 0 && (
                <i
                  className="material-icons"
                  onClick={() => this.setState({ page: page - 1 })}
                >
                  keyboard_arrow_left
                </i>
              )}
              {`${page + 1} / ${realties.length}`}
              {page < realties.length - 1 && (
                <i
                  className="material-icons"
                  onClick={() => this.setState({ page: page + 1 })}
                >
                  keyboard_arrow_right
                </i>
              )}
            </div>
          </div>
        </div>
        <ReportModal
          id={reportRealtyId}
          visible={reportModalVisible}
          toggle={this.toggleReportModal}
        />
      </div>
    )
  }
}
ShowRealty.contextType = RealtyConsumer

export default ShowRealty
