import React, { Fragment, PureComponent } from 'react'
import { Formik, Field, Form, Select } from 'components/Form'
import { Row, Col } from 'components/Grid'
import { Button } from 'components/Buttons'
import { getUfWithCities } from 'services/location'
import { updateUserInfo, downloadContract } from 'services/user'
import { updateCredential, userInfo } from 'services/auth'
import { callRequest } from 'services/requestHandler'
import { findIndex } from 'lodash'
import Spinner from 'components/Spinner'
import * as Yup from 'yup'
import I18n from 'locales'
import './index.css'

const UserUpdateSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, I18n.t('generalData.nameTooShort'))
    .required(I18n.t('login.required')),
  city: Yup.string().required(I18n.t('login.required')),
  uf: Yup.string().required(I18n.t('login.required')),
})

class GeneralData extends PureComponent {
  constructor() {
    super()
    this.state = {
      ufs: [],
      loading: false,
    }
    this.Materialize = window.M
  }

  componentDidMount() {
    this.getUfs()
  }

  getUfs = () => {
    this.setState({ loading: true })
    callRequest(() => getUfWithCities(), data => this.setUfs(data))
  }

  setUfs = ufs => {
    this.setState({ ufs, loading: false })
  }

  getCities = ufId => {
    const { ufs } = this.state
    const index = findIndex(ufs, item => item.Id === Number(ufId))

    return ufs[index].Cidades
  }

  updateUserInfo = values => {
    const { user } = this.props
    const payload = {
      CidadeId: Number(values.city),
      Endereco: user.address,
      EstadoId: Number(values.uf),
      GestorId: user.managerId,
      Id: user.userId,
      ImobiliariaId: user.realStateId,
      Nome: values.name,
      TipoPerfil: user.profileType,
      Telefone: user.phone,
    }
    const setNotLoading = () => this.setState({ loading: false })
    this.setState({ loading: true })
    callRequest(
      () => updateUserInfo(payload),
      () => {
        updateCredential(values)
        setNotLoading()
        this.Materialize.toast({
          html: I18n.t('common.successfullyAltered'),
          classes: 'GeneralData__Toast--success',
        })
      },
      setNotLoading,
      setNotLoading,
      () => window.location.reload(),
    )
  }

  render() {
    const {
      user: { username, cityId, ufId },
      mobile,
    } = this.props
    const { ufs, loading } = this.state
    return (
      <Fragment>
        {userInfo().cpfCnpj && (
          <Row end="xs">
            <Col xs={12}>
              <Button
                btnStyle="primary"
                onClick={downloadContract({
                  nome: userInfo().nome,
                  documento: userInfo().cpfCnpj,
                }).then(({ request }) => {
                  request.onloadend = () => {
                    if (request.status === 200) {
                      let link = document.createElement('a')
                      document.body.appendChild(link)
                      link.download = 'contrato_de_prestacao_de_servico.pdf'
                      link.href = window.URL.createObjectURL(request.response)
                      link.click()
                    }
                  }
                })}
              >
                Donwload Contrato
              </Button>
            </Col>
          </Row>
        )}
        <Formik
          initialValues={{ name: username, city: cityId, uf: ufId }}
          onSubmit={values => this.updateUserInfo(values)}
          validationSchema={UserUpdateSchema}
        >
          {({
            handleChange,
            values: { name, uf, city },
            handleSubmit,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col xs={12} lg={6}>
                  <Field
                    name="name"
                    label={I18n.t('generalData.name')}
                    value={name}
                    placeholder={I18n.t('generalData.namePlaceholder')}
                    error={errors.name}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={6}>
                  <Select
                    disabled={!ufs.length}
                    name="uf"
                    label={I18n.t('generalData.uf')}
                    component="select"
                    value={uf}
                    onChange={handleChange}
                    error={errors.uf}
                  >
                    {ufs.map(({ Id, Nome }) => (
                      <option value={Id} key={Id}>
                        {Nome}
                      </option>
                    ))}
                  </Select>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={6}>
                  <Select
                    name="city"
                    label={I18n.t('generalData.city')}
                    value={city}
                    disabled={!uf}
                    onChange={handleChange}
                    error={errors.city}
                  >
                    {uf &&
                      ufs.length &&
                      this.getCities(uf).map(({ Id, Nome }) => (
                        <option value={Id} key={Id}>
                          {Nome}
                        </option>
                      ))}
                  </Select>
                </Col>
              </Row>
              <Row className="GeneralData__BtnRow">
                <Col xs={12}>
                  <Button type="submit" btnStyle="primary" block={mobile}>
                    {I18n.t('common.save')}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
        <Spinner visible={loading} />
      </Fragment>
    )
  }
}

export default GeneralData
