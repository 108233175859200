import axios from 'axios'
import { headers as HEADERS } from 'services/auth'
const API_URL = process.env.REACT_APP_API_URL
const GET_ACCOUNTS = `${API_URL}/conta/contacorretor`
const REGISTER_BROKER = `${API_URL}/Account/RegisterBroker`
const REMOVE_BROKER = `${API_URL}/conta/removercorretor`
const GET_USERS = `${API_URL}/administrativo/buscarusuarios`
const RESEND_PASSWORDS = `${API_URL}/administrativo/reenviarsenha`
const INACTIVATE_USERS = `${API_URL}/administrativo/inativarusuarios`
const ACTIVATE_USERS = `${API_URL}/administrativo/ativarusuarios`
const MAKE_REAL_STATE = `${API_URL}/administrativo/tornarcorretor?gestorId=`
const MAKE_MANAGER = `${API_URL}/administrativo/tornargestor?numeroContas=`
const ACCESS_REPORT = (startDate, endDate) =>
  `${API_URL}/administrativo/relatorioacesso?DataInicial=${startDate}&DataFinal=${endDate}`

const CHANGE_VALIDITY = `${API_URL}/administrativo/alterarvigencia?Vigencia=`

const headers = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
  ...HEADERS,
}

export const getManagedAccounts = id => {
  return axios.get(`${GET_ACCOUNTS}?id=${id}`, { headers })
}

export const registerBroker = payload => {
  return axios.post(REGISTER_BROKER, payload, { headers })
}

export const removeBroker = payload => {
  return axios.post(`${REMOVE_BROKER}?corretorId=${payload}`, null, { headers })
}

export const getUsers = () => {
  return axios.get(GET_USERS, { headers })
}

export const resendPasswords = ids =>
  axios.post(RESEND_PASSWORDS, ids, { headers })

export const inactivateUsers = ids =>
  axios.post(INACTIVATE_USERS, ids, { headers })

export const activateUsers = ids => axios.post(ACTIVATE_USERS, ids, { headers })

export const makeRealState = (id, ids) =>
  axios.post(`${MAKE_REAL_STATE}${id}`, ids, { headers })

export const makeManager = (accountsQuantity, ids) =>
  axios.post(`${MAKE_MANAGER}${accountsQuantity}`, ids, { headers })

export const accessReport = (startDate, endDate, ids) => {
  let splited = startDate.split('-')
  const start = `${splited[1]}/${splited[2]}/${splited[0]}`
  splited = endDate.split('-')
  const end = `${splited[1]}/${splited[2]}/${splited[0]}`

  return axios.post(ACCESS_REPORT(start, end), ids, { headers })
}

export const changeValidity = (date, ids) => {
  let splited = date.split('-')
  const newDate = `${splited[1]}/${splited[2]}/${splited[0]}`

  return axios.post(`${CHANGE_VALIDITY}${newDate}`, ids, { headers })
}
