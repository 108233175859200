import React, { Component } from 'react'
import Header from 'components/Header'
import Sidebar from 'components/Sidebar'
import AlertTable from 'components/AlertTable'
import GeneralData from 'components/GeneralData'
import AccessData from 'components/AccessData'
import Accounts from 'components/Accounts'
import Users from 'components/Users'
import Plans from 'components/Plans'
import I18n from 'locales'
import { userInfo } from 'services/auth'
import { getPaymentStatus } from 'services/user'
import Chat from 'components/Chat'
import './index.css'

class Preference extends Component {
  constructor() {
    super()
    this.user = userInfo()
  }

  componentDidMount() {
    getPaymentStatus()
  }

  renderContent() {
    const { hash } = this.props.location
    switch (hash) {
      case I18n.t('routes.preferenceInitial'):
        return <h1>Ops! Nada aqui ainda :(</h1>
      case I18n.t('routes.preferenceAlert'):
        return <AlertTable />
      case I18n.t('routes.preferenceGeneralData'):
        return <GeneralData user={this.user} />
      case I18n.t('routes.preferenceAccessData'):
        return <AccessData user={this.user} />
      case I18n.t('routes.preferencePlans'):
        return <Plans />
      case I18n.t('routes.preferenceAccounts'):
        if (this.user.profileType === 3) {
          return <Accounts user={this.user} />
        } else {
          return <GeneralData user={this.user} />
        }
      case I18n.t('routes.preferenceUsers'):
        if (
          this.user.roles.includes('Admin') ||
          this.user.roles.includes('Sales') ||
          this.user.roles.includes('Staff')
        ) {
          return <Users />
        } else {
          return <GeneralData user={this.user} />
        }
      default:
        return <GeneralData user={this.user} />
    }
  }

  renderHeader() {
    const { hash } = this.props.location
    switch (hash) {
      case I18n.t('routes.preferenceInitial'):
        return (
          <h1 className="Preference__Header">{I18n.t('generalData.header')}</h1>
        )
      // return (
      //   <h1 className="Preference__Header">
      //     {I18n.t('common.welcome')}, {this.user.username}
      //   </h1>
      // )
      case I18n.t('routes.preferenceAlert'):
        return <h1 className="Preference__Header">{I18n.t('alert.alerts')}</h1>
      case I18n.t('routes.preferenceGeneralData'):
        return (
          <h1 className="Preference__Header">{I18n.t('generalData.header')}</h1>
        )
      case I18n.t('routes.preferenceAccessData'):
        return (
          <h1 className="Preference__Header">{I18n.t('accessData.header')}</h1>
        )
      case I18n.t('routes.preferencePlans'):
        return <h1 className="Preference__Header">{I18n.t('plans.plans')}</h1>
      case I18n.t('routes.preferenceAccounts'):
        if (this.user.roles.includes('Manager') || this.user.profileType === 3)
          return (
            <h1 className="Preference__Header">
              {I18n.t('accounts.accounts')}
            </h1>
          )
        else
          return (
            <h1 className="Preference__Header">
              {I18n.t('generalData.header')}
            </h1>
          )
      case I18n.t('routes.preferenceUsers'):
        if (
          this.user.roles.includes('Admin') ||
          this.user.roles.includes('Sales') ||
          this.user.roles.includes('Staff')
        )
          return <h1 className="Preference__Header">{I18n.t('users.users')}</h1>
        else
          return (
            <h1 className="Preference__Header">
              {I18n.t('generalData.header')}
            </h1>
          )
      default:
        return (
          <h1 className="Preference__Header">{I18n.t('generalData.header')}</h1>
        )
      // return (
      //   <h1 className="Preference__Header">
      //     {I18n.t('common.welcome')}, {this.user.username}
      //   </h1>
      // )
    }
  }

  render() {
    const { hash } = this.props.location
    return (
      <React.Fragment>
        <Header />
        <Sidebar hash={hash} />
        <div className="Preference__Container">
          {this.renderHeader()}
          {/* <span className="Preference__Info">
            Um texto explicativo de explicações explicadas
          </span> */}
          <div className="Preference__Content">{this.renderContent()}</div>
        </div>
        <Chat />
      </React.Fragment>
    )
  }
}

export default Preference
