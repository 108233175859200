import React, { Component } from 'react'
import Spinner from 'components/Spinner'
import { getUserInfo, storeUser, setToken } from 'services/auth'
import I18n from 'locales'
import './index.css'

class UnionTransition extends Component {
  componentDidMount() {
    const { token } = this.props.match.params
    setToken(token)
    getUserInfo(token)
      .then(({ data }) => {
        storeUser(data)
        setTimeout(() => (window.location = I18n.t('routes.search')), 500)
      })
      .catch(err => {
        console.error(err)
      })
  }

  render() {
    return (
      <div className="UnionTransition">
        <Spinner visible />
      </div>
    )
  }
}

export default UnionTransition
