import React from 'react'

import { formatToLocale } from 'utils/numberFormatter'

import './index.css'

const getFormatedDate = () => {
  const now = new Date()
  const month = now.toLocaleDateString('pt-BR', { month: 'long' })
  const day = now.getDate()
  const year = now.getFullYear()

  return `${day} de ${month} de ${year}`
}
const Contract = ({
  name,
  document,
  locality,
  address,
  cep,
  plan,
  discount = null,
}) => {
  const isCpf = document.length === 14
  return (
    <div className="Contract">
      <p>
        CONTRATO DE PRESTAÇÃO DE SERVIÇOS ATENÇÃO: LEIA CUIDADOSAMENTE OS TERMOS
        DESTE CONTRATO. AO CONCORDAR COM O MESMO E EFETUAR O PRIMEIRO PAGAMENTO,
        ESTARÁ CONFIGURADA A CONCORDÂNCIA E ACEITAÇÃO DOS TERMOS E CONDIÇÕES
        AQUI PROPOSTOS, ADERINDO A ESTE CONTRATO.
      </p>
      <p>
        Este contrato refere-se à contratação da Plataforma de Inteligência
        Imobiliária - <strong>EEmovel</strong>, abrangendo qualquer uma de suas
        versões:
      </p>
      <p>
        De um lado comparece como CONTRATADA, EEMOVEL SERVIÇOS DE INFORMAÇÕES
        LTDA, inscrita no CNPJ nº 21.519.937/0001-48, com sede na Rua Duque de
        Caxias, 209, Bairro Centro, Cascavel - PR, empresa desenvolvedora da
        Plataforma de Inteligência Imobiliária – <strong>EEmovel</strong>.
      </p>
      <p>
        De outro lado comparece como {name}, pessoa
        {isCpf ? ' física' : ' jurídica'}, inscrita no {isCpf ? 'CPF' : 'CNPJ'}{' '}
        sob o nº {document}, com sede na cidade de {locality}, na {address},
        CEP: {cep}.
      </p>
      <p>
        <strong>1. DO OBJETO</strong>
      </p>
      <p>
        <strong>CLÁUSULA PRIMEIRA:</strong> o presente contrato tem por objeto a
        prestação do serviço de informações baseado no conteúdo do site:
        https://www.eemovel.com.br, de acordo com o plano aderido pela
        CONTRATANTE, contendo acesso às funcionalidades descritas no próprio
        site da CONTRATADA.
      </p>
      <p>
        <strong>Parágrafo primeiro:</strong> este software é disponibilizado no
        modelo SaaS (software como serviço), ou seja, é disponibilizado em forma
        de aluguel, sendo pago mensalmente, recorrente, com duração de 12 meses,
        e renovação automática. O pagamento será em formato antecipado, de
        acordo com o plano escolhido pela CONTRATANTE.
      </p>
      <p>
        <strong>Parágrafo segundo: </strong> este produto possui a contratação
        mínima de 3 meses.
      </p>
      <p>
        <strong>CLÁUSULA SEGUNDA:</strong> à título de melhor entendimento e
        informação, a Plataforma de Inteligência Imobiliária –{' '}
        <strong>EEmovel</strong>, será referida neste contrato como software ou
        plataforma.
      </p>
      <p>
        <strong>2. Contratação de Serviços</strong>
      </p>
      <p>
        <strong>CLÁUSULA PRIMEIRA:</strong> A descrição do serviço de
        inteligência imobiliária será especificado no Anexo 1.
      </p>
      <p>
        <strong>CLÁUSULA SEGUNDA:</strong> O CONTRATANTE poderá, ainda,
        contratar serviços específicos de análise de informações imobiliárias,
        de cunho de incorporação, consultoria, venda e locação de imóveis. O
        CONTRATANTE deverá solicitar orçamento específico à CONTRATADA através
        do e-mail admin@eemovel.com.br, com as informações necessárias sobre os
        serviços específicos. A contratação de tais serviços será efetuada
        mediante formalização de documento específico com valores e cronograma,
        os quais não fazem parte deste contrato.
      </p>
      <p>
        <strong>3. PROPRIEDADE INTELECTUAL</strong>
      </p>
      <p>
        <strong>CLÁUSULA PRIMEIRA:</strong>A utilização de direitos de
        propriedade intelectual que apareçam ou estejam de alguma forma ligados
        à prestação de serviços ora contratados, deverão ter utilização restrita
        ao objeto do presente Contrato, de acordo com as condições
        estabelecidas, devendo as partes preservar tais direitos da contraparte,
        seja de suas marcas, direitos autorais, software, bem como direito de
        propriedade intelectual aqui mencionados e relacionados à Plataforma.
      </p>
      <p>
        <strong>CLÁUSULA SEGUNDA:</strong>O CONTRATANTE não poderá, em nenhum
        formato, copiar, reproduzir, adaptar, traduzir, alienar, modificar,
        alugar, vender, ceder, transferir, descompilar ou fazer engenharia
        reversa da Plataforma, no todo ou em parte. Também é de extrema
        restrição que o CONTRATANTE utilize de ferramentas de captura de dados
        dentro da plataforma. No caso de execução de algumas destas restrições,
        com o intuito de prejudicar o CONTRATADO, a conta de acesso do
        CONTRATANTE poderá ser bloqueada, e medidas jurídicas poderão ser
        tomadas.
      </p>
      <p>
        <strong>4. DO PREÇO E DAS CONDIÇÕES DE PAGAMENTO</strong>
      </p>
      <p>
        <strong>CLÁUSULA PRIMEIRA:</strong> O valor mensal para uso do software,
        cobrado pela CONTRATADA, será definido no momento da escolha do plano,
        conforme Anexo 1, e, pode sofrer modificações se a CONTRATANTE optar por
        outro plano, novos serviços, ou outra quantidade de licenças oferecida
        pela CONTRATADA.
      </p>
      <p>
        <strong>Parágrafo primeiro:</strong> Após o período de 12 meses de
        contrato, o valor da mensalidade será corrigido pelo IGPM/FGV acumulado
        dos últimos 12 meses.
      </p>
      <p>
        <strong>3.2 Condições de Pagamento</strong>
      </p>
      <p>
        O pagamento se dará através da quitação de boleto bancário emitido pela
        CONTRATADA e encaminhado via e-mail para o CONTRATANTE, ou através de
        cartão de crédito, mediante autorização do CONTRATANTE, conforme opção
        do mesmo.
      </p>
      <p>
        a) <strong>Boleto:</strong> o CONTRATANTE receberá via e-mail, de
        maneira automática, todas as faturas referentes a sua assinatura. As
        parcelas deverão ser pagas por meio de boleto bancário até a data de
        vencimento, evitando-se a incidência de mora de 1,00% ao mês (pro rata),
        e multa de 2,00% ao dia, sendo que após esse prazo incidirão
        integralmente tais encargos.
      </p>
      <p>
        b) <strong>Cartão de crédito:</strong> o CONTRATANTE que escolher a
        utilização do cartão de crédito, poderá optar por este formato de
        pagamento na contratação ou durante o período da vigência da assinatura.
        Para solicitar mais informações sobre o pagamento via cartão de crédito,
        envie suas perguntas pelo e-mail <strong>admin@eemovel.com.br</strong>{' '}
        ou pelo telefone
        <strong>(45) 3306-7401</strong>.
      </p>
      <p>
        <strong>Parágrafo Primeiro:</strong>
        Em caso de não pagamento dos valores referentes à mensalidade e/ou
        outros serviços, fica a CONTRATANTE sujeita a interrupção temporária ou
        o cancelamento definitivo dos serviços prestados pela CONTRATADA.
      </p>
      <p>
        <strong>Parágrafo Segundo:</strong> Passados 7 (sete) dias da
        mensalidade inadimplente, o acesso ao software será bloqueado e apenas
        liberado mediante pagamento da mensalidade.
      </p>
      <p>
        <strong>Parágrafo Terceiro:</strong>A CONTRATADA reserva para si, o
        direito de excluir todas as informações, históricos, dados
        mercadológicos, acessos e a própria conta da CONTRATANTE, quando esta
        ultrapassar 60 dias, inativa e inadimplente.
      </p>
      <p>
        <strong>5. OBRIGAÇÕES E RESPONSABILIDADES</strong>
      </p>
      <p>
        <strong>5.1 Obrigações da CONTRATADA</strong>
      </p>
      <p>
        a) É dever da CONTRATADA manter o sistema, bem como o site, online e de
        maneira atualizada, 24 horas por dia, 7 dias na semana, permitindo total
        utilização e acesso às informações por meio das credenciais de acesso
        (assinaturas), vinculadas ao plano contratado.
      </p>
      <p>
        b) Prestar os serviços através de pessoal capacitado, para que os mesmos
        sejam prestados dentro de um padrão de qualidade exigível pelo mercado.
      </p>
      <p>
        c) Oferecer suporte gratuito em relação ao uso da Plataforma. As
        requisições podem ser realizadas via e-mail no endereço
        <strong>contato@eemovel.com.br</strong>, ou via telefone, pelo número{' '}
        <strong>(45) 3306-7447</strong>. As respostas serão enviadas até o
        próximo dia útil e durante horário comercial (das 8am as 6pm - horário
        de Brasília).
      </p>
      <p>
        d) A CONTRATADA fornecerá o treinamento, o qual será agendado em
        apresentação a todos os funcionários pertinentes ao número de usuários
        contratados pela utilização da plataforma de maneira presencial ou
        ONLINE, onde será ministrado por um representante da CONTRATADA, com
        exemplos práticos das funcionalidades da plataforma.
      </p>
      <p>
        <strong>5.2 Obrigações da CONTRATANTE</strong>
      </p>
      <p>a) O CONTRATANTE se propõe a manter todas as mensalidades quitadas.</p>
      <p>
        b) No que se refere ao levantamento de dados sob encomenda, a
        CONTRATANTE deverá fornecer ao CONTRATADO todas as informações desejadas
        para a realização do serviço, devendo especificar os detalhes
        necessários à perfeita consecução do mesmo, e a forma de como ele deve
        ser entregue.
      </p>
      <p>
        c) É de responsabilidade da CONTRATANTE tomar todas as medidas de
        segurança para que seu pessoal e/ou terceiros não violem nenhum direito
        de propriedade intelectual da CONTRATADA, e comunicará à CONTRATADA,
        imediatamente, em caso de qualquer violação à propriedade intelectual de
        que venha a ter conhecimento.
      </p>
      <p>
        d) A CONTRATADA não terá obrigação em realizar customizações solicitadas
        pela CONTRATANTE, devendo estas solicitações serem, aprovadas
        previamente pela CONTRATADA.
      </p>
      <p>
        e) Toda e qualquer informação inserida no software será mantida sob
        absoluto sigilo, cabendo à CONTRATANTE zelar pela ética e manipulação
        segura de todas as informações. Qualquer informação poderá apenas ser
        entregue à CONTRATADA e portadora da licença principal.
      </p>
      <p>
        f) Além disso, a CONTRATANTE autoriza a CONTRATADA a usar seus dados de
        imóveis e de utilização da plataforma para realizar estudos estatísticos
        internos e outros que poderão ser publicados de forma agregada em
        diferentes mídias públicas. De qualquer forma, seus dados individuais
        jamais poderão ser identificados publicamente, mantendo assim suas
        informações privadas, seguras e anonimizadas.
      </p>
      <p>
        g) Se a CONTRATANTE identificar quebra de códigos fontes, invasão de
        servidores ou usuários que não sejam os próprios definidos pela
        CONTRATADA, revenda, demonstrações por parte da CONTRATANTE a
        concorrentes diretos ou indiretos, fica estipulada multa contratual a
        ser mensurada pelo dano causado a CONTRATADA.
      </p>
      <p>
        <strong>6. PRAZO E RESCISÃO</strong>
      </p>
      <p>
        Poderá o presente instrumento ser rescindido por qualquer uma das
        partes, em qualquer momento após o período exposto no Parágrafo Segundo
        do Item 1, mediante aviso prévio de 30 dias, sem que ocorram quaisquer
        custos envolvidos.
      </p>
      <p>
        <strong>7. CONFIDENCIALIDADE E PROTEÇÃO DOS DADOS</strong>
      </p>
      <p>
        a) Cada parte se compromete a manter e tratar como confidencial e não
        revelar a terceiros qualquer informação Confidencial relacionada a
        Plataforma e aos serviços, dados de usuários, segredo de indústria e
        outros.
      </p>
      <p>
        b) Não obstante o disposto neste Contrato, as informações Confidenciais
        poderão ser reveladas nas seguintes hipóteses: exigência legal
        aplicável, ordem ou decisão judicial ou em processo administrativo ou
        arbitral, ou solicitação de qualquer autoridade ou órgão regulador do
        Brasil.
      </p>
      <p>
        c) As partes reconhecem que qualquer quebra das obrigações de
        confidencialidade deste Contrato poderá causar danos à outra parte em
        valor não prontamente mensurável. Sendo assim, as partes concordam, sem
        prejuízo a outros direitos ou medidas cabíveis, que a parte infratora
        deverá reparar a parte reveladora dos danos efetivamente sofridos por
        esta.padrão
      </p>
      <p>
        d) A obrigação de confidencialidade aqui prevista permanecerá vigente
        enquanto perdurar o caráter de confidencialidade, que não possui período
        determinado de expiração.
      </p>
      <p>
        e) A CONTRATADA declara que garante e assegura o tratamento adequado dos
        dados pessoais sensíveis, financeiros e de propriedade ao quais tem
        acesso, estando ciente das regras e obrigações estabelecidas no
        ordenamento jurídico brasileiro acerca da Proteção de dados pessoais,
        estando preparada para atender a Lei de Proteção de dados que entrará em
        vigor em agosto de 2020, sendo a única responsável pelos dados que
        disponibiliza a CONTRATANTE.
      </p>
      <p>
        <strong>8. DISPOSIÇÕES GERAIS</strong>
      </p>
      <p>
        <strong>CLÁUSULA PRIMEIRA:</strong> A CONTRATADA não possui qualquer
        responsabilidade perante o uso final das informações obtidas pelo
        CONTRATANTE no uso da plataforma.
      </p>
      <p>
        <strong>CLÁUSULA SEGUNDA:</strong> A Parte que der ensejo à ação
        judicial para discutir qualquer disposição constante no presente
        Contrato deverá arcar com as custas judiciais iniciais, bem como com
        eventual perícia que vier a ser requerida, independente da Parte que
        peça, inclusive do próprio Juízo, sem prejuízo de ser ressarcida.
      </p>
      <p>
        <strong>CLÁUSULA TERCEIRA:</strong> Os termos deste contrato ou os
        Termos e Condições de Uso, podem ser, e provavelmente serão, revisados
        de tempos em tempos, e a versão mais atualizada será publicada em nosso
        site.
      </p>
      <p>
        <strong>CLÁUSULA QUARTA:</strong> A CONTRATADA reserva o direito de
        modificar as condições apresentadas neste contrato, a qualquer tempo,
        por meio de atualização no próprio site, bem como por notificação por
        e-mail à CONTRATANTE. Caso a CONTRATANTE continue acessando ou usando o
        sistema após as atualizações entrarem em vigor, fica entendido que você
        concorda em seguir os termos atualizados.
      </p>
      <p>
        <strong>CLÁUSULA QUINTA:</strong> Fica compactuado entre as partes a
        total inexistência de vínculo trabalhista entre as partes contratantes,
        excluindo as obrigações previdenciárias e os encargos sociais, não
        havendo entre CONTRATADO e CONTRATANTE qualquer tipo de relação de
        subordinação.
      </p>
      <p>
        <strong>9. FORO</strong>
      </p>
      <p>
        Para dirimir quaisquer controvérsias oriundas do presente contrato, as
        partes elegem o foro da comarca de Cascavel, no Estado do Paraná, com
        renúncia a qualquer outro, por mais privilegiado que seja ou venha a
        sê-lo.
      </p>
      <p>
        Por estarem assim justos e contratados, firmam o presente instrumento.
      </p>
      <p>Cascavel, {getFormatedDate()}.</p>
      <p>
        <strong>Anexo 1</strong>
      </p>
      <p>
        <strong>Característica do plano contratado:</strong>
      </p>
      <div className="Contract__Table">
        <div className="Contract__Row">
          <div className="Contract__Col">Plano Contratado</div>
          <div className="Contract__Col">Quantidade</div>
          <div className="Contract__Col">Valor</div>
        </div>
        <div className="Contract__Row">
          <div className="Contract__Col">{plan.name}</div>
          <div className="Contract__Col">1</div>
          <div className="Contract__Col">
            R$ {formatToLocale(plan.price.toFixed(2))}
          </div>
        </div>
        {discount && (
          <div className="Contract__Row">
            <div className="Contract__Col">Desconto</div>
            <div className="Contract__Col">1</div>
            <div className="Contract__Col">
              - R$ {formatToLocale(discount.toFixed(2))}
            </div>
          </div>
        )}
        <div className="Contract__Row">
          <div className="Contract__Col Contract__Col--ft">Total</div>
          <div className="Contract__Col">
            {discount
              ? `R$ ${formatToLocale((plan.price - discount).toFixed(2))}`
              : `R$ ${formatToLocale(plan.price.toFixed(2))}`}
          </div>
        </div>
      </div>
      <p>Dados do contrato válidos a partir do dia {getFormatedDate()}</p>
    </div>
  )
}

export default Contract
