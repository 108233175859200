import React from 'react'
import classNames from 'classnames'
import GoogleMapReact from 'google-map-react'
import PropTypes from 'prop-types'
import { MAP_MOBILE as MAP } from 'constants/map'
import './index.css'

const SearchMap = ({
  className,
  defaultCenter,
  defaultZoom,
  center,
  zoom,
  children,
  maxZoom,
  useMapsApi,
  onChange,
  streetViewControl,
  onGoogleApiLoaded,
  viewportHeight,
  hasSearch,
}) => {
  const classes = classNames('SearchMapMobile', className, {
    'SearchMapMobile--active': hasSearch,
  })
  return (
    <div className={classes} name="mapContainer" id="mapContainer">
      <GoogleMapReact
        bootstrapURLKeys={{
          libraries: ['drawing', 'geometry'],
          key: process.env.REACT_APP_GOOGLE_MAP_KEY,
        }}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        onChange={onChange}
        yesIWantToUseGoogleMapApiInternals={useMapsApi}
        onGoogleApiLoaded={onGoogleApiLoaded}
        center={center}
        zoom={zoom}
        rotateControl={false}
        resetBoundsOnResize
        options={{
          clickableIcons: false,
          // resetBoundsOnResize: true,
          gestureHandling: 'greedy',
          maxZoom,
          streetViewControl,
        }}
      >
        {children}
      </GoogleMapReact>
    </div>
  )
}

SearchMap.propTypes = {
  className: PropTypes.string,
  defaultCenter: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.object,
  ]),
  defaultZoom: PropTypes.number,
  center: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.object,
  ]),
  zoom: PropTypes.number,
}

SearchMap.defaultProps = {
  defaultCenter: MAP.defaultCenter,
  defaultZoom: MAP.defaultZoom,
  center: null,
  zoom: null,
}

export default SearchMap
