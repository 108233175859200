import axios from 'axios'
import { userInfo, headers } from 'services/auth'

const API_URL = process.env.REACT_APP_API_URL
const DISCOUNT_URL = `${API_URL}/pagamento/desconto`
const CHECKOUT_URL = `${API_URL}/pagamento/checkout`
const ARRIVED_AT_CHECKOUT = `${API_URL}/contato/enviar?`

export const getDiscount = code =>
  axios.get(`${DISCOUNT_URL}/${code}`, { headers })

export const checkout = payload =>
  axios.post(CHECKOUT_URL, payload, { headers })

export const arrivedAtCheckout = () => {
  const { username, email } = userInfo()
  const message = `<br/> <br/> Email: ${email}<br/> Nome: ${username}<br/>`
  const subject = `${email} entrou no checkout!`
  const urlParam = `assunto=${subject}&nome=${username}&email=contato@eemovel.com.br&mensagem=${message}`
  const payload = {
    assunto: subject,
    email: 'contato@eemovel.com.br',
    mensagem: message,
    nome: username,
  }

  return axios.post(`${ARRIVED_AT_CHECKOUT}${urlParam}`, payload, { headers })
}
