import React, { PureComponent } from 'react'
import { fitBounds } from 'google-map-react/utils'
import I18n from 'locales'
import { Grid, Row, Col } from 'components/Grid'
import SearchSvgIcon from 'components/SvgIcons/SearchSvgIcon'
import { SearchConsumer } from 'context/Search'
import FilterBar from 'components/Search/FilterBar'
import { AutoComplete } from 'components/Form'
import { debounce, deburr, compact } from 'lodash'
import * as auth from 'services/auth'
import './index.css'

class SearchBar extends PureComponent {
  constructor() {
    super()
    this.state = {
      suggestions: [],
      query: '',
    }
  }

  getAddressSuggestions = async () => {
    const { query } = this.state
    const { cities } = this.context

    this.setState({ loading: true })

    let suggestions = cities.map(city => {
      if (
        deburr(city.name.toLowerCase().trim()).includes(
          deburr(query.toLowerCase().trim()),
        )
      ) {
        return {
          name: `${city.name}, ${city.state}`.trim(),
          value: city.id,
          types: ['eemovel_local'],
        }
      }
      return null
    })

    suggestions = compact(suggestions)

    if (suggestions.length > 0) {
      this.setState({ suggestions })
    } else {
      let verifiedQuery =
        deburr(query.toLowerCase()) === 'sao paulo'
          ? `${query}, sp`
          : deburr(query.toLowerCase()) === 'rio de janeiro'
          ? `${query}, rj`
          : query

      await this.context.mapService.geocode(verifiedQuery, results => {
        this.setState({
          suggestions: results.map(result => ({
            identifiers: result.address_components
              .map(item => item.short_name)
              .join(' ')
              .toLowerCase()
              .trim(),
            name: result.formatted_address,
            value: result.geometry,
            types: result.types,
          })),
        })
      })
    }
  }

  debouncedAddressSuggestions = debounce(this.getAddressSuggestions, 500)

  setAddress = (event, { suggestion }) => {
    const { value, types, identifiers } = suggestion
    const { cities, city } = this.context

    if (
      types.includes('locality') ||
      types.includes('administrative_area_level_2')
    ) {
      let newCityId = auth.cityId()

      this.context.handleGeocodeCallback(
        'OK',
        fitBounds(
          {
            ne: {
              lat: value.viewport.getNorthEast().lat(),
              lng: value.viewport.getNorthEast().lng(),
            },
            sw: {
              lat: value.viewport.getSouthWest().lat(),
              lng: value.viewport.getSouthWest().lng(),
            },
          },
          {
            width: this.context.mapService.map.getDiv().clientWidth,
            height: this.context.mapService.map.getDiv().clientHeight,
          },
        ),
      )
      cities.forEach(city => {
        if (
          identifiers.includes(deburr(city.state.toLowerCase().trim())) &&
          identifiers.includes(deburr(city.name.toLowerCase().trim()))
        ) {
          newCityId = Number(city.id)
          return true
        }
      })
      if (newCityId === auth.cityId()) {
        window.M.toast({
          html: `Cidade não consta na nossa base, voltando para ${
            city ? `${city.name} - ${city.state}` : 'São Paulo - SP'
          }`,
          classes: 'Search__Toast--failure',
        })
      }
      this.context.changeCity(newCityId || 5270)
    } else if (types.includes('eemovel_local')) {
      this.context.changeCity(value ? value : 5270)
    } else {
      this.context.setRadiusInfo(value)
      this.context.mapService.createCircle({
        lat: value.location.lat(),
        lng: value.location.lng(),
      })
    }
  }

  render() {
    const { suggestions, query } = this.state
    return (
      <React.Fragment>
        <Grid fluid>
          <Row center="xs" middle="xs">
            <Col xs>
              <Row middle="xs" between="xs">
                <Col
                  lg={4}
                  md={12}
                  className="SearchBar__ColumnInput"
                  start="xs"
                >
                  <SearchSvgIcon
                    width={30}
                    height={30}
                    className="SearchBar__Column__Icon"
                  />
                  <AutoComplete
                    disabled={!this.context.mapService || !this.context.cities}
                    autoComplete="off"
                    name="search"
                    value={query}
                    suggestions={suggestions}
                    onSuggestionSelected={this.setAddress}
                    onChange={(event, { newValue }) => {
                      this.setState({ query: newValue }, () =>
                        this.debouncedAddressSuggestions(),
                      )
                    }}
                    classes={{
                      inputComponent: 'SearchBar__ColumnInput__Input',
                      optionContainer: 'SearchBar__OptionContainer',
                    }}
                    placeholder={I18n.t('components.searchBar.placeholder')}
                  />
                </Col>
                <Col lg={8} md={12}>
                  <FilterBar />
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>
      </React.Fragment>
    )
  }
}
SearchBar.contextType = SearchConsumer
export default SearchBar
