import React from 'react'
import FreshChat from 'react-freshchat'
import { userInfo } from 'services/auth'

class Chat extends React.Component {
  render() {
    return (
      <FreshChat
        token="994446d3-f85f-414e-b03f-8a8555babfe1"
        firstName={userInfo().username || null}
        email={userInfo().email || null}
        config={{
          cssNames: {
            widget: 'custom_fc_frame',
            open: 'custom_fc_open',
            expanded: 'custom_fc_expanded',
          },
          headerProperty: {
            backgroundColor: '#b18935',
          },
        }}
        onInit={widget => {
          widget.user.setProperties({
            firstName: userInfo().username || null,
            email: userInfo().email || null,
          })
        }}
      />
    )
  }
}

export default Chat
