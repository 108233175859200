// import { fitBounds } from 'google-map-react/utils'
import Supercluster from 'supercluster'
import concaveman from 'concaveman'
import * as auth from 'services/auth'
import { removeArrayDuplicates as removeDuplicates } from 'utils/removeArrayDuplicates'
import { MAP } from 'constants/map'
import { COLORS } from 'constants/colors'

export default class Map {
  constructor(props) {
    const { map, maps } = props
    this.map = map
    this.maps = maps
    this.clusterExists = false
    this.geocoder = new this.maps.Geocoder()
    this.polygons = []
    this.loadedNeighborhoods = {}
    this.selectedNeighborhoods = []
    this.isSelectingNeighborhoods = false
    this.loadedZones = {}
    this.freehandListener = null
    this.drawingManager = new this.maps.drawing.DrawingManager({
      drawingControl: false,
      polygonOptions: {
        editable: false,
      },
      polylineOptions: {
        clickable: false,
      },
    })

    this.drawingManager.setMap(this.map)

    this.maps.event.addListener(
      this.drawingManager,
      'polygoncomplete',
      this.savePolygon,
    )

    this.supercluster = new Supercluster({
      radius: 150,
      maxZoom: props.mobile ? 18 : 20,
    })

    this.enableMapMovement()

    //define polygon getBounds
    if (!this.maps.Polygon.prototype.getBounds) {
      this.maps.Polygon.prototype.getBounds = function() {
        var bounds = new maps.LatLngBounds()
        this.getPath().forEach(function(element, index) {
          bounds.extend(element)
        })
        return bounds
      }
    }

    if (props.mobile) {
      this.mouseDownEvent = 'touchstart'
    } else {
      this.mouseDownEvent = 'mousedown'
    }
  }

  changeToRoadmapView() {
    this.map.setMapTypeId('roadmap')
  }

  changeToSatelliteView() {
    this.map.setMapTypeId('satellite')
  }

  decodePath(path) {
    return this.maps.geometry.encoding.decodePath(path)
  }

  encodePath(path) {
    return this.maps.geometry.encoding.encodePath(path)
  }

  clearClusters() {
    this.clusterExists = false
  }

  createLatLng({ lat, lng }) {
    return new this.maps.LatLng({ lat, lng })
  }

  initializeStreetView(fenway, divId, handleStreetViewChange) {
    const panorama = new this.maps.StreetViewPanorama(
      document.getElementById(divId),
      {
        position: fenway,
        pov: {
          heading: 165,
          pitch: 0,
          zoom: 1,
        },
      },
    )
    this.maps.event.addListener(panorama, 'pov_changed', () => {
      const panoInfo = panorama.getPov()
      const pitch = panoInfo['pitch']
      const heading = panoInfo['heading']
      handleStreetViewChange(fenway.lat, fenway.lng, heading, pitch)
    })

    handleStreetViewChange(
      fenway.lat,
      fenway.lng,
      panorama.getPov()['heading'],
      panorama.getPov()['pitch'],
    )
  }

  clearPolygons() {
    if (this.polygons.length > 0) {
      this.polygons.forEach(polygon => {
        polygon.setVisible(false)
      })
      this.polygons = []
    }
  }

  createCircle = (center, radius = 250) => {
    const circle = new this.maps.Circle({
      strokeColor: COLORS.gray28,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: COLORS.gray28,
      fillOpacity: 0.35,
      map: this.map,
      center,
      radius,
    })
    this.clearMap()
    this.savePolygon(circle)
  }

  clearMap() {
    for (const cityId in this.loadedNeighborhoods) {
      if (this.loadedNeighborhoods.hasOwnProperty(cityId)) {
        this.hideNeighborhoods(cityId)
      }
    }
    for (const cityId in this.loadedZones) {
      if (this.loadedZones.hasOwnProperty(cityId)) {
        this.hideZones(cityId)
      }
    }
    this.clearPolygons()
  }

  createGeometryFromPath(path) {
    const options = {
      strokeColor: COLORS.gray28,
      fillColor: COLORS.gray28,
      fillOpacity: 0.1,
      strokeWeight: 2,
    }

    const polygon = this.createPolygonFromPath({ path }, options)
    polygon.setMap(this.map)
    polygon.setVisible(true)

    this.savePolygon(polygon)

    const latLng = polygon
      .getBounds()
      .getCenter()
      .toJSON()
    return latLng
  }

  createPolygonFromPath(polygon, options, callback = null) {
    const { path, name } = polygon
    const poly = new this.maps.Polygon({
      ...options,
      htmlContent: name,
      path,
    })

    if (name) {
      const latLng = poly
        .getBounds()
        .getCenter()
        .toJSON()
      this.addDefaultListenersToPolygon(poly, latLng, callback)
    }

    return poly
  }

  addDefaultListenersToPolygon(polygon, latLng, callback = null) {
    polygon.infoWindow = new this.maps.InfoWindow({
      disableAutoPan: true,
      pixelOffset: {
        height: -10,
        width: 0,
      },
      content: polygon.htmlContent,
    })
    this.maps.event.addListener(polygon, 'mouseover', () => {
      polygon.setOptions({ fillOpacity: 0.2 })
      polygon.infoWindow.setPosition(latLng)
      polygon.infoWindow.open(this.map)
    })

    this.maps.event.addListener(polygon, 'mouseout', () => {
      polygon.setOptions({ fillOpacity: 0.1 })
      polygon.infoWindow.close()
    })

    this.maps.event.addListener(polygon, 'click', event => {
      const index = this.selectedNeighborhoods.indexOf(polygon)
      if (index === -1) {
        polygon.setOptions({
          strokeColor: COLORS.leather,
          fillColor: COLORS.leather,
          fillOpacity: 0.1,
          strokeWeight: 5,
        })
        this.selectedNeighborhoods.push(polygon)
      } else {
        polygon.setOptions({
          strokeColor: COLORS.gray28,
          fillColor: COLORS.gray28,
          fillOpacity: 0.1,
          strokeWeight: 2,
        })
        this.selectedNeighborhoods.splice(index, 1)
      }
      this.isSelectingNeighborhoods = this.selectedNeighborhoods.length !== 0
      callback && callback(event)
    })
  }

  stopSelecting() {
    this.clearMap()
    this.selectedNeighborhoods.forEach(neighborhood => {
      neighborhood.setOptions({
        strokeColor: COLORS.gray28,
        fillColor: COLORS.gray28,
        fillOpacity: 0.1,
        strokeWeight: 2,
      })
      this.savePolygon(
        new this.maps.Polygon({
          map: this.map,
          clickable: false,
          strokeOpacity: 1,
          strokeWeight: 2,
          strokeColor: COLORS.gray28,
          fillColor: COLORS.gray28,
          fillOpacity: 0.1,
          path: neighborhood.getPath(),
        }),
      )
    })
    this.isSelectingNeighborhoods = false
  }

  drawPolygon() {
    this.clearMap()
    this.drawingManager.setDrawingMode(this.maps.drawing.OverlayType.POLYGON)
  }

  drawFreeHand() {
    this.clearMap()
    this.map.setOptions({ draggableCursor: 'crosshair' })
    this.freehandListener = this.maps.event.addDomListener(
      this.map.getDiv(),
      this.mouseDownEvent,
      event => {
        if (!event.ctrlKey) {
          this.disableMapMovement()
          this.map.setOptions({ draggableCursor: 'crosshair' })
        } else {
          this.enableMapMovement()
        }
        let polyLine = new this.maps.Polyline({
          map: this.map,
          clickable: false,
          strokeColor: COLORS.gray28,
          strokeOpacity: 1,
        })

        const move = this.maps.event.addListener(
          this.map,
          'mousemove',
          event => {
            polyLine.getPath().push(event.latLng)
          },
        )

        this.maps.event.addListenerOnce(this.map, 'mouseup', event => {
          this.maps.event.removeListener(move)
          this.drawingManager.setDrawingMode(null)
          polyLine.setMap(null)

          const path = polyLine.getPath()
          const pathArray = path
            .getArray()
            .map(item => [item.lat(), item.lng()])

          if (pathArray.length) {
            path.clear()
            concaveman(pathArray).forEach(item =>
              path.push(
                new this.maps.LatLng({
                  lat: Number(item[0].toFixed(5)),
                  lng: Number(item[1].toFixed(5)),
                }),
              ),
            )
          }

          this.savePolygon(
            new this.maps.Polygon({
              map: this.map,
              clickable: false,
              strokeOpacity: 1,
              strokeColor: COLORS.gray28,
              fillColor: COLORS.gray28,
              fillOpacity: 0.1,
              path,
            }),
          )
        })
      },
    )
  }

  toggleMapMovement = () => {
    if (this.map.draggable) {
      this.disableMapMovement()
    } else {
      this.enableMapMovement()
    }
  }

  disableMapMovement = () => {
    this.map.setOptions({
      draggable: false,
      zoomControl: false,
      scrollwheel: false,
    })
  }

  enableMapMovement = () => {
    this.map.setOptions({
      draggable: true,
      draggableCursor: 'default',
      zoomControl: true,
      scrollwheel: true,
    })
  }

  getClusters({ bounds, zoom }) {
    let boundsArray = []
    if (bounds) {
      boundsArray[0] = bounds.sw.lng
      boundsArray[1] = bounds.sw.lat
      boundsArray[2] = bounds.ne.lng
      boundsArray[3] = bounds.ne.lat
    }
    if (
      !boundsArray[0] ||
      !boundsArray[1] ||
      !boundsArray[2] ||
      !boundsArray[3]
    ) {
      boundsArray = MAP.defaultBounds
    }

    return this.supercluster.getClusters(boundsArray, zoom)
  }

  getClusterExpansionZoom(id) {
    return this.supercluster.getClusterExpansionZoom(id)
  }

  getClusterLeaves(id, size = Infinity) {
    return this.supercluster.getLeaves(id, size)
  }

  getPolygons() {
    return this.polygons
  }

  getPolygonsAsArray() {
    const circlePath = circle => {
      const numPts = 1000
      let path = []
      for (let i = 0; i < numPts; i++) {
        path.push(
          this.maps.geometry.spherical.computeOffset(
            circle.getCenter(),
            circle.getRadius(),
            (i * 360) / numPts,
          ),
        )
      }
      return path
    }
    let closedPolygonsArray = this.getPolygons().map(polygon => {
      let polygonsArray = removeDuplicates(
        polygon.getRadius
          ? circlePath(polygon).map(item => [item.lng(), item.lat()])
          : polygon
              .getPath()
              .getArray()
              .map(item => [item.lng(), item.lat()]),
      )
      if (
        polygonsArray.length > 1 &&
        polygonsArray[0].toString() !==
          polygonsArray[polygonsArray.length - 1].toString()
      ) {
        polygonsArray.push(polygonsArray[0])
      }
      return polygonsArray
    })
    return closedPolygonsArray
  }

  geocode(address, callback) {
    this.geocoder.geocode(
      { address, region: 'BR', language: 'pt-BR' },
      callback,
    )
    // if (callback)
    //   this.geocoder.geocode({ address }, (results, status) =>
    //     callback(
    //       status,
    //       fitBounds(
    //         {
    //           ne: {
    //             lat: results[0].geometry.viewport.getNorthEast().lat(),
    //             lng: results[0].geometry.viewport.getNorthEast().lng(),
    //           },
    //           sw: {
    //             lat: results[0].geometry.viewport.getSouthWest().lat(),
    //             lng: results[0].geometry.viewport.getSouthWest().lng(),
    //           },
    //         },
    //         {
    //           width: this.map.getDiv().clientWidth,
    //           height: this.map.getDiv().clientHeight,
    //         },
    //       ),
    //     ),
    //   )
  }

  hideNeighborhoods(cityId = auth.getCityIdLocalStorage()) {
    this.loadedNeighborhoods[cityId].forEach(neighborhood => {
      neighborhood.setVisible(false)
    })
  }

  hideZones(cityId = auth.getCityIdLocalStorage()) {
    this.loadedZones[cityId].forEach(zone => {
      zone.setVisible(false)
    })
  }

  loadClusters(clusters) {
    this.supercluster.load(clusters)
    this.clusterExists = true
  }

  loadNeighborhoods(
    neighborhoods,
    onClick,
    cityId = auth.getCityIdLocalStorage(),
  ) {
    const options = {
      strokeOpacity: 1,
      strokeWeight: 2,
      fillOpacity: 0.1,
      strokeColor: COLORS.gray28,
      fillColor: COLORS.gray28,
    }
    this.loadedNeighborhoods[cityId] = neighborhoods.map(neighborhood =>
      this.createPolygonFromPath(neighborhood, options, onClick),
    )

    this.renderNeighborhoods(cityId)
  }

  addPolygonFromPath = path => {
    const options = {
      strokeOpacity: 1,
      strokeWeight: 2,
      fillOpacity: 0.1,
      strokeColor: COLORS.gray28,
      fillColor: COLORS.gray28,
    }
    const polygon = this.createPolygonFromPath(path, options)

    this.resetMap()
    polygon.setMap(this.map)
    polygon.setVisible(true)
    this.savePolygon(polygon)
  }

  loadZones(zones, onClick, cityId = auth.getCityIdLocalStorage()) {
    const options = {
      strokeOpacity: 1,
      strokeWeight: 2,
      fillOpacity: 0.1,
      strokeColor: COLORS.gray28,
      fillColor: COLORS.gray28,
    }
    this.loadedZones[cityId] = zones.map(zone =>
      this.createPolygonFromPath(zone, options, onClick),
    )
    this.renderZones()
  }

  renderNeighborhoods(cityId = auth.getCityIdLocalStorage()) {
    this.loadedNeighborhoods[cityId] &&
      this.loadedNeighborhoods[cityId].forEach(neighborhood => {
        if (!neighborhood.getMap()) {
          neighborhood.setMap(this.map)
          return
        }
        neighborhood.setVisible(true)
      })
  }

  renderZones(cityId = auth.getCityIdLocalStorage()) {
    this.loadedZones[cityId] &&
      this.loadedZones[cityId].forEach(zone => {
        if (!zone.getMap()) {
          zone.setMap(this.map)
          return
        }
        zone.setVisible(true)
      })
  }

  savePolygon = polygon => {
    this.polygons.push(polygon)
  }

  stopDrawing() {
    this.freehandListener &&
      this.maps.event.removeListener(this.freehandListener)
    this.maps.event.clearListeners(this.map.getDiv(), this.mouseDownEvent)
    this.drawingManager.setDrawingMode(null)
    this.enableMapMovement()
  }

  resetMap() {
    this.clearClusters()
    this.clearMap()
    this.enableMapMovement()
    this.selectedNeighborhoods = []
  }
}
