import React from 'react'
import './index.css'

const Notification = ({ height = 16, width = 16 }) => {
  return (
    <svg
      version="1.1"
      id="Camada_1"
      className="NotificationIcon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      height={height}
      width={width}
      viewBox="0 0 20 20"
      style={{ enableBackground: 'new 0 0 20 20' }}
      xmlSpace="preserve"
    >
      <line
        id="XMLID_1_"
        fill="#797E85"
        fillRule="evenodd"
        className="stx"
        x1="0"
        y1="17.8"
        x2="20"
        y2="17.8"
      />
      <path
        id="XMLID_2_"
        className="stx"
        fill="#797E85"
        fillRule="evenodd"
        d="M0.4,17.6c0.9-1.1,1.7-2.2,2.6-3.4"
      />
      <path
        id="XMLID_3_"
        fill="#797E85"
        fillRule="evenodd"
        className="stx"
        d="M19.6,17.6c-0.8-1.1-1.6-2.2-2.4-3.3"
      />
      <path
        id="XMLID_4_"
        fill="#797E85"
        fillRule="evenodd"
        className="stx"
        d="M2.9,14.5V6.9c0.5-3.5,3.3-6.1,6.7-6.3c3.7-0.2,7.2,2.5,7.7,6.4"
      />
      <line
        id="XMLID_5_"
        fill="#797E85"
        fillRule="evenodd"
        className="stx"
        x1="17.3"
        y1="14.5"
        x2="17.3"
        y2="6.6"
      />
      <path id="XMLID_8_" className="stx" d="M12.2,19.1c-1.4,0-2.8,0-4.3,0" />
      <path id="XMLID_6_" className="stx" d="M28,11.7" />
    </svg>
  )
}

export default Notification
