import React, { PureComponent } from 'react'
import classNames from 'classnames'
import { Grid, Row, Col } from 'components/Grid'
import FilterBusinessType from 'components/Search/FilterBusinessType'
import { Button } from 'components/Buttons'
import FilterMore from 'components/Search/FilterMore'
import FilterPriceRange from 'components/Search/FilterPriceRange'
import FilterCategory from 'components/Search/FilterCategory'
import FilterNeighborhood from 'components/Search/FilterNeighborhood'
import { SearchConsumer } from 'context/Search'
import { getSearchParams } from 'constants/search'
import { userId } from 'services/auth'
import I18n from 'locales'
import './index.css'

class FilterBar extends PureComponent {
  renderActionButton = (
    clearFilters,
    currentSearch,
    setPrevSearch,
    prevSearch,
    { businessType, category },
    polygons,
  ) => {
    const defaultParams = JSON.stringify(getSearchParams())
    const parsedSearch = JSON.parse(currentSearch)
    if (defaultParams !== currentSearch && currentSearch !== prevSearch) {
      return (
        <Button
          btnStyle="primary"
          className="FilterBar__Btn"
          disabled={
            businessType ||
            category ||
            parsedSearch.drawing ||
            polygons.length === 0
          }
          onClick={() => setPrevSearch({ ...parsedSearch })}
        >
          {I18n.t('components.actions.search')}
        </Button>
      )
    } else {
      return (
        <Button
          disabled={defaultParams === currentSearch}
          btnStyle="link"
          className="FilterBar__Btn"
          onClick={clearFilters}
        >
          {I18n.t('components.actions.clearFilters')}
        </Button>
      )
    }
  }

  render() {
    const { className } = this.props
    const classes = classNames('FilterBar', className)
    return (
      <SearchConsumer>
        {({
          onSubmit,
          clearFilters,
          filters,
          query,
          visualization,
          setPrevSearch,
          prevSearch,
          error,
          polygons,
          openDwellers,
          goToStep,
          loadedNeighborhoods,
          mapService,
          clearClusters,
          currentCity,
        }) => (
          <Row middle="lg" center="lg" className={classes}>
            <Col lg={12}>
              <Grid className="FilterBar__Container">
                <Row between="xs">
                  <Col className="FilterBar__Container__Filters">
                    <FilterBusinessType
                      businessType={filters.businessType}
                      onSubmit={onSubmit}
                      error={error}
                    />
                    <FilterCategory
                      category={filters.category}
                      onSubmit={onSubmit}
                      error={error}
                      goToStep={goToStep}
                    />
                    <FilterPriceRange
                      priceRange={filters.priceRange}
                      onSubmit={onSubmit}
                    />
                    <FilterMore more={filters.more} onSubmit={onSubmit} />
                    <FilterNeighborhood
                      loadedNeighborhoods={loadedNeighborhoods}
                      mapService={mapService}
                      clearClusters={clearClusters}
                      onSearch={() =>
                        setPrevSearch({
                          query,
                          ...filters,
                          visualization,
                        })
                      }
                    />
                    {userId() !== 14801 && (
                      <Button
                        btnStyle="link"
                        className="FilterButton__Btn"
                        onClick={() => {
                          openDwellers()
                        }}
                        data-tut="step-eleven"
                      >
                        {currentCity &&
                        currentCity.eemovelProperties &&
                        currentCity.eemovelProperties.owners
                          ? I18n.t('components.filters.owners')
                          : currentCity && !currentCity.eemovelProperties
                          ? I18n.t('components.filters.owners')
                          : I18n.t('components.filters.dwellers')}
                      </Button>
                    )}
                  </Col>
                  <Col className="FilterBar__Container__Filters">
                    {this.renderActionButton(
                      clearFilters,
                      JSON.stringify({
                        query,
                        ...filters,
                        visualization,
                      }),
                      setPrevSearch,
                      prevSearch,
                      error,
                      polygons,
                    )}
                  </Col>
                </Row>
              </Grid>
            </Col>
          </Row>
        )}
      </SearchConsumer>
    )
  }
}

export default FilterBar
