import React, { Component } from 'react'
import Helmet from 'react-helmet'
import Marker from 'components/Marker'
import ClusterMarker from 'components/ClusterMarker'
import MobilePageBase from 'components/MobilePageBase'
import MobileSearchBar from 'components/MobileSearch/SearchBar'
import MobileSearchMap from 'components/MobileSearch/Map'
import DrawButton from 'components/MobileSearch/Map/DrawButton'
import CancelButton from 'components/MobileSearch/Map/CancelButton'
import SearchClient from 'services/search'
import FilterBar from 'components/MobileSearch/FilterBar'
import {
  businessTypeEnum,
  getSearchParams as SEARCH,
  REALTY_OPTIONS,
} from 'constants/search'
import MapService from 'services/map'
import { getPaymentStatus } from 'services/user'
import I18n from 'locales'
import { SearchProvider } from 'context/Search'
import { Row, Col } from 'components/Grid'
import { Button } from 'components/Buttons'
import Toast from 'components/Toast'
import AlertContainer from 'components/MobileAlertContainer'
import NotificationIcon from 'components/SvgIcons/NotificationSvgIcon'
import { ALL } from 'constants/categories'
import { getOptionalsByCategory } from 'services/filter'
import Dwellers from 'components/Dwellers'
import MarkerContainer from 'components/MarkerContainer'
import InfoWindow from 'components/InfoWindow'
import Spinner from 'components/Spinner'
import { ShowRealtyPaginated as ShowRealty } from 'components/ShowRealty'
import { RealtyProvider } from 'context/Realty'
import { MAP_MOBILE as MAP } from 'constants/map'
import Chat from 'components/Chat'
import { Formik } from 'components/Form'
import classNames from 'classnames'
import Statistics from 'components/Statistics'
import {
  cityId,
  getCityIdLocalStorage,
  setCityIdLocalStorage,
  userId,
} from 'services/auth'
import { callRequest } from 'services/requestHandler'
import { formatRealties } from 'utils/formatRealties'
import { getCityById, getCities } from 'services/location'
import './index.css'

class MobileSearchPage extends Component {
  constructor(props) {
    super(props)
    this.client = new SearchClient()
    this.state = {
      search: SEARCH(),
      clusters: [],
      prevSearch: JSON.stringify({ ...SEARCH() }),
      mapOptions: {},
      error: {
        category: false,
        businessType: false,
      },
      toast: false,
      alert: false,
      optionalsFilter: [],
      dwellers: false,
      showRealty: false,
      loading: false,
      realties: [],
      pristine: true,
      radiusInfo: false,
      selectedAddress: {},
      neighborhoodStatistics: [],
      loadedNeighborhoods: [],
      statistics: false,
    }
    this.searching = false
    this.idList = []
    this.city = null
    this.baseCity = null
    this.cities = []

    !getCityIdLocalStorage() && setCityIdLocalStorage(cityId())
  }

  componentDidMount() {
    getPaymentStatus()
    this.getCities()
  }

  componentDidUpdate(prevProps, prevState) {
    this.shouldOptionalsUpdate(prevState)
  }

  getCities = () => {
    getCities().then(({ data }) => (this.cities = data))
  }

  shouldOptionalsUpdate(prevState) {
    let currentCategories = []
    let prevCategories = []
    if (this.state.search.category) {
      if (Object.keys(this.state.search.category).length) {
        Object.keys(this.state.search.category).forEach(key => {
          if (this.state.search.category[key] && !key.includes('Filter'))
            currentCategories.push(ALL[key].name)
        })
      }

      if (prevState.search.category) {
        Object.keys(prevState.search.category).forEach(key => {
          if (prevState.search.category[key] && !key.includes('Filter'))
            prevCategories.push(ALL[key].name)
        })
      }

      if (
        currentCategories.length &&
        currentCategories.toString() !== prevCategories.toString()
      ) {
        callRequest(
          () => getOptionalsByCategory(currentCategories),
          this.setOptionals,
        )
      }

      if (prevCategories.length > 0 && !currentCategories.length) {
        this.setOptionals([])
      }
    }
  }

  drawFreeHand = () => {
    if (this.mapService) {
      this.startDrawing()
      this.mapService.drawFreeHand()
    }
  }

  getWarningMessage = () => {
    const { category, businessType } = this.state.error

    if (category && businessType)
      return I18n.t('warningMessages.noBusinessAndCategory')

    if (!businessType && category) return I18n.t('warningMessages.noCategory')

    if (businessType && !category) return I18n.t('warningMessages.noBusiness')

    return I18n.t('warningMessages.noBusinessAndCategory')
  }

  getNeighborhoods = (city = null) => {
    const newCityId = city ? city : this.city.id ? cityId() : 5270
    this.setState({ loading: true })
    callRequest(
      () => this.client.neighborhoods(newCityId),
      data =>
        this.setState({
          loading: false,
          loadedNeighborhoods: data.map(neighborhood => ({
            id: neighborhood.Id,
            name: neighborhood.Nome,
            path: this.mapService.decodePath(neighborhood.PontosPoligono),
            latLng: {
              lat: neighborhood.PontoCentralLatitude,
              lng: neighborhood.PontoCentralLongitude,
            },
          })),
        }),
      null,
      this.handleError,
    )
  }

  changeCity = cityId => {
    getCityById(cityId).then(({ data }) => {
      if (data.eemovelProperties) {
        setCityIdLocalStorage(cityId)
        this.city = data
        this.resetMap()
      } else {
        getCityById(
          this.baseCity.eemovelProperties ? this.baseCity.id : 5270,
        ).then(({ data }) => {
          this.city = data
          setCityIdLocalStorage(data.id)
          this.resetMap()
        })
      }
    })
  }

  initializeMapService = google => {
    google.mobile = true
    this.mapService = new MapService(google)
    callRequest(
      () => getCityById(cityId()),
      data => {
        if (data.eemovelProperties) {
          this.city = data
          this.baseCity = data
          this.panMap(
            this.city.eemovelProperties.zoom,
            this.city.eemovelProperties.coordinates,
          )
          this.getNeighborhoods(this.city.id)
        } else {
          getCityById(5270).then(({ data }) => {
            this.city = data
            this.baseCity = data
            this.getNeighborhoods(this.city.id)
            this.panMap(
              this.city.eemovelProperties.zoom,
              this.city.eemovelProperties.coordinates,
            )
          })
          window.M.toast({
            html:
              'Infelizmente não conseguimos localizar sua cidade, redirecionamos você para São Paulo',
            classes: 'Toast--failure',
          })
        }
      },
    )
    this.forceUpdate()
  }

  setSearching = () => {
    this.searching = true
  }

  setNotSearching = () => {
    this.searching = false
  }

  handleMapChange = mapOptions => {
    if (!this.searching) {
      this.setState(
        {
          mapOptions,
        },
        () => {
          const { clusters } = this.state
          if (this.mapService && this.mapService.clusterExists) {
            const newClusters = this.mapService.getClusters(mapOptions)
            JSON.stringify(clusters) !== JSON.stringify(newClusters) &&
              this.setState({ clusters: newClusters })
          }
        },
      )
    }
  }

  handleGeocodeCallback = (status, { center, zoom }) => {
    if (status === 'OK') {
      this.panMap(zoom, center)
    } else {
      this.handleError(status)
    }
  }

  handleOnSearch = values => {
    const {
      minPrice,
      maxPrice,
      query,
      more,
      businessType,
      category,
      privateBusiness,
    } = values
    const priceRange =
      minPrice !== '' || maxPrice !== '' ? { minPrice, maxPrice } : null

    this.setNotSearching()
    this.setState(
      prevState => ({
        ...prevState,
        search: {
          ...prevState.search,
          businessType: businessType || prevState.search.businessType,
          privateBusiness:
            (privateBusiness === undefined &&
              prevState.search.privateBusiness) ||
            privateBusiness,
          priceRange: priceRange || prevState.search.priceRange,
          query: query || prevState.search.query,
          more: { ...(more || prevState.search.more) },
          category: category || prevState.search.category,
        },
      }),
      () => this.validateSearch(),
    )

    if (query) {
      this.mapService.geocode(query, this.handleGeocodeCallback)
    }
  }

  setOptionals = optionals => {
    this.setState(state => ({ ...state, optionalsFilter: optionals }))
  }

  handleSubmitSearch = () => {
    const { query } = this.state
    const options = this.formatStateForQuery()
    this.setState(state => ({ ...state, loading: true, pristine: false }))

    //count and region stats
    this.client
      .searchV2(null, {
        ...options,
        mapped: undefined,
        filterPaths: ['aggregations.*'],
        aggs: {
          type: 'terms',
          field: 'geo_process_pinned',
          name: 'counts',
        },
      })
      .then(({ data: { aggregations } }) => {
        if (aggregations) {
          const {
            neighborhood,
            counts: { buckets },
          } = aggregations
          this.setState({
            neighborhoodStatistics: neighborhood.buckets,
          })
          if (buckets && buckets.length) {
            console.log(buckets)
            const mappeds = buckets.find(item => item.key === 1)
            const unmappeds = buckets.find(item => item.key === 0)
            this.setState({
              unmappeds: (unmappeds && unmappeds.doc_count) || 0,
              mappeds: (mappeds && mappeds.doc_count) || 0,
            })
          } else {
            this.setState({
              unmappeds: 0,
              mappeds: 0,
            })
          }
        }
      })
      .catch(err => {
        this.handleError(err)
      })

    //search
    this.client
      .searchV2(query, {
        ...options,
        size: 100000,
        filterPaths: ['hits.hits*'],
      })
      .then(results => {
        this.setState(state => ({ ...state, loading: false }))
        this.parseResults(results)
      })
      .catch(err => {
        this.handleError(err)
        this.Materialize.toast({
          html: I18n.t('common.fail'),
          classes: 'Search__Toast--failure',
        })
        this.setState(state => ({ ...state, loading: false }))
      })
  }

  clearClusters = () => {
    this.setState({ clusters: [] })
    this.mapService.clearClusters()
  }

  handleError = err => {
    console.error(err)
  }

  handleClearFilters = () => {
    this.setState(
      state => ({ ...state, search: { ...SEARCH() } }),
      () => {
        this.clearClusters()
        this.validateSearch(this.state.search)
      },
    )
    this.stopDrawing()
  }

  handleSetPrevSearch = (prevSearch, submit = true) => {
    this.setState(state => ({
      ...state,
      prevSearch: JSON.stringify(prevSearch),
    }))
    submit && this.handleSubmitSearch()
  }

  formatStateForQuery = () => {
    const {
      search: {
        priceRange,
        businessType,
        more,
        query,
        category,
        privateBusiness,
      },
    } = this.state
    const polygon = this.mapService.getPolygonsAsArray()

    let priceType
    switch (businessTypeEnum(businessType).value) {
      case 2:
        priceType = 'transaction_rent'
        break
      case 3:
        priceType = 'transaction_season'
        break
      default:
        priceType = 'transaction_sale'
        break
    }

    const aggregations = [
      { type: 'percentiles', field: priceType, value: 'price' },
      { type: 'percentiles', field: 'area', value: 'area' },
      { type: 'percentiles', field: 'total_area', value: 'totalArea' },
      { type: 'max', field: 'neighborhood_id', value: 'neighborhoodId' },
    ]
    const source = ['location', 'link_key', 'type']
    const mapped = true

    return {
      priceRange,
      businessType: priceType,
      polygon,
      query,
      category,
      aggregations,
      source,
      mapped,
      privateBusiness,
      ...more,
    }
  }

  parseResults = results => {
    const { data } = results
    if (data.hits) {
      this.mapService.loadClusters(
        data.hits.hits.map(realState => ({
          esIndex: realState._source.link_key,
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: realState._source.location.coordinates,
          },
        })),
      )
      this.setState(state => ({
        ...state,
        clusters: this.mapService.getClusters(state.mapOptions),
      }))
    }
  }

  shouldDisplaySearchButton = () => {
    const { search, prevSearch } = this.state
    const currentSearch = JSON.stringify({ ...search })
    const defaultParams = JSON.stringify(SEARCH())
    let polygons = []
    if (this.mapService) {
      polygons = this.mapService.getPolygonsAsArray()
    }
    return (
      this.mapService &&
      polygons.length > 0 &&
      defaultParams !== currentSearch &&
      currentSearch !== prevSearch &&
      !search.drawing
    )
  }

  shouldDisplayAlert = () => {
    const { search, prevSearch } = this.state
    const defaultSearch = JSON.stringify({ ...SEARCH() })
    const currentSearch = JSON.stringify({ ...search })
    let polygons = []
    if (this.mapService) {
      polygons = this.mapService.getPolygonsAsArray()
    }
    return (
      !this.shouldDisplaySearchButton() &&
      prevSearch !== defaultSearch &&
      currentSearch !== defaultSearch &&
      polygons.length > 0
    )
  }

  cancelDraw = () => {
    this.mapService.resetMap()
    this.mapService.stopDrawing()
    this.setState(state => ({
      ...state,
      search: { ...state.search, drawing: false },
    }))
  }

  stopDrawing = () => {
    if (this.mapService) {
      this.mapService.stopDrawing()
      this.setState(
        state => ({
          ...state,
          search: { ...state.search, drawing: false },
        }),
        () => {
          const { search } = this.state
          this.validateSearch(search)
        },
      )
    }
  }

  startDrawing = () => {
    this.mapService.clearClusters()
    this.mapService.drawFreeHand()
    this.setState(state => ({
      ...state,
      search: { ...state.search, drawing: true },
      clusters: [],
    }))
  }

  toggleCreateAlert = () => {
    this.setState(state => ({ ...state, alert: !state.alert }))
  }

  toggleDwellers = () => {
    this.setState(state => ({
      ...state,
      dwellers: !state.dwellers,
    }))
  }

  validateSearch = (prevState = null) => {
    const { businessType, category } = this.state.search
    let valid = true
    let categoryCount = 0

    if (category) {
      Object.keys(category).forEach(key => {
        if (category[key]) categoryCount++
      })
    }

    if (categoryCount === 0) {
      this.setState(state => ({
        ...state,
        error: { ...state.error, category: true },
      }))
      valid = false
    } else {
      this.setState(state => ({
        ...state,
        error: { ...state.error, category: false },
      }))
    }

    if (businessType === '') {
      this.setState(state => ({
        ...state,
        error: { ...state.error, businessType: true },
      }))
      valid = false
    } else {
      this.setState(state => ({
        ...state,
        error: { ...state.error, businessType: false },
      }))
    }

    if (prevState) this.handleSetPrevSearch(prevState, valid)
    if (!valid) {
      this.setState(
        state => ({ ...state, toast: true, toastType: 'warning' }),
        () => this.setState(state => ({ ...state, toast: false })),
      )
    }
  }

  formatRealtiesCallback = ({ data }) => {
    this.setState({ realties: data })
  }

  handlePaginatedSearch = (options, callback = null) => {
    const { businessType } = this.state.search
    this.client
      .searchPaginated(options)
      .then(({ data: { hits } }) => {
        // TODO add count request
        this.setState({ totalRealties: hits.total.value })
        hits.hits &&
          formatRealties(hits.hits, businessType, this.formatRealtiesCallback)
      })
      .catch(err => {
        this.handleError(err)
      })
      .then(() => {
        callback && callback()
      })
  }

  handleShowRealtyBtnClick = mapped => {
    let options = {
      ...this.formatStateForQuery(),
      source: REALTY_OPTIONS,
      aggregations: undefined,
      sort: {
        created_at: 'desc',
        _id: 'asc',
      },
      mapped: mapped === true || mapped === false ? mapped : undefined,
      filterPaths: ['hits.*'],
    }
    this.toggleShowRealty(mapped)
    this.handlePaginatedSearch(options)
  }

  panMap = (zoom, center) => {
    this.setState(({ mapOptions }) => ({
      mapOptions: {
        ...mapOptions,
        zoom,
        center,
      },
    }))
  }

  toggleShowRealty = mapped => {
    const { showRealty } = this.state
    this.idList = []
    this.setState(state => ({
      ...state,
      mappedClick: mapped,
      showRealty: !showRealty,
      realties: [],
    }))
  }

  renderMarkers = () => {
    const zoomClick = (id, geometry) => {
      this.panMap(
        Math.floor(
          this.mapService.getClusterExpansionZoom(id) + 2,
          MAP.options.maxZoom,
        ),
        geometry,
      )
    }

    const zoomedClick = clusterId => {
      this.toggleShowRealty()
      this.idList = this.mapService
        .getClusterLeaves(clusterId)
        .map(leaf => leaf.esIndex)
      this.handlePageChange({
        id: this.idList,
        sort: {
          created_at: 'desc',
          _id: 'asc',
        },
      })
    }

    return this.state.clusters.map((item, index) => {
      const { properties, geometry } = item
      return (
        <MarkerContainer
          key={index}
          lat={geometry.coordinates[1]}
          lng={geometry.coordinates[0]}
        >
          {!properties ? (
            <Marker onClick={() => this.handleOnClickPin(item)} />
          ) : (
            <ClusterMarker
              mobile
              zoomClick={() =>
                zoomClick(properties.cluster_id, geometry.coordinates.reverse())
              }
              zoomedClick={() => zoomedClick(properties.cluster_id)}
              currentZoom={this.state.mapOptions.zoom}
              pointsCount={properties.point_count_abbreviated}
            />
          )}
        </MarkerContainer>
      )
    })
  }

  handleOnClickPin = pin => {
    const {
      search: { businessType },
    } = this.state
    this.setState(
      {
        lastClickedPin: pin.geometry.coordinates,
        infoWindowVisible: true,
        infoWindowContent: null,
      },
      () =>
        this.client
          .realtyById({
            id: pin.esIndex,
            source: REALTY_OPTIONS,
          })
          .then(({ data }) => {
            data.hits &&
              data.hits.hits &&
              formatRealties(
                data.hits.hits,
                businessType,
                this.formatRealtiesInfoWindowCallback,
              )
          }),
    )
  }

  formatRealtiesInfoWindowCallback = ({ data }) => {
    this.setState({ infoWindowContent: data[0] })
  }

  toggleInfoWindow = () => {
    this.setState({
      infoWindowContent: null,
      infoWindowVisible: !this.state.infoWindowVisible,
    })
  }

  toggleStatistics = () => {
    this.setState(state => ({ ...state, statistics: !state.statistics }))
  }

  closeRadiusInfo = () => {
    this.setState(state => ({ ...state, radiusInfo: false }))
  }

  setRadiusInfo = selectedAddress => {
    this.setState(state => ({ ...state, selectedAddress, radiusInfo: true }))
  }

  resetMap = city => {
    this.state.infoWindowVisible && this.toggleInfoWindow()
    this.clearClusters()
    this.mapService.resetMap()
    this.setState(({ mapOptions }) => ({
      neighborhoodStatistics: [],
      statistics: false,
      mappeds: 0,
      unmappeds: 0,
      pristine: true,
      mapOptions: {
        ...mapOptions,
        center: this.city.eemovelProperties.coordinates,
        zoom: this.city.eemovelProperties.zoom,
      },
    }))
    this.callUpdate()
  }

  callUpdate = () => {
    this.forceUpdate()
  }

  changeRadius = ({ radius }) => {
    const { selectedAddress } = this.state
    const parsedRadius = Number(radius)
    this.mapService.createCircle(
      {
        lat: selectedAddress.location.lat(),
        lng: selectedAddress.location.lng(),
      },
      parsedRadius,
    )
    this.panMap(this.state.mapOptions.zoom, {
      lat: selectedAddress.location.lat(),
      lng: selectedAddress.location.lng(),
    })
    this.closeRadiusInfo()
    this.validateSearch(this.state.search)
  }

  renderRadiusInfo = () => {
    const { radiusInfo } = this.state
    const radiusClasses = classNames({
      'SearchMobile__Pane--hidden': !radiusInfo,
      SearchMobile__Pane: radiusInfo,
    })
    return (
      <div className={radiusClasses}>
        <div className="SearchMobile__Radius">
          <Row end="xs">
            <Col xs={12}>
              <i className="material-icons" onClick={this.closeRadiusInfo}>
                close
              </i>
            </Col>
          </Row>
          <div className="SearchMobile__RadiusContainer">
            <span>Em um raio de quantos metros você deseja procurar?</span>
            <Formik
              initialValues={{ radius: 250 }}
              onSubmit={this.changeRadius}
            >
              {({ handleSubmit, handleChange, values: { radius } }) => (
                <form onSubmit={handleSubmit}>
                  <input name="radius" value={radius} onChange={handleChange} />
                  <Button type="submit" btnStyle="primary" btnSize="xs" block>
                    <i className="material-icons">check</i>
                  </Button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    )
  }

  handlePageChange = ({ mapped, sort, searchAfter, query, id }, callback) => {
    let options = {
      ...this.formatStateForQuery(),
      sort,
      searchAfter,
      query,
      id,
      source: REALTY_OPTIONS,
      aggregations: undefined,
      mapped: mapped === true || mapped === false ? mapped : undefined,
      filterPaths: ['hits.*'],
    }
    this.handlePaginatedSearch(options, callback)
  }

  render() {
    const {
      mapOptions,
      prevSearch,
      error,
      toast,
      clusters,
      search,
      alert,
      optionalsFilter,
      dwellers,
      showRealty,
      loading,
      realties,
      infoWindowVisible,
      lastClickedPin,
      infoWindowContent,
      unmappeds,
      mappeds,
      pristine,
      neighborhoodStatistics,
      statistics,
      loadedNeighborhoods,
      mappedClick,
      totalRealties,
    } = this.state
    const {
      query,
      businessType,
      privateBusiness,
      drawing,
      priceRange,
      more,
      category,
      visualization,
    } = search
    const { center, zoom } = mapOptions
    return (
      <SearchProvider
        value={{
          query,
          filters: {
            businessType,
            privateBusiness,
            drawing,
            priceRange,
            more,
            category,
          },
          cities: this.cities,
          city: this.baseCity,
          currentCity: this.city,
          mapService: this.mapService,
          handleGeocodeCallback: this.handleGeocodeCallback,
          setRadiusInfo: this.setRadiusInfo,
          changeCity: this.changeCity,
          visualization,
          clusters,
          clearFilters: this.handleClearFilters,
          onSubmit: this.handleOnSearch,
          onSearch: this.handleSubmitSearch,
          polygons: this.mapService ? this.mapService.getPolygonsAsArray() : [],
          prevSearch,
          error,
          optionalsFilter,
          openDwellers: this.toggleDwellers,
          setSearching: this.setSearching,
          setNotSearching: this.setNotSearching,
          loadedNeighborhoods,
          clearClusters: this.clearClusters,
          setPrevSearch: this.validateSearch,
          getNeighborhoods: this.getNeighborhoods,
        }}
      >
        <MobilePageBase
          fluid
          className="SearchMobile"
          TopComponent={() => (
            <div className="SearchMobile__Bars">
              <MobileSearchBar
                searching={this.setSearching}
                notSearching={this.setNotSearching}
              />
            </div>
          )}
          FilterComponent={() => (
            <div>
              <FilterBar />
              <Row
                className="SearchMobile__SearchBtnContainer"
                center="xs"
                style={{
                  display: this.shouldDisplaySearchButton() ? 'block' : 'none',
                }}
              >
                <Button
                  btnSize="lg"
                  block
                  btnStyle="primary"
                  onClick={() =>
                    this.validateSearch({
                      query,
                      businessType,
                      privateBusiness,
                      drawing,
                      priceRange,
                      more,
                      category,
                      visualization,
                    })
                  }
                >
                  {I18n.t('components.actions.filter')}
                </Button>
              </Row>
              {userId() !== 14801 && (
                <Row
                  className="SearchMobile__SearchBtnContainer"
                  center="xs"
                  style={{
                    display:
                      this.shouldDisplayAlert() && userId() !== 14801
                        ? 'block'
                        : 'none',
                  }}
                >
                  <Button
                    btnSize="lg"
                    block
                    btnStyle="default"
                    onClick={this.toggleCreateAlert}
                  >
                    <NotificationIcon />
                    {I18n.t('components.searchBar.createAlert')}
                  </Button>
                </Row>
              )}
            </div>
          )}
        >
          <Helmet>
            <title>EEmovel - {I18n.t('pages.search.title')}</title>
          </Helmet>
          {((unmappeds || mappeds) && !loading && this.shouldDisplayAlert() && (
            <div className="SearchMobile__ShowRealtyBar">
              <div className="SearchMobile__ShowRealtyBar__Container">
                {(mappeds || unmappeds) && (
                  <div className="SearchMobile__ShowRealtyBar__Count">
                    {I18n.t('components.showRealtyBtn.total')}
                    <span
                      className="SearchMobile__ShowRealtyBar__Count__Text"
                      onClick={this.handleShowRealtyBtnClick}
                    >
                      {mappeds && unmappeds
                        ? (mappeds + unmappeds).toLocaleString('pt-BR')
                        : mappeds
                        ? mappeds.toLocaleString('pt-BR')
                        : unmappeds.toLocaleString('pt-BR')}{' '}
                      {I18n.t('components.filters.opportunities')}
                    </span>
                  </div>
                )}
                {userId() !== 14801 && (
                  <Button
                    className="SearchMobile__ShowRealtyBar__ShowRealtyBtn"
                    onClick={this.toggleStatistics}
                  >
                    <i className="material-icons">poll</i>
                    Estatísticas
                  </Button>
                )}
              </div>
            </div>
          )) ||
            (!unmappeds && !mappeds && !pristine && !loading && (
              <div className="SearchMobile__ShowRealtyBar">
                <div className="SearchMobile__ShowRealtyBar__Container">
                  <div className="SearchMobile__ShowRealtyBar__NotFound">
                    <div>{I18n.t('components.showRealty.noRealtyFound')}</div>
                  </div>
                </div>
              </div>
            ))}
          <MobileSearchMap
            onChange={this.handleMapChange}
            useMapsApi
            onGoogleApiLoaded={this.initializeMapService}
            streetViewControl
            zoom={zoom}
            maxZoom={MAP.options.maxZoom}
            center={center}
            visualization={visualization}
            hasSearch={
              (unmappeds || mappeds) && !loading && this.shouldDisplayAlert()
            }
            onClick={this.setNotSearching}
          >
            {this.renderMarkers()}
          </MobileSearchMap>
          <DrawButton
            drawing={drawing}
            onClick={!drawing ? this.drawFreeHand : this.stopDrawing}
          />
          <CancelButton drawing={drawing} onClick={this.cancelDraw} />
          {infoWindowVisible && (
            <InfoWindow
              mobile
              toggleInfoWindow={this.toggleInfoWindow}
              content={infoWindowContent || 'Carregando...'}
              lat={lastClickedPin ? lastClickedPin[1] : null}
              lng={lastClickedPin ? lastClickedPin[0] : null}
            />
          )}
          {alert && (
            <AlertContainer visible={alert} onToggle={this.toggleCreateAlert} />
          )}
          <Toast mobile type="warning" visible={toast}>
            <p>{this.getWarningMessage()}</p>
          </Toast>
          {loading && <Spinner visible={loading} />}
          {showRealty && (
            <RealtyProvider
              value={{
                realties,
                totalRealties,
                mappedClick,
                idList: this.idList,
                visible: showRealty,
                handlePageChange: this.handlePageChange,
                toggle: this.toggleShowRealty,
              }}
            >
              <ShowRealty businessType={businessType} />
            </RealtyProvider>
          )}
          {this.renderRadiusInfo()}
          {dwellers && (
            <Dwellers visible={dwellers} close={this.toggleDwellers} />
          )}
          {statistics && (
            <Statistics
              statistics={neighborhoodStatistics}
              active={statistics}
              close={this.toggleStatistics}
            />
          )}
          <Chat />
        </MobilePageBase>
      </SearchProvider>
    )
  }
}
export default MobileSearchPage
