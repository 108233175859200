import React, { Component } from 'react'

import Spinner from 'components/Spinner'

import './index.css'

class LandingPage extends Component {
  componentDidMount() {
    window.location = 'https://eemovel.com.br'
  }

  render() {
    return (
      <div className="UnionTransition">
        <Spinner visible />
      </div>
    )
  }
}

export default LandingPage
