import React from 'react'
import { Grid, Row, Col } from 'components/Grid'
import Logo from 'components/Logo'
import I18n from 'locales'
import './index.css'

const Footer = () => (
  <footer className="Footer">
    <Grid>
      <Row>
        <Col md={4} xs={12}>
          <Row>
            <Col xs={12}>
              <Logo />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <a
                className="Footer__FooterLink"
                target="_blank"
                rel="noopener noreferrer"
                href="/"
              >
                {I18n.t('footer.home')}
              </a>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <a
                className="Footer__FooterLink"
                target="_blank"
                rel="noopener noreferrer"
                href="https://api.whatsapp.com/send?phone=554598323749"
              >
                {I18n.t('footer.support')}
              </a>
            </Col>
          </Row>
        </Col>
        <Col md={4} xs={12}>
          <Row>
            <Col xs={12}>
              <p className="Footer__FooterHeading">
                {I18n.t('footer.contact')}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p className="Footer__FooterText">contato@eemovel.com.br</p>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p className="Footer__FooterText">
                Rua Duque de Caxias, 209 Centro - Cascavel - PR, 85801-100
              </p>
            </Col>
          </Row>
        </Col>
        <Col md={4} xs={12}>
          <Row>
            <Col xs={12}>
              <p className="Footer__FooterHeading">Social</p>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <a
                className="Footer__FooterLink"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/EEmovel/"
              >
                Facebook
              </a>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <a
                className="Footer__FooterLink"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.youtube.com/channel/UCP_YYG2TqBjEIVZm5TsBjFA"
              >
                Youtube
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </Grid>
    <Grid>
      <Row center="xs">
        <Col>
          <p className="Footer__FooterText">{I18n.t('footer.copyRight')}</p>
        </Col>
      </Row>
    </Grid>
  </footer>
)

export default Footer
