export default {
  pages: {
    search: {
      title: 'Busca',
    },
  },
  routes: {
    preferences: '/preferencias',
    preferenceInitial: '#inicial',
    preferenceAccounts: '#contas',
    preferenceUsers: '#usuarios',
    preferenceAlert: '#alerta',
    preferenceGeneralData: '#dados-gerais',
    preferenceAccessData: '#dados-acesso',
    preferencePlans: '#planos',
    login: '/login/:reason?',
    partner: '/partner/:token',
    register: '/cadastro',
    search: '/busca',
    whereWeAct: '/onde-atuamos',
    rgi: '/rgi/:id/:businessType',
    evaluation: '/avaliacao',
    evaluationRgi: '/avaliacao-rgi/:report',
  },
  warningMessages: {
    noCategory: 'É necessário selecionar pelo menos um tipo de imóvel',
    noBusiness: 'É necessário selecionar pelo menos um tipo de negócio',
    noBusinessAndCategory:
      'É necessário selecionar pelo menos um tipo de imóvel e um tipo de negócio',
  },
  errors: {
    invalidDate: 'Data inválida',
    invalidCep: 'CEP inválido',
    invalidCoupon: 'Cumpom inválido',
    invalidCpfCnpj: 'CPF/CNPJ inválido',
    invalidRealState: 'Imobiliária inválida',
    invalidTelephone: 'Telefone inválido',
    invalidCity: 'Cidade inválida',
    passwordMustMatch: 'As senhas devem ser iguais',
    invalidNumber: 'Número inválido',
    invalid: 'Inválido',
    zipCodeNotFound: 'CEP não encontrado',
  },
  accounts: {
    accounts: 'Contas',
  },
  users: {
    users: 'Usuários',
    resendPassword: 'Reenviar senha',
    changeValidity: 'Alterar Vigência',
    inactivate: 'Inativar',
    activate: 'Ativar',
    changeToManager: 'Tornar Gestor',
    accessReport: 'Relatório de acesso',
    changeToRealState: 'Tornar Corretor',
    userSuccessfullyAlteredToRealState:
      'Usuários alterados para corretor com sucesso',
    userSuccessfullyAlteredToManager:
      'Usuários alterados para gestor com sucesso',
    noAccessData:
      'Opa, parece que não há nenhum registro de acesso desses usuários :(',
    validityChanged: 'Vigência alterada com sucesso',
    passwordsResended: 'Senhas reenviadas com sucesso',
    usersInactivated: 'Usuários inativados com sucesso',
    usersActivated: 'Usuários ativados com sucesso',
    selectAValidOption: 'Selecione uma opção válida',
    manager: 'Gestor',
    startDate: 'Inicio Vig.',
    endDate: 'Fim Vig.',
    accountQuantity: 'Nº Contas',
    selectedUsers: ' usuários selecionados',
    selectedUser: ' usuário selecionado',
    searchHere: 'Pesquise aqui...',
    validityFinalDate: 'Data final da vigência',
    managerId: 'Id do gestor',
    initialDate: 'Data inicio',
    finalDate: 'Data fim',
    accessDate: 'Data de acesso',
  },
  plans: {
    plan: 'Plano',
    smartSearch: 'Pesquisa Inteligente',
    evaluation: 'Avaliação',
    captationAlerts: 'Alertas de Captação de oportunidade',
    rgi: 'RGI - Relatório Geral do Imóvel',
    oneCitySearch: 'Apenas 1 cidade para consultar',
    threeCitiesSearch: 'Apenas 3 cidades para consulta',
    allCitiesSearch: 'Acesso a todas as cidades da base EEmovel',
    twentySearches: '20 consultas de possíveis moradores/dia',
    fortySearches: '40 consultas de possíveis moradores/dia',
    hundredSearches: '100 consultas de possíveis moradores/dia',
    adMetrics: 'Estatísticas de Anúncios',
    privateFilter: 'Filtro de imóveis particulares',
    payment: 'Pagamento',
    personal: 'Pessoal',
    cep: 'CEP',
    cepPlaceholder: 'Digite seu CEP aqui',
    addressPlaceholder: 'Digite seu endereço aqui',
    numberPlaceholder: 'Digite o número',
    complement: 'Complemento',
    cardName: 'Nome impresso no cartão',
    cardNamePlaceholder: 'Informe o nome impresso no cartão',
    cardNumber: 'Número cartão',
    cardNumberPlaceholder: 'Informe o número do cartão',
    code: 'Código',
    codePlaceholder: 'CVV',
    expireDate: 'Validade',
    expireDatePlaceholder: 'mm/aa',
    plans: 'Planos',
    paymentMethod: 'Como deseja realizar o pagamento?',
    bankSlip: 'Boleto',
    card: 'Cartão',
    readedAndAccept: 'LI E ACEITO OS TERMOS DO CONTRATO',
    contractDownload: 'Download Contrato',
    whenFinalize: 'Ao clicar em "Finalizar", você concorda com os ',
    userTerms: 'Termos de uso',
    withA: ', com a ',
    privacyDeclaration: 'Declaração de Privacidade',
    andDeclare:
      ', e declara ter mais de 18 anos. Para cancelar, acesse sua Conta online e clique em "Cancelar assinatura".',
    selectedPlan: 'Plano selecionado',
    fullName: 'Nome Completo',
    fullNamePlaceholder: 'Digite seu nome completo aqui',
    cpfCnpj: 'CPF/CNPJ',
    cpfCnpjPlaceholder: 'Digite seu CPF ou CNPJ aqui',
  },
  dwellers: {
    helpTitle:
      'Dados de pessoas que tem ou tiveram vínculo com o endereço, como inquilinos, ex-inquilinos, ex-proprietários, familiares ou os proprietários.',
    address: 'Endereço',
    errorMatchDocument:
      'Não localizamos dados de contato apenas por esse nome. Você pode pesquisar novamente, selecionando a opção "Possíveis Moradores" para tentar localizar mais informações.',
    complement: 'Unidade',
    fullAddress: 'Endereço completo',
    document: 'Documento',
    nameTitle: 'Nome: ',
    cpfTitle: 'CPF: ',
    motherTitle: 'Nome da mãe: ',
    birthDateTitle: 'Data de Nascimento: ',
    ageTitle: 'Idade: ',
    federalSituationTitle: 'Situação Receita: ',
    scholarshipTitle: 'Escolaridade: ',
    ocupationTitle: 'Ocupação: ',
    searchPlaceholder: 'Pesquise endereço ou CEP',
    filter: 'Filtrar',
    deathTitle: 'Óbito: ',
    relationship: 'Relação',
    relation: 'Relação',
    age: 'Idade',
    type: 'Tipo',
    name: 'Nome',
    provider: 'Operadora',
    operator: 'Operadora',
    street: 'Logradouro',
    relevance: 'Relevância',
    ocupation: 'Cargo',
    dweller: 'Morador',
    owner: 'Proprietário',
    links: 'Vínculos',
    emails: 'E-mails',
    telephones: 'Telefones',
    addresses: 'Endereços',
    company: 'Empresa',
    partners: 'Sócios',
    cnae: 'CNAE: ',
    cnaeDescription: 'Descrição CNAE: ',
    legalNature: 'Natureza Juridica: ',
    companyName: 'Razão Social: ',
    fantasyName: 'Nome Fantasia: ',
    cnpj: 'CNPJ: ',
    cpf: 'CPF',
    openningDate: 'Data de Abertura: ',
    companyType: 'Porte: ',
    employesNumber: 'Quantidade de Funcionários: ',
    joinDate: 'Data de Entrada',
    participation: 'Participação',
    startNumber: 'Nº inicial',
    endNumber: 'Nº final',
  },
  accessData: {
    passwordSuccess: 'Senha redefinida com sucesso',
    passwordFail: 'Ops, algo deu errado :(',
    header: 'Dados de Acesso',
    currentPassword: 'Senha Atual',
    newPassword: 'Nova senha',
    confirmNewPassword: 'Confirme a nova senha',
    currentPasswordPlaceholder: 'Digite aqui sua senha atual',
    newPasswordPlaceholder: 'Digite aqui sua nova senha',
    confirmNewPasswordPlaceholder: 'Confirme sua nova senha',
  },
  generalData: {
    nameTooShort: 'Deve conter no mínimo 2 caracteres',
    header: 'Dados Gerais',
    name: 'Nome',
    namePlaceholder: 'Digite aqui seu nome',
    uf: 'Estado',
    ufWithInfo: 'Estado (Sigla)',
    city: 'Cidade',
    UF: 'UF',
  },
  footer: {
    home: 'Home',
    contact: 'Contato',
    support: 'Suporte',
    privacyPolicy: 'Politica de privacidade',
    useTerms: 'Termos de uso',
    copyRight:
      'Copyright © 2019 EEmovel Inteligência Imobiliária - Todos os direitos reservados.',
  },
  common: {
    required: 'Obrigatório',
    from: 'De',
    to: 'Até',
    minimum: 'mínimo',
    maximum: 'máximo',
    notInformed: 'Não informado',
    yes: 'Sim',
    no: 'Não',
    save: 'Salvar',
    delete: 'Excluir',
    continue: 'Continuar',
    finish: 'Finalizar',
    fail: 'Ops, algo deu errado :(',
    welcome: 'Bem vindo',
    successfullyAltered: 'Informações alteradas com sucesso',
  },
  login: {
    login: 'login',
    email: 'E-mail',
    password: 'Senha',
    forgotPassword: 'Esqueci minha senha',
    invalidEmail: 'E-mail inválido',
    required: 'Obrigatório',
    tooShort: 'Deve conter no minímo 6 caracteres',
  },
  register: {
    surpriseYourself: 'Surpreenda-se com nossas Ferramentas',
    smartSearch: 'Busca Inteligente',
    find:
      'Encontre todos os imóveis para venda e locação em sua cidade em um único lugar. Entenda a necessidade do seu cliente, pesquise e feche mais negócios.',
    realtyRating: 'Avaliação de Imóveis',
    do:
      'Faça avaliações imobiliárias, residenciais ou comerciais, utilizando como base de dados as informações da região e imóveis semelhantes. Gere confiança para argumentar com seu cliente no momento de precificar uma propriedade.',
    marketReport: 'Relatório de Mercado',
    understand:
      'Entenda o mercado imobiliário da sua cidade em detalhes. Observe tendências de mercado, estatísticas e monte sua estratégia de atuação.',
    register: 'Cadastro',
    firstName: 'Primeiro Nome',
    lastName: 'Sobrenome',
    confirmPassword: 'Confirmação de senha',
    telephone: 'Telefone',
    searchCity: 'Pesquise uma cidade',
    realState: 'Imobilíaria',
    individualRealState: 'Corretor Individual',
    signWithFacebook: 'Cadastrar com o Facebook',
    home: 'Voltar ao início',
  },
  sidebar: {
    accounts: 'Contas',
    initial: 'Inicial',
    alert: 'Alerta',
    generalData: 'Dados Gerais',
    accessData: 'Dados de Acesso',
    plans: 'Planos',
    users: 'Usuários',
  },
  alert: {
    alerts: 'Alertas',
    createdAt: 'Criação',
    name: 'Nome',
    realty: 'Imóvel',
    minPrice: 'Valor min',
    maxPrice: 'Valor max',
    rooms: 'Dorm',
    garage: 'Garagem',
    optionals: 'Opcionais',
    active: 'Ativo',
    private: 'Particular',
    actions: 'Ações',
    realState: 'Imob',
    neighborhood: 'Bairro',
    building: 'Edif',
    value: 'Valor',
    squareMeterValue: 'Valor/m²',
    room: 'Dorm',
    garageMin: 'Garag',
    totalArea: 'Área Total',
    totalAreaValue: 'Valor m² total',
    usefulArea: 'Área útil',
    userfulAreaValue: 'Valor m² útil',
    address: 'Endereço',
    inclusion: 'Anúncio/ Inclusão',
    select: 'Selecionar',
    search: 'Pesquisar',
    confirmDelete: 'Você tem certeza que deseja excluir este alerta?',
    findAlert: 'Digite aqui o nome do seu alerta para uma busca mais rápida',
    alertCreated: 'Alerta criado com sucesso!',
  },
  components: {
    searchBar: {
      placeholder: 'Digite um endereço, ponto de referência ou cidade',
      placeholderMobile: 'Busque sua cidade',
      seeStats: 'Ver Estatísticas',
      createAlert: 'Criar Alerta',
    },
    navBar: {
      ourTools: 'Nossas Ferramentas',
      blog: 'Blog',
      support: 'Suporte',
      login: 'Fazer Login',
      signin: 'Fazer Cadastro',
      home: 'Inicial',
      generalData: 'Dados Gerais',
      plans: 'Planos',
      alerts: 'Alertas',
      accounts: 'Contas',
      logout: 'Fazer Logout',
      socialMediaText: 'Estamos nas redes sociais para ajudar você a entender:',
      facebook: 'Facebook',
      twitter: 'Twitter',
      youtube: 'Youtube',
      whereWeAct: 'Onde Atuamos',
      preferences: 'Preferências',
      search: 'Busca',
      evaluation: 'Avaliação',
    },
    actions: {
      apply: 'Aplicar',
      ok: 'OK',
      cancel: 'Cancelar',
      clearFilters: 'Limpar Filtros',
      search: 'Buscar',
      createAlert: 'Criar Alerta',
      register: 'Cadastrar',
      filter: 'Filtrar',
      select: 'Selecione',
      sign: 'Assinar',
      edit: 'Alterar',
      calculate: 'Calcular',
      exit: 'Sair',
    },
    createAlert: {
      realtyPrice: 'Valor do Imóvel',
      realtyArea: 'Área do Imóvel',
      businessType: 'Tipo de Negócio',
      rent: 'Locação',
      sale: 'Venda',
      selectOption: 'Selecione uma opção',
      options: 'Aqui estão as opções que você selecionou para o Alerta',
      info: 'Os filtros selecionados serão utilizados para criar o alerta',
      alertName: 'Nome do Alerta',
      alertNamePlaceholder: 'Dê um nome para o alerta',
    },
    filters: {
      owners: 'Proprietários',
      apartment: 'Apartamento',
      houses: 'Casa',
      comercial: 'Comercial',
      others: 'Outros',
      moreFilter: 'Mais Filtros',
      moreInstructions:
        'Filtre ainda mais seus resultados selecionando detalhes específicos do tipo de Imóvel que você busca',
      rooms: 'Cômodos',
      room: 'Quartos',
      suits: 'Suítes',
      garages: 'Garagens',
      propertyUsefulArea: 'Área útil do Imóvel',
      propertyTotalArea: 'Área total do Imóvel',
      building: 'Edifício',
      realState: 'Imobiliária',
      reference: 'Referência',
      moreDetail: 'Mais Detalhes',
      details: 'Detalhes',
      name: 'Nome',
      number: 'Número',
      price: 'Valor',
      category: 'Tipo',
      businessType: 'Negócio',
      more: '+ Filtros',
      map: 'Mapa',
      optionals: 'Opcionais',
      privateBusiness: 'Apenas particulares',
      all: 'Todos',
      infoPriceRange:
        'Escolha o valor máximo e mínimo dos imóveis, nesta região, o valor médio é de R$ ',
      fullAddress: 'Endereço completo',
      resetMap: 'Resetar Mapa',
      foundAds: 'Anúncios encontrados: ',
      foundAdsMobile: 'Encontrado: ',
      opportunities: 'Oportunidades',
      dwellers: 'Moradores',
    },
    showRealty: {
      noRealtyFound:
        'Não foram encontrados imóveis com os critérios informados.',
    },
    visualizeButton: {
      visualize: 'Visualizar',
      neighborhoods: 'Bairros',
      heatMap: 'Mapa de Calor',
      zones: 'Zoneamento',
      satellite: 'Satélite',
    },
    drawButton: {
      drawInMap: 'Desenhar no Mapa',
      instruction:
        'Escolha a ferrmenta e depois de desenhar no Mapa, arraste os pontos para criar uma pesquisa ainda mais específica.',
      polygon: 'Polígono',
      handFree: 'Mão Livre',
      stopDrawing: 'Aplicar',
    },
    showRealtyBtn: {
      show: 'Ver Imóveis',
      showMobile: 'Ver',
      total: 'Total: ',
    },
  },
  ourTools: {
    realStateIntelligence:
      'Inteligência Imobiliária: Transforme Informação em Comissão',
    makeMoreBusinnes:
      'Gere mais negócios através das nossas soluções de pesquisa, avaliação e comparativo de mercado.',
    startHere: 'Comece aqui',
    clients: 'clientes',
    cities: 'cidades',
    functionalities: 'Funcionalidades',
    weCreated:
      'Criamos ferramentas inteligentes para você entender de forma única o seu mercado imobiliário e aumentar suas vendas.',
    findAll:
      'Encontre todos os imóveis para venda e locação em sua cidade em um único lugar. Entenda a necessidade do seu cliente, pesquise e feche mais negócios.',
    doAvaluations:
      'Faça avaliações imobiliárias, residenciais ou comerciais, utilizando como base de dados as informações da região e imóveis semelhantes. Gere confiança para argumentar com seu cliente no momento de precificar uma propriedade.',
    understandTheMarket:
      'Entenda o mercado imobiliário da sua cidade em detalhes. Observe tendências de mercado, estatísticas e monte sua estratégia de atuação.',
    askForADemo:
      'Solicite uma demonstração e surpreenda-se com nossas Ferramentas',
    doAskForADemo: 'Solicite uma demonstração',
    whatItTakes: 'O que é necessário para se tornar um corretor em 2019?',
    ourChannel: 'Nosso canal',
    lastNews: 'Últimas Notícias',
    howCanIHelp: 'Olá! como posso te ajudar?',
    askYourQuestions:
      'Tire suas dúvidas sobre nosso produtos. Teremos satisfação em te ajudar.',
    talkToUs: 'Fale Conosco',
    businessManAlt: 'Homem de negócios',
    videos: 'Vídeos',
    realty: 'Imóveis',
    ad: 'Anúncios',
  },
  whereWeAct: {
    seIf: 'Veja se estamos na sua cidade',
  },
  report: {
    realty: {
      rooms: 'Dormitórios',
      garages: 'Garagens',
      usefulArea: 'Área Útil',
      totalArea: 'Área Total',
      price: 'Valor Total',
      valuePerMeter: 'Valor por m²',
      address: 'Endereço:',
    },
    zoning: 'Zoneamento',
    estimative: 'Estimativa EEmovel',
    population: 'População',
    residence: 'Domicílios',
    householdeIncome: 'Renda Domiciliar',
    perCapitaIncome: 'Renda Per Capta',
    area: 'Área',
    density: 'Densidade',
    sample: 'Amostra',
    medianSquareMeter: 'Mediana do m²',
    medianPrice: 'Valor mediano',
    maxHeight: 'Altura Máxima',
    minRecoil: 'Recuo Mín',
    permeabilityRate: 'Taxa de Permeabilização',
    minTestedGround: 'Testada Mín',
    minArea: 'Área Mín (M²)',
    realtyReport: 'Relatório do Imóvel',
    typology: 'Tipologia: ',
    address: 'Endereços: ',
    regionMetrics: 'Estatísticas da Região',
    realtyFeatures: 'Características do Imóvel',
    initials: 'Sigla',
  },
  showRealty: {
    loadMessage: 'Por favor aguarde...',
    timeOutMessage: 'Opa, nenhum imóvel correspondente :(',
    adDate: 'Adicionado Em',
    exportPDF: 'Exportar PDF',
    exportCSV: 'Exportar Excel',
  },
  evaluation: {
    suggestedPrice: 'Valor sugerido',
    squareMeterPrice: 'Valor do metro quadrado: R$ ',
    successfullyRevaluated: 'Revaliado com suceso!',
    area: 'Área',
    report: 'Relatório',
    clearEvaluation: 'Limpar Avaliação',
    evaluate: 'Avaliar',
    revaluate: 'Reavaliar',
    minArea: 'Área Mínima',
    maxArea: 'Área Maxima',
  },
  radiusSearch: {
    radiusSearch: 'Em um raio de quantos metros você deseja procurar?',
  },
  tour: {
    stepOne:
      'Vamos começar! clique no filtro, selecione um tipo de negócio e clique em OK',
    stepTwo:
      'Agora selecione os tipos de imóveis que voce deseja procurar e clique em OK',
    stepThree:
      'Selecione os bairros em que deseja fazer sua busca e clique em aplicar',
    stepFour: 'Pronto, sua busca foi feita! Agora vamos resetar o mapa',
    stepFive:
      'Clique nesse botão e selecione uma ferramenta de desenho (Mão livre ou Polígono)',
    stepSix: 'Desenhe um polígono e clique em aplicar',
    stepSeven:
      'Agora vamos ver as estátiscas da área buscada por você, clique no local indicado',
    stepEight:
      'Essas são as estatísticas da região onde você realizou sua busca',
    stepNine:
      'Agora vamos ver a lista dos imovéis que foram encontrados nessa região',
    stepTen:
      'Essa é a lista dos imovéis que foram encontrados nessa região. Para continuar basta fechar essa janela',
    stepEleven:
      'Agora vamos fazer uma busca de possiveis moradores, clique no botão indicado',
    stepTwelve: 'Preencha os campos confome solicitado e clique em buscar',
    stepThirteenPartOne:
      'Esse são os possiveis moradores do endereço pesquisado, clique em um deles para ver mais informações.',
    stepThirteenPartTwo:
      'Quando estiver pronto, clique no botão abaixo para finalizar o tutorial',
    skipTutorial: 'Pular tutorial',
    finish: 'Terminar',
  },
  selectTypeahead: {
    selectOption: 'Selecione um opção',
    searchAnOption: 'Busque uma opção',
  },
  auth: {
    sessionExpired:
      'Sessão Expirada! Redirecionando para login em 10 segundos...',
  },
}
