import React from 'react'
import './index.css'
import classNames from 'classnames'

const DrawButton = props => {
  const classes = classNames({
    DrawButtonMobile: !props.drawing,
    'DrawButtonMobile DrawButtonMobile--animated': props.drawing,
  })
  return (
    <button className={classes} onClick={props.onClick}>
      <i className="material-icons">{!props.drawing ? 'edit' : 'check'}</i>
    </button>
  )
}

export default DrawButton
