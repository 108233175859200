import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const CITY_URL = `${API_URL}/localizacao/buscacidade`
const REAL_STATE_URL = `${API_URL}/imobiliaria/imobiliariasporcidade`
const REGISTER_URL = `${API_URL}/Account/Register`

export const getCities = cityName =>
  axios.get(`${CITY_URL}/?criterioBusca=${cityName}`).then(({ data }) =>
    data.map(sugg => ({
      value: sugg.Id,
      name: sugg.Nome,
    })),
  )

export const getRealStates = (cityId, realStateName) =>
  axios
    .get(`${REAL_STATE_URL}/?criterioBusca=${realStateName}&cidadeId=${cityId}`)
    .then(({ data }) =>
      data.map(sugg => ({
        value: sugg.Id,
        name: sugg.Nome,
      })),
    )
    .catch(err => console.log(err))

export const submitRegister = (payload, actions, loading, callback) => {
  let data = {
    UserName: payload.email.toLowerCase(),
    Name: `${payload.firstName} ${payload.lastName}`,
    Email: payload.email.toLowerCase(),
    Password: payload.password,
    PasswordConfirmation: payload.confirmPassword,
    PhoneNumber: payload.telephone,
    CityId: payload.cityId,
    ProfileType: payload.realState ? 0 : 1,
  }
  if (payload.realState) data.RealStateAgencyName = payload.realStateName

  loading(true)
  axios
    .post(REGISTER_URL, data)
    .then(response => {
      if (response.status === 200) {
        loading(false)
        actions.resetForm()
        callback()
      } else {
        loading(false)
        window.M.toast({
          html: response.statusText,
          classes: 'Toast--failure',
        })
      }
    })
    .catch(err => {
      loading(false)
      window.M.toast({
        html: err.response.data.Message || 'Ops, algo deu errado',
        classes: 'Toast--failure',
      })
    })
}
