import React, { Component } from 'react'
import { Table, Row, Col } from 'components/AlertTable/styles'
import { Formik, Form, Select } from 'components/Form'
import { Row as GRow, Col as GCol } from 'components/Grid'
import I18n from 'locales'
import { Button } from 'components/Buttons'
import {
  getUsers,
  resendPasswords,
  inactivateUsers,
  activateUsers,
  makeRealState,
  makeManager,
  accessReport,
  changeValidity,
} from 'services/manager'
import './index.css'
import { PaginateArray } from 'utils/arrayPagination'
import Spinner from 'components/Spinner'
import { formatDateAndTime } from 'utils/formatDate'
import { userInfo } from 'services/auth'
import classNames from 'classnames'

const defaultParams = {
  validity: false,
  manager: false,
  realState: false,
  accessReport: false,
  errorValidity: '',
}

const SALES_OPTIONS = ['resendPassword', 'activate', 'accessReport']
const ADMIN_OPTIONS = [
  'activate',
  'inactivate',
  'resendPassword',
  'changeValidity',
  'changeToManager',
  'changeToRealState',
  'accessReport',
]

class Users extends Component {
  constructor() {
    super()
    this.state = {
      users: [],
      usersSnapshot: [],
      loading: false,
      selectedUsers: [],
      page: 0,
      search: '',
      sortId: 'asc',
      sortNome: 'asc',
      sortEmail: 'asc',
      sortImobiliaria: 'asc',
      sortCidade: 'asc',
      sortVigenciaInicial: 'asc',
      sortVigenciaFinal: 'asc',
      sortStatus: 'asc',
      sortIsGestor: 'asc',
      sortNumeroContas: 'asc',
      modal: false,
      validity: false,
      manager: false,
      realState: false,
      accessReport: false,
      accessReports: [],
      errorValidity: '',
    }
  }

  componentDidMount() {
    this.getUsers()
  }

  getUsers = () => {
    const { search, page } = this.state
    this.setLoading()
    getUsers()
      .then(({ data, status, statusText }) => {
        if (status === 200) {
          this.setState({
            users: PaginateArray(data),
            usersSnapshot: data,
            loading: false,
            selectedUsers: [],
          })
        } else {
          this.setState({ loading: false })
          window.M.toast({
            html: statusText,
            classes: 'Toast--failure',
          })
        }
      })
      .catch(err => {
        console.log(err)
        window.M.toast({
          html: I18n.t('common.fail'),
          classes: 'Toast--failure',
        })
      })
      .finally(() =>
        this.setState({ page, search }, () =>
          this.searchUser({ target: { value: search } }, true),
        ),
      )
  }

  setLoading = () => this.setState({ loading: true })

  toggleModal = () => {
    const { modal } = this.state

    this.setState({ ...defaultParams, modal: !modal }, () => {
      if (!this.state.accessReport) this.setState({ accessReports: [] })
    })
  }

  searchUser = ({ target: { value } }, searched = false) => {
    const { usersSnapshot } = this.state
    if (searched) {
      this.setState({
        users: PaginateArray(
          usersSnapshot.filter(
            ({ Nome, Email, Id }) =>
              Nome.toLowerCase().includes(value.toLowerCase()) ||
              (Email && Email.toLowerCase().includes(value.toLowerCase())) ||
              String(Id).includes(value),
          ),
        ),
      })
    } else {
      if (value) {
        this.setState({
          users: PaginateArray(
            usersSnapshot.filter(
              ({ Nome, Email, Id }) =>
                Nome.toLowerCase().includes(value.toLowerCase()) ||
                (Email && Email.toLowerCase().includes(value.toLowerCase())) ||
                String(Id).includes(value),
            ),
          ),
          search: value,
          page: 0,
        })
      } else {
        this.setState({
          users: PaginateArray(usersSnapshot),
          search: value,
          page: 0,
        })
      }
    }
  }

  requestFeedback = ({ status, statusText }, successMessage) => {
    this.setState({ loading: false })
    if (status === 200) {
      window.M.toast({ html: successMessage, classes: 'Toast--success' })
    } else {
      window.M.toast({ html: statusText, classes: 'Toast--failure' })
    }
  }

  handleError = err => {
    this.setState({ loading: false })
    window.M.toast({ html: I18n.t('common.fail'), classes: 'Toast--failure' })
    console.log(err)
  }

  makeRealState = ({ managerId }) => {
    const { selectedUsers } = this.state

    this.setLoading()
    makeRealState(managerId, selectedUsers)
      .then(response =>
        this.requestFeedback(
          response,
          I18n.t('users.userSuccessfullyAlteredToRealState'),
        ),
      )
      .catch(this.handleError)
      .finally(() => {
        this.getUsers()
        this.setState({ ...defaultParams, modal: false })
      })
  }

  makeManager = ({ accountsQuantity }) => {
    const { selectedUsers } = this.state

    this.setLoading()
    makeManager(accountsQuantity, selectedUsers)
      .then(response =>
        this.requestFeedback(
          response,
          I18n.t('users.userSuccessfullyAlteredToManager'),
        ),
      )
      .catch(this.handleError)
      .finally(() => {
        this.getUsers()
        this.setState({ ...defaultParams, modal: false })
      })
  }

  getAccessReport = ({ startDate, endDate }) => {
    const { selectedUsers } = this.state

    this.setLoading()
    accessReport(startDate, endDate, selectedUsers)
      .then(({ data, status, statusText }) => {
        if (status === 200) {
          this.setState({ accessReports: data, loading: false })
          if (data.length === 0)
            window.M.toast({
              html: I18n.t('users.noAccessData'),
              classes: 'Toast',
            })
        } else {
          this.setState({ loading: false })
          window.M.toast({ html: statusText, classes: 'Toast--failure' })
        }
      })
      .catch(this.handleError)
  }

  changeValidity = ({ endDate }) => {
    const { selectedUsers } = this.state

    this.setLoading()
    changeValidity(endDate, selectedUsers)
      .then(response => {
        this.requestFeedback(response, I18n.t('users.validityChanged'))
        this.getUsers()
        this.setState({ ...defaultParams, modal: false })
      })
      .catch(err =>
        this.setState({
          errorValidity: err.response.data.Message,
          loading: false,
          validity: false,
          modal: true,
        }),
      )
  }

  doAction = ({ action }) => {
    const { selectedUsers } = this.state
    switch (action) {
      case 'resendPassword':
        this.setLoading()
        resendPasswords(selectedUsers)
          .then(response =>
            this.requestFeedback(response, I18n.t('users.passwordsResended')),
          )
          .catch(this.handeError)
          .finally(() => {
            this.getUsers()
            this.setState({ ...defaultParams, modal: false })
          })
        break
      case 'inactivate':
        this.setLoading()
        inactivateUsers(selectedUsers)
          .then(response =>
            this.requestFeedback(response, I18n.t('users.usersInactivated')),
          )
          .catch(this.handleError)
          .finally(() => {
            this.getUsers()
            this.setState({ ...defaultParams, modal: false })
          })
        break
      case 'activate':
        this.setLoading()
        activateUsers(selectedUsers)
          .then(response =>
            this.requestFeedback(response, I18n.t('users.usersActivated')),
          )
          .catch(this.handleError)
          .finally(() => {
            this.getUsers()
            this.setState({ ...defaultParams, modal: false })
          })
        break
      case 'changeValidity':
        this.setState({ ...defaultParams, validity: true, modal: true })
        break
      case 'changeToManager':
        this.setState({ ...defaultParams, manager: true, modal: true })
        break
      case 'changeToRealState':
        this.setState({ ...defaultParams, realState: true, modal: true })
        break
      case 'accessReport':
        this.setState({ ...defaultParams, accessReport: true, modal: true })
        break
      default:
        window.M.toast({
          html: I18n.t('users.selectAValidOption'),
          classes: 'Toast',
        })
        break
    }
  }

  selectAllUsers = () => {
    const { selectedUsers, usersSnapshot } = this.state

    if (selectedUsers.length === usersSnapshot.length) {
      this.setState({ selectedUsers: [] })
    } else {
      this.setState({ selectedUsers: usersSnapshot.map(({ Id }) => Id) })
    }
  }

  selectUser = id => {
    let { selectedUsers } = this.state
    let index = selectedUsers.indexOf(id)

    if (index === -1) selectedUsers.push(id)
    else selectedUsers.splice(index, 1)

    this.setState({ selectedUsers })
  }

  sortBy = column => {
    let { usersSnapshot } = this.state
    this.setState(state => ({
      ...state,
      page: 0,
      users: PaginateArray(
        usersSnapshot.sort((a, b) => {
          if (column === 'VigenciaInicial' || column === 'VigenciaFinal') {
            const dateA = new Date(a[column])
            const dateB = new Date(a[column])
            if (dateA < dateB)
              return this.state[`sort${column}`] === 'asc' ? 1 : -1
            if (dateA > dateB)
              return this.state[`sort${column}`] === 'asc' ? -1 : 1
          }
          if (column === 'Status') {
            const A = a[column] === 1 ? 'sim' : 'nao'
            const B = a[column] === 1 ? 'sim' : 'nao'
            if (A < B) return this.state[`sort${column}`] === 'asc' ? 1 : -1
            if (A > B) return this.state[`sort${column}`] === 'asc' ? -1 : 1
          }
          if (a[column] < b[column])
            return this.state[`sort${column}`] === 'asc' ? -1 : 1
          if (a[column] > b[column])
            return this.state[`sort${column}`] === 'asc' ? 1 : -1
          return 0
        }),
      ),
      [`sort${column}`]: this.state[`sort${column}`] === 'asc' ? 'desc' : 'asc',
      selectedUsers: [],
    }))
  }

  renderHeaderRow = () => (
    <Row header>
      {this.renderHeaderCol('', null, '50px')}
      {this.renderHeaderCol('Id', null, '50px')}
      {this.renderHeaderCol(I18n.t('dwellers.name'))}
      {this.renderHeaderCol(I18n.t('login.email'), 'Email')}
      {this.renderHeaderCol(
        I18n.t('components.filters.realState'),
        'Imobiliaria',
      )}
      {this.renderHeaderCol(I18n.t('generalData.city'))}
      {this.renderHeaderCol(I18n.t('register.telephone'))}
      {this.renderHeaderCol(
        I18n.t('users.startDate'),
        'VigenciaInicial',
        '80px',
      )}
      {this.renderHeaderCol(I18n.t('users.endDate'), 'VigenciaFinal', '80px')}
      {this.renderHeaderCol(I18n.t('alert.active'), 'Status', '73px')}
      {this.renderHeaderCol(I18n.t('users.manager'), 'NumeroContas', '73px')}
      {this.renderHeaderCol(I18n.t('users.accountQuantity'), 'NumeroContas')}
    </Row>
  )

  renderHeaderCol = (
    name,
    sort = null,
    width = 'calc(calc(100% - 413.54px) / 6)',
  ) => (
    <Col
      width={width}
      header
      title={name}
      onClick={() => this.sortBy(sort || name)}
      style={{ cursor: 'pointer' }}
    >
      {name}
      <i className="material-icons">
        {`keyboard_arrow_${
          this.state[`sort${sort || name}`] === 'asc' ? 'down' : 'up'
        }`}
      </i>
    </Col>
  )

  renderCheckCol = (callback, id = null, all = false) => {
    const { selectedUsers, usersSnapshot } = this.state
    return (
      <Col width="40px" header>
        <i className="material-icons Users__Checkbox" onClick={callback}>
          {all
            ? selectedUsers.length === usersSnapshot.length
              ? 'check_box'
              : 'check_box_outline_blank'
            : selectedUsers.includes(id)
            ? 'check_box'
            : 'check_box_outline_blank'}
        </i>
      </Col>
    )
  }

  renderRow = ({
    Id,
    Email,
    Nome,
    NumeroContas,
    Status,
    Cidade,
    VigenciaInicial,
    VigenciaFinal,
    Telefone,
    Imobiliaria,
    IsGestor,
  }) => (
    <Row key={Id}>
      {this.renderCheckCol(() => this.selectUser(Id), Id)}
      {this.renderCol(Id || I18n.t('common.notInformed'), 'Id', '50px')}
      {this.renderCol(
        Nome || I18n.t('common.notInformed'),
        I18n.t('dwellers.name'),
      )}
      {this.renderCol(
        Email || I18n.t('common.notInformed'),
        I18n.t('login.email'),
      )}
      {this.renderCol(
        Imobiliaria || I18n.t('common.notInformed'),
        I18n.t('components.filters.realState'),
      )}
      {this.renderCol(
        Cidade || I18n.t('common.notInformed'),
        I18n.t('generalData.city'),
      )}
      {this.renderCol(
        Telefone || I18n.t('common.notInformed'),
        I18n.t('register.telephone'),
      )}
      {this.renderCol(
        new Date(VigenciaInicial).toLocaleDateString('pt-BR') ||
          I18n.t('common.notInformed'),
        I18n.t('users.startDate'),
        '80px',
      )}
      {this.renderCol(
        new Date(VigenciaFinal).toLocaleDateString('pt-BR') ||
          I18n.t('common.notInformed'),
        I18n.t('users.endDate'),
        '80px',
      )}
      {this.renderCol(
        Status === 1 ? 'Sim' : 'Não' || I18n.t('common.notInformed'),
        I18n.t('alert.active'),
        '73px',
      )}
      {this.renderCol(
        NumeroContas ? 'Sim' : 'Não' || I18n.t('common.notInformed'),
        I18n.t('users.manager'),
        '73px',
      )}
      {this.renderCol(
        NumeroContas || I18n.t('common.notInformed'),
        I18n.t('users.accountQuantity'),
      )}
    </Row>
  )

  renderCol = (content, label, width = 'calc(calc(100% - 396px) / 6)') => (
    <Col width={width} title={content} label={label}>
      {content}
    </Col>
  )

  renderPageNavigation = () => {
    const { page, users } = this.state
    const nextPage = () => this.setState({ page: page + 1 })
    const previousPage = () => this.setState({ page: page - 1 })
    const hasPrevious = page > 0
    const hasNext = page + 1 !== users.length && users.length > 1
    return (
      users.length > 0 && (
        <div className="Users__PageNavigationContainer">
          <div className="Users__Arrow">
            {hasPrevious && (
              <i className="material-icons" onClick={previousPage}>
                keyboard_arrow_left
              </i>
            )}
          </div>
          <div className="Users__Navigation" style={{ padding: '5px 0' }}>
            {page + 1}/{users.length || 1}
          </div>
          <div className="Users__Arrow">
            {hasNext && (
              <i className="material-icons" onClick={nextPage}>
                keyboard_arrow_right
              </i>
            )}
          </div>
        </div>
      )
    )
  }

  renderOptions = () => {
    if (userInfo().roles.includes('Sales')) {
      return SALES_OPTIONS.map(option => (
        <option key={option} value={option}>
          {I18n.t(`users.${option}`)}
        </option>
      ))
    } else {
      return ADMIN_OPTIONS.map(option => (
        <option key={option} value={option}>
          {I18n.t(`users.${option}`)}
        </option>
      ))
    }
  }

  render() {
    const {
      users,
      page,
      loading,
      search,
      manager,
      realState,
      validity,
      accessReport,
      accessReports,
      modal,
      selectedUsers,
      errorValidity,
    } = this.state
    const modalClasses = classNames({
      'Users__Pane--hidden': !modal,
      Users__Pane: modal,
    })
    const accessReportClasses = classNames('Users__Modal', {
      'Users__Modal--report': accessReport && accessReports.length > 0,
    })
    return (
      <div>
        {selectedUsers.length > 0 && (
          <GRow>
            <GCol xs={12}>
              {selectedUsers.length}
              {selectedUsers.length > 1
                ? I18n.t('users.selectedUsers')
                : I18n.t('users.selectedUser')}
            </GCol>
          </GRow>
        )}
        <GRow>
          <GCol xs={12}>
            <input
              placeholder={I18n.t('users.searchHere')}
              className="Users__SearchInput"
              onChange={this.searchUser}
              value={search}
            />
          </GCol>
        </GRow>
        <GRow>
          <GCol xs={12}>
            <Table>
              {this.renderHeaderRow()}
              <div className="Users__Table">
                {users.length > 0 && users[page].map(this.renderRow)}
              </div>
            </Table>
          </GCol>
        </GRow>
        <GRow>
          <GCol xs={12}>{this.renderPageNavigation()}</GCol>
        </GRow>
        <GRow>
          <GCol xs={12}>
            <Formik initialValue={{ action: '' }} onSubmit={this.doAction}>
              {({ handleSubmit, handleChange, values: { action } }) => (
                <Form onSubmit={handleSubmit}>
                  <GRow>
                    <GCol xs={12}>
                      <Select
                        name="action"
                        value={action}
                        onChange={handleChange}
                        disabled={selectedUsers.length === 0}
                      >
                        {this.renderOptions()}
                      </Select>
                    </GCol>
                  </GRow>
                  <GRow className="Users__RowBtn">
                    <GCol xs={12}>
                      <Button
                        block
                        btnStyle="primary"
                        type="submit"
                        disabled={!action}
                      >
                        {I18n.t('components.actions.apply')}
                      </Button>
                    </GCol>
                  </GRow>
                </Form>
              )}
            </Formik>
          </GCol>
        </GRow>
        <Spinner visible={loading} />
        <div className={modalClasses}>
          <div className={accessReportClasses}>
            <GRow end="xs">
              <GCol xs={12}>
                <i className="material-icons" onClick={this.toggleModal}>
                  close
                </i>
              </GCol>
            </GRow>
            {errorValidity && <div>{errorValidity}</div>}
            {validity && (
              <Formik
                initialValues={{ endDate: '' }}
                onSubmit={this.changeValidity}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values: { initialDate, endDate },
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <GRow>
                      <GCol xs={12}>
                        <label>{I18n.t('users.validityFinalDate')}</label>
                        <input
                          className="Users__SearchInput"
                          value={endDate}
                          onChange={handleChange}
                          name="endDate"
                          type="date"
                        />
                      </GCol>
                    </GRow>
                    <GRow className="Users__RowBtn">
                      <GCol xs={12}>
                        <Button btnStyle="primary" block type="submit">
                          {I18n.t('components.actions.apply')}
                        </Button>
                      </GCol>
                    </GRow>
                  </Form>
                )}
              </Formik>
            )}
            {manager && (
              <Formik
                initialValues={{ accountsQuantity: '' }}
                onSubmit={this.makeManager}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values: { accountsQuantity },
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <GRow>
                      <GCol xs={12}>
                        <label>{I18n.t('users.accountQuantity')}</label>
                        <input
                          value={accountsQuantity}
                          onChange={handleChange}
                          className="Users__SearchInput"
                          name="accountsQuantity"
                        />
                      </GCol>
                    </GRow>
                    <GRow className="Users__RowBtn">
                      <GCol xs={12}>
                        <Button btnStyle="primary" block type="submit">
                          {I18n.t('components.actions.apply')}
                        </Button>
                      </GCol>
                    </GRow>
                  </Form>
                )}
              </Formik>
            )}
            {realState && (
              <Formik
                initialValues={{ managerId: '' }}
                onSubmit={this.makeRealState}
              >
                {({ handleSubmit, handleChange, values: { managerId } }) => (
                  <Form onSubmit={handleSubmit}>
                    <GRow>
                      <GCol xs={12}>
                        <label>{I18n.t('users.managerId')}</label>
                        <input
                          value={managerId}
                          onChange={handleChange}
                          className="Users__SearchInput"
                          name="managerId"
                        />
                      </GCol>
                    </GRow>
                    <GRow className="Users__RowBtn">
                      <GCol xs={12}>
                        <Button btnStyle="primary" block type="submit">
                          {I18n.t('components.actions.apply')}
                        </Button>
                      </GCol>
                    </GRow>
                  </Form>
                )}
              </Formik>
            )}
            {accessReport && accessReports.length === 0 && (
              <Formik
                initialValues={{ startDate: '', endDate: '' }}
                onSubmit={this.getAccessReport}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values: { startDate, endDate },
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <GRow>
                      <GCol xs={6}>
                        <label>{I18n.t('users.initialDate')}</label>
                        <input
                          className="Users__SearchInput"
                          value={startDate}
                          onChange={handleChange}
                          name="startDate"
                          type="date"
                        />
                      </GCol>
                      <GCol xs={6}>
                        <label>{I18n.t('users.finalDate')}</label>
                        <input
                          className="Users__SearchInput"
                          value={endDate}
                          onChange={handleChange}
                          name="endDate"
                          type="date"
                        />
                      </GCol>
                    </GRow>
                    <GRow className="Users__RowBtn">
                      <GCol xs={12}>
                        <Button btnStyle="primary" block type="submit">
                          {I18n.t('components.actions.apply')}
                        </Button>
                      </GCol>
                    </GRow>
                  </Form>
                )}
              </Formik>
            )}
            {accessReport && accessReports.length > 0 && (
              <Table>
                <Row header>
                  <Col width="50%" header>
                    Id
                  </Col>
                  <Col width="50%" header>
                    {I18n.t('users.accessDate')}
                  </Col>
                </Row>
                <div className="Users__Scroll">
                  {accessReports.map(({ PessoaId, Data }) => (
                    <Row>
                      <Col width="50%" label="Id">
                        {PessoaId}
                      </Col>
                      <Col width="50%" label="Data de acesso">
                        {formatDateAndTime(Data)}
                      </Col>
                    </Row>
                  ))}
                </div>
              </Table>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default Users
