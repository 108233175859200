import React from 'react'
import classNames from 'classnames'
import { Row, Col } from 'components/Grid'
import { Button } from 'components/Buttons'
import I18n from 'locales'
import './index.css'

const ConfirmDialog = ({ visible, text, onConfirm, onCancel }) => {
  const classes = classNames({
    ConfirmDialog: visible,
    'ConfirmDialog--hidden': !visible,
  })
  return (
    <div className={classes}>
      <div className="ConfirmDialog__Modal">
        <div className="ConfirmDialog__Header">
          <i className="material-icons" onClick={onCancel}>
            close
          </i>
        </div>
        <div className="ConfirmDialog__Body">{text}</div>
        <div className="ConfirmDialog__Actions">
          <Row end="xs">
            <Col>
              <Button btnStyle="link" onClick={onCancel}>
                {I18n.t('components.actions.cancel')}
              </Button>
            </Col>
            <Col>
              <Button btnStyle="primary" onClick={onConfirm}>
                {I18n.t('components.actions.ok')}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default ConfirmDialog
