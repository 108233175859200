import React from 'react'
import './index.css'
import classNames from 'classnames'

const CancelButton = props => {
  const classes = classNames({
    'CancelButton--hidden': !props.drawing,
    CancelButton: props.drawing,
  })
  return (
    <button className={classes} onClick={props.onClick}>
      <i className="material-icons">close</i>
    </button>
  )
}

export default CancelButton
